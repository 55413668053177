import { api } from "..";
import { NotificationType } from "./types";

const NotificationAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllNotifications: build.query<NotificationType, void>({
      query: () => `notification/all`,
      providesTags: ["Notification"],
    }),
  }),
});

export const { useGetAllNotificationsQuery } = NotificationAPI;
