import { api } from "..";
import { SignedSamlResponse } from "./types";

const analyticsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSignedSamlResponse: build.query<SignedSamlResponse, void>({
      query: (arg) => "zoho/saml",
    }),
  }),
});

export const {
  useGetSignedSamlResponseQuery,
  useLazyGetSignedSamlResponseQuery,
} = analyticsApi;
