import { Card, Col, Descriptions, Flex, Row, Space, Switch, Typography } from "antd";
import React, { useContext } from "react";
import IncredableButton from "../button";
import { EditOutlined } from "@ant-design/icons";
import MaskValue from "../MaskValue";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { ToastContext } from "../../context/toast/incredable-toast";

interface ProviderCardProps {
  title: React.ReactNode;
  extraButton?: {
    text: string;
    icon?: string;
    onClick?: () => void;
  };
  data: {
    label: string;
    value: string | boolean | Date | number | { id: string; name: string };
  }[];
}

const ProviderCard: React.FC<ProviderCardProps> = ({
  title,
  extraButton,
  data,
}) => {
  const [showSSN, setShowSSN] = React.useState(false);
  const toastContext = useContext(ToastContext);
  const totalSpan = 24;
  const isEven = totalSpan % 2 === 0

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner || (state.ui.permissions?.includes("ssn.view") ?? false)
  );

  const showSecrets = () => {
    if (hasPermission) {
      setShowSSN(!showSSN);
    } else {
      toastContext?.openErrorNotification({
        message: ` You Don't Have A Permission.`,
        placement: "top",
      });
    }
  };

  return (
    <Card
      title={title}
      extra={
        !!extraButton && (
          <IncredableButton
            type="primary"
            icon={<EditOutlined />}
            iconPosition="start"
            onClick={extraButton.onClick}
          >
            Edit
          </IncredableButton>
        )
      }
    >
      <Descriptions
        bordered
        layout="vertical"
        items={data?.map((item) => {

          let label =
            item.label === "SSN" ? (
              <Flex justify="space-between" align="center">
                <Row>
                  <Col span={8} style={isEven ? { width: 50 } : {}}>{item.label}</Col>
                </Row>
                <Space direction="horizontal">
                  <Switch value={showSSN} onChange={showSecrets} size="small" />
                  Show SSN
                </Space>
              </Flex>
            ) : (
              <Row>
                <Col span={8} style={isEven ? { width: 50 } : {}}>{item.label}</Col>
              </Row>
            );

          return {
            key: item.label,
            label: label,
            children:
              item.label === "SSN" ? (
                <MaskValue
                  maskContent={showSSN ? false : true}
                  value={item?.value ? String(item.value) : "-"}
                />
              ) : (
                String(item.value)
              ),
          };
        })}
      />
    </Card>
  );
};

export default ProviderCard;
