import { Col, Divider, Flex, Form, Row, Space, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import IncredableButton from "../../components/button";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import {
  useGetAllContractTypesQuery,
  useSaveContractMutation,
} from "../../redux/api/contracts/contract";
import { Attachment } from "../../redux/api/document/types";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import IncredableContent from "../../components/incredable-content";
import { alertReminderOptions } from "../../common/constants";
import AttachmentViewer from "../../components/attachment-viewer";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";

type FormInputType = {
  title: string;
  executionDate: string;
  expirationDate: string;
  lastRenewalDate: string;
  description: string;
  primaryContact: string;
  facility: string;
  contractType: string;
  alertDays: string;
  entityName: string;
  entityPhone: string;
  entityEmail: string;
  attachment: Attachment;
};

export default function AddContract() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const [uploadedFile, setUploadedFile] = useState<File>();

  const { data: contractType, isLoading: isContractTypeLoading } =
    useGetAllContractTypesQuery();
  const { data: facilities, isLoading: isFacilitiesLoading } =
    useGetFacilitiesQuery();
  const { data: member, isLoading: isMemberLoading } = useGetAllMembersQuery();
  const [saveContract] = useSaveContractMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <IncredableContent
      spinning={isContractTypeLoading || isFacilitiesLoading || isMemberLoading}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Contract",
            href: "/contract",
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addcontract"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            await saveContract({
              title: data?.title,
              executionDate: data?.executionDate,
              expirationDate: data?.expirationDate,
              lastRenewalDate: data?.lastRenewalDate,
              description: data?.description,
              primaryContact: { id: data?.primaryContact },
              facility: { id: data.facility },
              contractType: { id: data.contractType, type: data.contractType },
              entityName: data.entityName,
              entityPhone: data.entityPhone,
              entityEmail: data.entityEmail,
              alertDays: data.alertDays,
              attachment: uploadedFile
                ? {
                    name: uploadedFile?.name,
                    size: uploadedFile?.size,
                    contentType: uploadedFile?.type,
                  }
                : null,
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Contract saved successfully`,
              placement: "topRight",
            });
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Add Contract
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="contracts" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Title",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `title`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Contract Type" }}
                            controllerProps={{
                              name: `contractType`,
                            }}
                            rules={{ required: "This field is required" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: contractType?.map((type) => {
                                return {
                                  label: type?.type,
                                  value: type?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/contracttype");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility" }}
                            controllerProps={{
                              name: `facility`,
                            }}
                            rules={{ required: "This field is required" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: facilities?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Execution Date",
                            }}
                            controllerProps={{
                              name: `executionDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Expiration Date",
                            }}
                            controllerProps={{
                              name: `expirationDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Last Renewal Date",
                            }}
                            controllerProps={{
                              name: `lastRenewalDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Entity Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `entityName`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Entity Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `entityPhone`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Entity Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `entityEmail`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Primary Contact" }}
                            controllerProps={{
                              name: `primaryContact`,
                            }}
                            rules={{ required: "This field is required" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select contact",
                              options: member?.map((name) => {
                                return {
                                  label: `${name?.lastName} ${name?.firstName}`,
                                  value: name?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Follow Up Date" }}
                            controllerProps={{
                              name: `alertDays`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Days",
                              options: alertReminderOptions?.map((alert) => {
                                return {
                                  label: alert?.label,
                                  value: alert?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                      <Col span={24}>
                        <RHFTextArea
                          formItemProps={{ label: "Notes" }}
                          controllerProps={{ name: "description" }}
                        />
                      </Col>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
      <Col>
        <AttachmentViewer
          onFileChange={(file) => {
            setUploadedFile(file);
          }}
          file={uploadedFile}
        />
      </Col>
    </IncredableContent>
  );
}
