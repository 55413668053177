import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { PayerEnrollment } from "../../redux/api/payerenrollment/types";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useDeletePayerEnrollmentMutation } from "../../redux/api/payerenrollment/payerenrollment";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableDateFormat from "../../common/incredable-date-format";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useLocalStorage } from "../../common/localStorage";
import {
  ENROLLEDPAYER_CONTENT_DISPLAY_OPTIONS,
  ENROLLEDPAYER_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";
import useEnrolledPayerFilters from "../../table-filters/enrolledpayer-filters";
export default function EnrolledPayerV2() {
  const { providerId } = useParams();
  const [selectedRows, setSelectedRows] = useState<PayerEnrollment[]>([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [deleteEnrolledPayer] = useDeletePayerEnrollmentMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false)
  );
  const {
    TableFilters,
    filteredproviderEnrollment = [],
    UseQueryHookResult: { isFetching, isLoading, refetch },
  } = useEnrolledPayerFilters({ providerId });
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-EnrolledPayer-Table-Preferences",
    ENROLLEDPAYER_TABLE_DEFAULT_PREFERENCES
  );
  const { items, collectionProps, paginationProps } = useCollection(
    filteredproviderEnrollment,
    {
      pagination: { pageSize: tablePreferences?.pageSize },
    }
  );
  return (
    <>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([detail.selectedItems[0]]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "payer",
            header: <div>Payer</div>,
            cell: (item) => (
              <NavLink
                to={
                  hasPermission
                    ? `/manageprovider/${providerId}/enrolledpayer/${item?.id}/edit`
                    : "#"
                }
              >
                {item?.payer?.name}
              </NavLink>
            ),
            sortingField: "payer.name",
            sortingComparator: (a, b) =>
              (a?.payer?.name ?? "").localeCompare(b?.payer?.name ?? ""),
          },
          {
            id: "network",
            header: <div>Network</div>,
            cell: (item) => <span>{item?.payerGroup?.name}</span>,
            sortingField: "payerGroup.name",
            sortingComparator: (a, b) =>
              (a?.payerGroup?.name ?? "").localeCompare(
                b?.payerGroup?.name ?? ""
              ),
          },
          {
            id: "status",
            header: <div>Status</div>,
            cell: (item) => <span>{item?.status?.name ?? "-"}</span>,
            sortingField: "status.name",
            sortingComparator: (a, b) =>
              (a?.status?.name ?? "").localeCompare(b?.status?.name ?? ""),
          },
          {
            id: "provider",
            header: <div>Provider</div>,
            cell: (item) =>
              item?.provider ? (
                <span>
                  {item?.provider?.firstName + " " + item?.provider?.lastName}{" "}
                </span>
              ) : (
                "-"
              ),
            sortingField: "provider.firstName",
            sortingComparator: (a, b) =>
              (a?.provider?.firstName ?? "").localeCompare(
                b?.provider?.firstName ?? ""
              ),
          },
          {
            id: "payerProviderId",
            header: <div>Provider ID</div>,
            cell: (item) => (
              <span>{item?.payerProviderId ? item?.payerProviderId : "-"}</span>
            ),
            sortingField: "payerProviderId",
            sortingComparator: (a, b) =>
              (a?.payerProviderId ?? "").localeCompare(
                b?.payerProviderId ?? ""
              ),
          },
          {
            id: "facility",
            header: <div>Facility</div>,
            cell: (item) => <span>{item?.facility?.name ?? "-"}</span>,
            sortingField: "facility.name",
            sortingComparator: (a, b) =>
              (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
          },
          {
            id: "facilityGroup",
            header: <div>Facility Group</div>,
            cell: (item) => <span>{item?.facilityGroup?.name ?? "-"}</span>,
          },
          {
            id: "submittedDate",
            header: <div>Submitted date </div>,
            cell: (item) =>
              item?.submittedDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.submittedDate)}
                </span>
              ) : (
                "-"
              ),
          },
          {
            id: "approvedDate",
            header: <div>Approved Date</div>,
            cell: (item) =>
              item?.approvedDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.approvedDate)}
                </span>
              ) : (
                "-"
              ),
          },
          {
            id: "effectiveDate",
            header: <div>Effective Date</div>,
            cell: (item) =>
              item?.effectiveDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.effectiveDate)}
                </span>
              ) : (
                "-"
              ),
          },
          {
            id: "recredentialingDate",
            header: <div>Re-Credentialing Date</div>,
            cell: (item) =>
              item?.recredentialingDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.recredentialingDate)}
                </span>
              ) : (
                "-"
              ),
          },
          {
            id: "followupDate",
            header: <div>Followup Date</div>,
            cell: (item) => (
              <span>
                {item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}
              </span>
            ),
          },
          {
            id: "description",
            header: <div>Notes</div>,
            cell: (item) => <span>{item?.description}</span>,
          },
        ]}
        items={items}
        filter={TableFilters}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={ENROLLEDPAYER_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(
                              `enrolledpayer/${selectedRows?.at(0)?.id}/edit`
                            );
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => navigate(`enrolledpayer/add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Enrolled Payer{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${
                  filteredproviderEnrollment?.length || 0
                })`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteEnrolledPayer({
              payerEnrollmentId: selectedRows?.at(0)?.id + "",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Enrolled Payer deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Enrolled Payer"
        content={
          <Typography.Text>
            Payer Name:{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.payer?.name}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this enrolled payer?"
      />
    </>
  );
}
