import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import {
  useGetAllFacilityTypesQuery,
  useGetFacilitiesQuery,
} from "../redux/api/facility/facility";
import { USStatesList } from "../common/constants";
import { Facility } from "../redux/api/facility/types";
import { useLocation } from "react-router-dom";
import { Trainee } from "../redux/api/gme/types";
import { useGetAllTraineeQuery } from "../redux/api/gme/gme";
import { programs, traineeTypes } from "../gme/constants";

export default function useTraineeFilter(items?: Trainee[]) {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("firstName");
  const location = useLocation();

  let isLocation = location.pathname.includes("location");

  const [traineeFirstNameFiltervalue, setTraineeFirstNameFiltervalue] = useState<
    string | undefined
  >(undefined);
  const [traineeLastNameFiltervalue, setTraineeLastNameFiltervalue] = useState<
    string | undefined
  >(undefined);
  const [traineeTypeFiltervalue, setTraineeTypeFiltervalue] = useState<
    string | undefined
  >(undefined);
  const [programFiltervalue, setProgramFiltervalue] = useState<string | undefined>(
    undefined
  );
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });

  const [pageSize, setPageSize] = useState<number>(10);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const {
    data: trainee = [],
    refetch,
    isLoading: traineeLoading,
    isFetching,
  } = useGetAllTraineeQuery();

  const [filteredTrainee, setFilteredTrainee] = useState<Trainee[]>(
    items ?? trainee
  );
  useEffect(() => {
    setFilteredTrainee(items ?? trainee);
  }, [trainee, items]);

  useEffect(() => {
    let temp_filtered_trainee = !!items ? [...items] : [...trainee];

    if (!!traineeFirstNameFiltervalue && selectedFilterkey === "firstName")
      temp_filtered_trainee = temp_filtered_trainee?.filter((name) =>
        name?.traineeFirstName
          ?.toLowerCase()
          .includes(traineeFirstNameFiltervalue?.toLowerCase())
      );
    if (!!traineeLastNameFiltervalue && selectedFilterkey === "lastName")
      temp_filtered_trainee = temp_filtered_trainee?.filter((name) =>
        name?.traineeLastName
          ?.toLowerCase()
          .includes(traineeLastNameFiltervalue?.toLowerCase())
      );

    if (!!traineeTypeFiltervalue && selectedFilterkey === "traineeType") {
      temp_filtered_trainee = temp_filtered_trainee?.filter(
        (type) => type?.type + "" === traineeTypeFiltervalue + ""
      );
    }
    if (!!programFiltervalue && selectedFilterkey === "program") {
      temp_filtered_trainee = temp_filtered_trainee?.filter(
        (program) => program?.program + "" === programFiltervalue + ""
      );
    }
    setFilteredTrainee(temp_filtered_trainee);
  }, [
    traineeFirstNameFiltervalue,
    traineeLastNameFiltervalue,
    traineeTypeFiltervalue,
    programFiltervalue,
    trainee,
    items,
  ]);
  useEffect(() => {
    setTraineeFirstNameFiltervalue(undefined);
    setTraineeLastNameFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterkey}
          onChange={(value) => {
            setSelectedFilterKey(value);
          }}
          style={{ width: "140px" }}
          options={[
            { value: "firstName", label: "First Name" },
            { value: "lastName", label: "Last Name" },
            {
              value: "traineeType",
              label: "Trainee Type",
            },
            { value: "program", label: "Program" },
          ]}
        />
        {selectedFilterkey === "firstName" && (
          <Input
            onChange={(event) => {
              setTraineeFirstNameFiltervalue(event?.target?.value);
            }}
            value={traineeFirstNameFiltervalue}
            style={{
              width: "320px",
            }}
            placeholder="Find First Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterkey === "lastName" && (
          <Input
            onChange={(event) => {
              setTraineeLastNameFiltervalue(event?.target?.value);
            }}
            value={traineeLastNameFiltervalue}
            style={{
              width: "320px",
            }}
            placeholder="Find Last Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterkey === "traineeType" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={traineeTypeFiltervalue}
            placeholder="Select Trainee Type"
            onChange={(value) => {
              setTraineeTypeFiltervalue(value);
            }}
            style={{ width: "320px" }}
            options={traineeTypes?.map((traineeType) => ({
              label: traineeType?.value,
              searchValue: traineeType?.value,
              value: traineeType?.value + "",
            }))}
          />
        )}
        {selectedFilterkey === "program" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={programFiltervalue}
            placeholder="Select Program"
            onChange={(value) => {
              setProgramFiltervalue(value);
            }}
            style={{ width: "320px" }}
            options={programs?.map((program) => ({
              label: program?.value,
              searchValue: program?.value,
              value: program?.value + "",
            }))}
          />
        )}
      </Space>
      <div>
        {!!traineeTypeFiltervalue && (
          <Tag
            closeIcon
            onClose={() => {
              setTraineeTypeFiltervalue(undefined);
            }}
          >
            <span>
              TraineeType ={" "}
              {
                traineeTypes?.find(
                  (type) => type?.value + "" === traineeTypeFiltervalue + ""
                )?.value
              }
            </span>
          </Tag>
        )}
        {!!programFiltervalue && (
          <Tag
            closeIcon
            onClose={() => {
              setProgramFiltervalue(undefined);
            }}
          >
            <span>
              Program ={" "}
              {
                programs?.find(
                  (program) => program?.value + "" === programFiltervalue + ""
                )?.value
              }
            </span>
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredTrainee: filteredTrainee,
    UseQueryHookResult: {
      refetch,
      isFetching,
      isLoading: traineeLoading,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  };
}
