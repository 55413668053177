import { Col, ConfigProvider, Divider, Form, Row, Tabs } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import IncredableButton from "../../components/button";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { PayerEnrollmentRequest } from "../../redux/api/payerenrollment/types";
import {
  useCreatePayerEnrollmentMutation,
  useGetAllPayerEnrollmentStatusQuery,
} from "../../redux/api/payerenrollment/payerenrollment";
import { useGetAllPayerQuery } from "../../redux/api/payers/payers";
import IncredableContent from "../../components/incredable-content";
import { alertReminderOptions } from "../../common/constants";
import { useGetAllPayerGroupQuery } from "../../redux/api/payergroup/payergroup";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { ReloadOutlined } from "@ant-design/icons";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import { Facility } from "../../redux/api/facility/types";
import { PayerGroup } from "../../redux/api/payergroup/types";
import StickyFooter from "../../components/StickyFooter";

export default function AddPayerEnrollment() {
  const navigate = useNavigate();
  const methods = useForm<PayerEnrollmentRequest>();
  const { data: payer, isLoading } = useGetAllPayerQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: facilityGroups } = useGetAllFacilityGroupQuery();
  const { data: status } = useGetAllPayerEnrollmentStatusQuery();
  const { data: networks = [] } = useGetAllPayerGroupQuery();
  const { data: workflow, refetch } = useGetAllWorkflowQuery();
  const [addpayerenrollment] = useCreatePayerEnrollmentMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const selectedPayerId = methods.watch("payerId");
  const selectedFacilityGroupId = methods.watch("facilityGroupId");
  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]);
  const [filteredPayergroup, setFilteredPayergroup] = useState<PayerGroup[]>(
    []
  );
  useEffect(() => {
    if (!!selectedFacilityGroupId) {
      const selectedFacilityGroup = facilityGroups?.find(
        (fg) => fg.id + "" === selectedFacilityGroupId + ""
      );
      setFilteredFacilities(selectedFacilityGroup?.facilities ?? []);
    } else setFilteredFacilities(facilities);
  }, [selectedFacilityGroupId, facilityGroups, facilities]);

  useEffect(() => {
    if (!!selectedPayerId) {
      const selectedPayer = payer?.find(
        (pg) => pg.id + "" === selectedPayerId + ""
      );
      setFilteredPayergroup(selectedPayer?.payerGroup ?? []);
    } else setFilteredPayergroup(networks);
  }, [selectedPayerId, networks, payer]);

  return (
    <IncredableContent
      spinning={isLoading}
      style={{
        position: "relative",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Payer Enrollments",
            href: "/payerenrollment",
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "rgb(29, 26, 31)",
              verticalItemPadding: "0",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "none ",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey="addPayerEnrollment"
          size="large"
          items={[
            {
              label: (
                <span style={{ fontWeight: "600", padding: "0" }}>
                  Add PayerEnrollment
                </span>
              ),
              key: "addPayerEnrollment",
              children: (
                <FormProvider {...methods}>
                  <Form
                    name="addpayerenrollment"
                    layout="vertical"
                    onFinish={methods.handleSubmit(
                      async (data: PayerEnrollmentRequest) => {
                        if (!data.payerId && !data.payerGroupId) {
                          toastContext?.openErrorNotification({
                            message: `Network or Payer is required.`,
                            placement: "top",
                          });
                          return;
                        }
                        if (!data.providerId && !data.facilityId) {
                          toastContext?.openErrorNotification({
                            message: `Provider or Facility is required.`,
                            placement: "top",
                          });
                          return;
                        }
                        spinnerContext?.showSpinner();
                        if (data?.payerId) {
                          await addpayerenrollment({
                            ...data,
                          });
                        }
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                          message: `Payer Enrollment saved successfully`,
                          placement: "topRight",
                        });
                        navigate(-1);
                      }
                    )}
                    style={{
                      height: "calc(100vh - 250px)",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      paddingBottom: "50px",
                    }}
                    autoComplete="off"
                  >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Payer" }}
                          controllerProps={{
                            name: `payerId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Select payer",
                            options: payer?.map((name) => {
                              return {
                                label: name?.name,
                                value: name?.id,
                              };
                            }),
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Network" }}
                          controllerProps={{
                            name: `payerGroupId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Select network",
                            options: filteredPayergroup?.map((network) => {
                              return {
                                label: network?.name,
                                value: network?.id,
                              };
                            }),
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Provider" }}
                          controllerProps={{
                            name: `providerId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Select provider",
                            options: providers?.map((provider) => {
                              return {
                                label: `${provider?.firstName} ${provider?.lastName}`,
                                value: provider?.id + "",
                              };
                            }),
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Facility Group" }}
                          controllerProps={{
                            name: `facilityGroupId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Select Facility Group",
                            options: facilityGroups?.map((name) => {
                              return {
                                label: name?.name + "",
                                value: name?.id + "",
                              };
                            }),
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Facility" }}
                          controllerProps={{
                            name: `facilityId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Select facility",
                            options: filteredFacilities?.map((facility) => {
                              return {
                                label: facility?.name,
                                value: facility?.id,
                              };
                            }),
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Status" }}
                          controllerProps={{
                            name: `enrollmentStatusId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Select status",
                            options: status?.map((name) => {
                              return {
                                label: name?.name,
                                value: name?.id,
                              };
                            }),
                            onModifyClick: () => {
                              navigate("/payerenrollmentstatus");
                            },
                          }}
                          rules={{ required: "This field is required" }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFDatePicker
                          formItemProps={{
                            label: "Submitted Date",
                          }}
                          controllerProps={{
                            name: `submittedDate`,
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFDatePicker
                          formItemProps={{
                            label: "Approved Date",
                          }}
                          controllerProps={{
                            name: `approvedDate`,
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFDatePicker
                          formItemProps={{
                            label: "Effective Date",
                          }}
                          controllerProps={{
                            name: `effectiveDate`,
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFDatePicker
                          formItemProps={{
                            label: "Re-Enrollment Date",
                          }}
                          controllerProps={{
                            name: `recredentialingDate`,
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFTextField
                          formItemProps={{
                            label: "Payer Provider Id",
                          }}
                          inputProps={{}}
                          controllerProps={{
                            name: `payerProviderId`,
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Follow Up Date" }}
                          controllerProps={{
                            name: `alertDays`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Select Days",
                            options: alertReminderOptions?.map((alert) => {
                              return {
                                label: alert?.label,
                                value: alert?.value,
                              };
                            }),
                          }}
                        />
                      </Col>
                    </Row>
                    <Divider />
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={12}>
                        <RHFSelect
                          formItemProps={{ label: "Workflows" }}
                          controllerProps={{
                            name: `workflowId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Select workflow",
                            options: workflow?.map((name) => {
                              return {
                                label: name?.name,
                                value: name?.id,
                              };
                            }),
                          }}
                        />
                      </Col>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <ReloadOutlined
                          size={20}
                          style={{ marginLeft: "10px" }}
                          onClick={() => refetch()}
                        />
                        <IncredableButton
                          style={{
                            borderColor: "#598BEB",
                            color: "#598BEB",
                            marginLeft: "10px",
                          }}
                          secondary
                          onClick={() => navigate("/workflows/add")}
                        >
                          Create New Workflow
                        </IncredableButton>
                      </Col>
                    </Row>
                    <Divider />
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={24}>
                        <RHFTextArea
                          formItemProps={{ label: "Notes" }}
                          controllerProps={{ name: "description" }}
                        />
                      </Col>
                    </Row>
                    <StickyFooter
                      hasPermission={true}
                      onSubmit={() => ""}
                      onCancel={() => {
                        navigate(-1);
                      }}
                    />
                  </Form>
                </FormProvider>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </IncredableContent>
  );
}
