import { Box, Header, PieChart } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import { useGetAllWorkflowQuery } from "../../../redux/api/workflow/workflow";
import { useGetDashboardWorkflowStatsQuery } from "../../../redux/api/dashboards/dashboard";

export const workflowStatus: WidgetConfig = {
  title: "Workflow Status",
  description: "Overview of all your Workflows",
  header: WorkflowStatusHeader,
  content: WorkflowStatusContent,
};

function WorkflowStatusHeader() {
  return <Header variant="h2">{workflowStatus.title}</Header>;
}

function WorkflowStatusContent() {
  const { data = [] } = useGetDashboardWorkflowStatsQuery();
  return (
    <div style={{ height: "400px" }}>
      <PieChart
        data={
          data?.map((workflow) => {
            return {
              title: workflow?.name,
              percentage: workflow?.count ?? 0,
              value: workflow?.count ?? 0,
            };
          }) ?? []
        }
        segmentDescription={(datum, sum) =>
          `${datum.value} workflows, ${((datum.value / sum) * 100).toFixed(0)}%`
        }
        detailPopoverContent={(datum, sum) => [
          {
            key: "Workflows count",
            value: datum.value,
          },
          {
            key: "Percentage",
            value: `${((datum.value / sum) * 100).toFixed(0)}%`,
          },
        ]}
        ariaDescription="Donut chart showing generic example data."
        ariaLabel="Donut chart"
        innerMetricDescription="Total Workflows"
        innerMetricValue={`${data?.length}`}
        size="medium"
        variant="donut"
        hideFilter
        fitHeight
        empty={
          <Box textAlign="center" color="inherit">
            <b>No data available</b>
            <Box variant="p" color="inherit">
              There is no data available
            </Box>
          </Box>
        }
      />
    </div>
  );
}
