import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { ProviderLiabilityInsuranceType } from "../../redux/api/provider/types";
import {
  useDeleteProviderLiabilityInsuranceMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableDateFormat from "../../common/incredable-date-format";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import {
  LIABILITYINSURANCE_CONTENT_DISPLAY_OPTIONS,
  LIABILITYINSURANCE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";
import { useLocalStorage } from "../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { AsYouType } from "libphonenumber-js";

export default function LiabilityInsuranceV2() {
  const { providerId } = useParams();
  const [selectedRows, setSelectedRows] = useState<
    ProviderLiabilityInsuranceType[]
  >([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [deleteProviderLiabilityInsurance] =
    useDeleteProviderLiabilityInsuranceMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );
  const filteredColumns = [
    "name",
    "policyNumber",
    "effectiveDate",
    "expirationDate",
    "typeOfCoverage",
    "email",
    "phone",
  ];
  const {
    data: provider,
    isLoading,
    isFetching,
    refetch,
  } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-LiablityInsurance-Table-Preferences",
    LIABILITYINSURANCE_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerLiabilityInsurances ?? [],
    {
      pagination: { pageSize: tablePreferences?.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  return (
    <>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([detail.selectedItems[0]]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>ID</div>,
            cell: (item) => item.id || "-",
          },
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => (
              <NavLink
                to={
                  hasPermission
                    ? `/manageprovider/${providerId}/liabilityinsurance/${item.id}/edit`
                    : `#`
                }
              >
                {item?.name}
              </NavLink>
            ),
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(b?.name ?? ""),
          },
          {
            id: "policyNumber",
            header: <div>Policy Number </div>,
            cell: (item) => <div> {item?.policyNumber ?? "-"} </div>,
          },
          {
            id: "effectiveDate",
            header: <div>Effective Date</div>,
            cell: (item) =>
              item?.effectiveDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.effectiveDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "effectiveDate",
          },
          {
            id: "expirationDate",
            header: <div>Expiration Date</div>,
            cell: (item) =>
              item?.expirationDate ? (
                <span>
                  <CalendarOutlined />{" "}
                  {IncredableDateFormat(item?.expirationDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "expirationDate",
          },
          {
            id: "typeOfCoverage",
            header: <div>Type Of Coverage</div>,
            cell: (item) => <div> {item?.typeOfCoverage ?? "-"} </div>,
          },
          {
            id: "timeWithCurrentCarrierValue",
            header: <div>Time With Current Carrier Value</div>,
            cell: (item) => (
              <div>
                {item?.timeWithCurrentCarrierValue ?? "-"}{" "}
                {item?.timeWithCurrentCarrierUnit ?? "-"}{" "}
              </div>
            ),
          },
          {
            id: "coveragePerOccurrence",
            header: <div>Coverage Per Occurance </div>,
            cell: (item) => <div> {item?.coveragePerOccurrence ?? "-"} </div>,
          },
          {
            id: "aggregateCoverage",
            header: <div>Aggregate Coverage</div>,
            cell: (item) => <div> {item?.aggregateCoverage ?? "-"} </div>,
          },
          {
            id: "email",
            header: <div>Email</div>,
            cell: (item) => <div> {item?.email ?? "-"} </div>,
          },
          {
            id: "phone",
            header: <div>Phone </div>,
            cell: (item) => (
              <div> {new AsYouType("US").input(item?.phone ?? "") ?? "-"} </div>
            ),
          },
          {
            id: "fax",
            header: <div>Fax</div>,
            cell: (item) => <div> {item?.fax ?? "-"} </div>,
          },
          {
            id: "addressLane1",
            header: <div>Address Line 1</div>,
            cell: (item) => <div> {item?.addressLane1 ?? "-"} </div>,
          },
          {
            id: "addressLane2",
            header: <div>Address Line 2</div>,
            cell: (item) => <div> {item?.addressLane2 ?? "-"} </div>,
          },
          {
            id: "city",
            header: <div>City</div>,
            cell: (item) => <div> {item?.city ?? "-"} </div>,
          },
          {
            id: "state",
            header: <div>State</div>,
            cell: (item) => <div> {item?.state ?? "-"} </div>,
          },
          {
            id: "zip",
            header: <div>Zipcode </div>,
            cell: (item) => <div> {item?.zip ?? "-"} </div>,
          },
          {
            id: "country",
            header: <div>Country </div>,
            cell: (item) => <div> {item?.country ?? "-"} </div>,
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={LIABILITYINSURANCE_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(
                              `liabilityinsurance/${
                                selectedRows?.at(0)?.id
                              }/edit`
                            );
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => navigate(`liabilityinsurance/add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title
                level={3}
                style={{ marginTop: "0", fontSize: "22px" }}
              >
                Liability Insurance{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${
                  provider?.providerLiabilityInsurances?.length || 0
                })`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteProviderLiabilityInsurance({
              providerId: providerId,
              liabilityInsuranceId: selectedRows?.at(0)?.id + "",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Liability Insurance deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Liability Insurance"
        content={
          <Typography.Text>
            Name:{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.name}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this liability insurance?"
      />
    </>
  );
}
