import { Col, Divider, Flex, Form, Row, Space, Typography } from "antd";
import { useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import IncredableButton from "../../../components/button";
import IncredableContent from "../../../components/incredable-content";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import { CredentialPackageType } from "../../../redux/api/credentialpackages/types";
import { useAddPackageTypeMutation } from "../../../redux/api/credentialpackages/credentialpackages";

type FormInputType = CredentialPackageType;

export default function AddPackageType() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [addPackageType] = useAddPackageTypeMutation();

  return (
    <IncredableContent
      breadcrumbGroupProps={{
        items: [
          {
            text: "Package Type",
            href: "/packagetype",
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addpackagetype"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();
            await addPackageType(data);
            toastContext?.openInfoNotification({
              message: `Package Type saved successfully`,
              placement: "topRight",
            });
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Package Type
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="packagetype" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Description",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `description`,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
