import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import IncredableButton from "../../components/button";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ToastContext } from "../../context/toast/incredable-toast";
import { SpinnerContext } from "../../context/spinner/spinner";
import IncredableContent from "../../components/incredable-content";
import { FacilityTypeRequest } from "../../redux/api/facility/types";
import { useSaveFacilityTypeMutation } from "../../redux/api/facility/facility";

const { useToken } = theme;

type FormInputType = FacilityTypeRequest;
export default function AddFacilityType() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [addFacilityType] = useSaveFacilityTypeMutation();
  return (
    <IncredableContent
      breadcrumbGroupProps={{
        items: [
          {
            text: "Facility Type",
            href: "/facilitytype",
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addfacilitytype"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();
            await addFacilityType({
              name: data.name,
              description: data.description,
            });
            toastContext?.openInfoNotification({
              message: `Facility Type saved successfully`,
              placement: "topRight",
            });
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Facility Type
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Add Facility Type
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="facilitytype" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Description",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `description`,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
