import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Space,
  Typography,
  DatePicker,
  Upload,
} from "antd";
import IncredableButton from "../components/button";
import { RcFile, UploadFile } from "antd/lib/upload";
import { useContext, useEffect, useState } from "react";
import { DocumentRequest } from "../redux/api/document/types";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Input,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter,
} from "@cloudscape-design/components";
import prettyBytes from "pretty-bytes";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { differenceWith, set } from "lodash";
import { useSaveDocumentMutation } from "../redux/api/document/document";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { fileAxios } from "../context/axios";
import { getFileUrl } from "../config";
import { captureException } from "@sentry/browser";
import IncredableDateFormat from "../common/incredable-date-format";
import { alertReminderOptions } from "../common/constants";
import { PATH_DASHBOARD } from "../routes/path";

const { Dragger } = Upload;

function BulkUpload() {
  const [selectedItems, setSelectedItems] = useState<DocumentRequest[]>([]);
  const [documents, setDocuments] = useState<DocumentRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtertedDocuments, setFiltertedDocuments] = useState<
    DocumentRequest[]
  >([]);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState<string>("");
  const { data: categories = [] } = useGetAllCategoryQuery();
  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const [searchParams] = useSearchParams();
  const providerId = searchParams.get("providerId");

  useEffect(() => {
    if (!!filterText) {
      const temp = documents.filter(
        (document) =>
          !!document.name &&
          document.name.toLowerCase().includes(filterText.toLowerCase())
      );
      setFiltertedDocuments(temp);
    } else setFiltertedDocuments(documents);
  }, [filterText, documents]);
  const [saveDocument] = useSaveDocumentMutation();
  const { items, collectionProps, paginationProps } = useCollection(
    filtertedDocuments,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="s">
              <b>No files selected to upload.</b>
            </SpaceBetween>
          </Box>
        ),
      },
      sorting: {
        defaultState: {
          isDescending: true,
          sortingColumn: {
            sortingField: "createdDate",
          },
        },
      },
      pagination: {
        pageSize: preferences.pageSize,
      },
    }
  );

  const uploadProps = {
    name: "file",
    multiple: true,
    beforeUpload: (file: RcFile) => {
      const newFile = {
        key: uuidv4(),
        name: file.name,
        expirationDate: "",
        alertReminders: "",
        notes: "",
        categoryId: "",
        providerId: "",
        facilityId: "",
        file: file,
      };
      setDocuments((prev) => [...prev, newFile]);
      return false;
    },
    onRemove: (file: UploadFile) => {
      setDocuments((prev) => prev.filter((item) => item.name !== file.name));
    },
  };
  const breadcrumbProps = [
    {
      title: <NavLink to={"/safe"}>Safe</NavLink>,
    },
    {
      title: "Bulk Upload",
    },
  ];
  return (
    <div
      style={{
        padding: "12px 24px 12px 24px",
      }}
    >
      <div
        style={{
          padding: "0 0 16px 0",
        }}
      >
        <Breadcrumb items={breadcrumbProps} />
      </div>
      <Card
        title={
          <div style={{ marginTop: "10px" }}>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Upload Multiple Documents
            </Typography.Title>
            <Typography.Text type="secondary">
              Add the file you want to upload
            </Typography.Text>
          </div>
        }
        style={{
          borderRadius: "16px",
          boxShadow: "0px 10px 25px 0px #5911920D",
          marginBottom: "20px",
        }}
      >
        <Dragger
          {...uploadProps}
          showUploadList={false}
          style={{
            border: "1px dashed #598BEB",
            background: "#F6FAFF",
            height: "200px",
          }}
        >
          <Space direction="vertical">
            <Space>
              <InboxOutlined style={{ fontSize: "32px", color: "#7C4095" }} />
              <span>Drag and Drop your file(s) to start uploading</span>
            </Space>
            OR
            <IncredableButton
              style={{
                background: "#598BEB",
                color: "#FFF",
                borderColor: "#598BEB",
              }}
            >
              Select a File
            </IncredableButton>
          </Space>
        </Dragger>
      </Card>
      <Table
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => {
          if (!!detail && !!detail.selectedItems)
            setSelectedItems(detail.selectedItems);
        }}
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => item.name,
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.name}
                        onChange={(event) => {
                          setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
          },
          {
            id: "categoryId",
            header: "Category",
            cell: (item) =>
              categories.find(
                (category) => category.id + "" === item?.categoryId + ""
              )?.name ?? "-",
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    const value = currentValue ?? item.categoryId;
                    return (
                      <Select
                        autoFocus={true}
                        filteringType="auto"
                        expandToViewport={true}
                        selectedOption={{
                          label: categories.find(
                            (category) => category.id + "" === value + ""
                          )?.name,
                          value: categories.find(
                            (category) => category.id + "" === value + ""
                          )?.id,
                        }}
                        onChange={(event) => {
                          setValue(
                            event.detail.selectedOption.value ?? item.categoryId
                          );
                        }}
                        options={categories.map((category) => ({
                          label: category?.name,
                          value: category?.id + "",
                        }))}
                      />
                    );
                  },
                },
          },
          {
            id: "providerId",
            header: "Provider",
            cell: (item) => {
              const provider = providers.find(
                (provider) =>
                  provider?.id + "" ===
                  (providerId ? providerId : item?.providerId + "")
              );
              if (provider?.id && providerId) {
                item.providerId = provider.id + "";
              }

              return `${provider?.lastName || ""} ${
                provider?.firstName || "-"
              }`;
            },
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    const value = currentValue ?? item.providerId;
                    return (
                      <Select
                        autoFocus={true}
                        filteringType="auto"
                        expandToViewport={true}
                        selectedOption={{
                          label: providers.find(
                            (provider) => provider.id + "" === value + ""
                          )?.firstName,
                          value: providers.find(
                            (provider) => provider.id + "" === value + ""
                          )?.id,
                        }}
                        onChange={(event) => {
                          setValue(
                            event.detail.selectedOption.value ?? item.providerId
                          );
                        }}
                        options={providers.map((provider) => ({
                          label: `${provider?.lastName} ${provider?.firstName}`,
                          value: provider?.id + "",
                        }))}
                      />
                    );
                  },
                  disabledReason: () => {
                    if (providerId) {
                      return "You cannot change";
                    }
                    return undefined;
                  },
                },
          },
          {
            id: "facilityId",
            header: "Facility",
            cell: (item) =>
              facilities.find(
                (facility) => facility.id + "" === item?.facilityId + ""
              )?.name ?? "-",
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    const value = currentValue ?? item.facilityId;
                    return (
                      <Select
                        autoFocus={true}
                        filteringType="auto"
                        expandToViewport={true}
                        selectedOption={{
                          label: facilities.find(
                            (facility) => facility.id + "" === value + ""
                          )?.name,
                          value: facilities.find(
                            (facility) => facility.id + "" === value + ""
                          )?.id,
                        }}
                        onChange={(event) => {
                          setValue(
                            event.detail.selectedOption.value ?? item.facilityId
                          );
                        }}
                        options={facilities.map((facility) => ({
                          label: facility?.name,
                          value: facility?.id + "",
                        }))}
                      />
                    );
                  },
                },
          },
          {
            id: "name",
            header: "Name",
            cell: (item) => item.name,
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.name}
                        onChange={(event) => {
                          setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
          },
          {
            id: "size",
            header: "Size",
            cell: (item) =>
              !!item?.file?.size ? prettyBytes(item?.file?.size) : "-",
          },
          {
            id: "expirationDate",
            header: "Expiration Date",
            cell: (item) => IncredableDateFormat(item?.expirationDate) || "-",
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    const value = currentValue ?? item?.expirationDate;
                    return (
                      <DatePicker
                        style={{ width: "100%" }}
                        value={IncredableDateFormat(value)}
                        onChange={(date, dateString) => {
                          console.log("date", dateString);

                          //   if (date) {
                          //     setValue(date.format("YYYY-MM-DD") + "T00:00:00");
                          //   } else {
                          //     setValue(null);
                          //   }
                        }}
                        format={"MM-DD-YYYY"}
                      />
                    );
                  },
                },
          },
          {
            id: "alertDays",
            header: "Alert Days",
            cell: (item) => item?.alertDays || "-",
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    const value = currentValue ?? item?.alertDays;
                    return (
                      <Select
                        autoFocus={true}
                        filteringType="auto"
                        expandToViewport={true}
                        selectedOption={{
                          label: alertReminderOptions.find(
                            (alert) => alert?.value + "" === value + ""
                          )?.label,
                          value: alertReminderOptions?.find(
                            (alert) => alert?.value + "" === value + ""
                          )?.value,
                        }}
                        onChange={(event) => {
                          setValue(
                            event.detail?.selectedOption.value ?? item.alertDays
                          );
                        }}
                        options={alertReminderOptions?.map((alert) => ({
                          label: alert?.label,
                          value: alert?.value,
                        }))}
                      />
                    );
                  },
                },
          },

          {
            id: "notes",
            header: "Notes",
            cell: (item) => item.notes,
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.notes}
                        onChange={(event) => {
                          setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
          },
        ]}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "categoryId", visible: true },
          { id: "providerId", visible: true },
          { id: "facilityId", visible: true },
          { id: "notes", visible: true },
          { id: "size", visible: true },
          { id: "expirationDate", visible: true },
          { id: "alertDays", visible: true },
          { id: "type", visible: true },
        ]}
        items={items}
        loadingText="Loading resources"
        selectionType="multi"
        wrapLines={true}
        trackBy={(item) => item?.name + item?.file?.size + item?.file?.type}
        stripedRows={true}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => {
              if (!!detail) setPreferences(detail);
            }}
            preferences={preferences}
            pageSizePreference={{
              options: [
                { value: 10, label: "10 items" },
                { value: 30, label: "30 items" },
                { value: 50, label: "50 items" },
              ],
            }}
          />
        }
        {...collectionProps}
        submitEdit={async (editedItem, column, newValue) => {
          let editItemIndex = documents.findIndex((document) =>
            compareItems(document, editedItem)
          );
          if (editItemIndex === -1 && !column.id) return;
          const propertyPath = column.id ?? "";
          set(editedItem, propertyPath, newValue);
          const temp = [...documents];
          temp[editItemIndex] = { ...editedItem };
          setDocuments(temp);
        }}
        filter={
          <TextFilter
            filteringPlaceholder="Find by name"
            filteringText={filterText}
            onChange={({ detail }) => {
              setFilterText(detail.filteringText);
            }}
            countText={
              !!filterText ? `${filtertedDocuments?.length} matches` : ""
            }
          />
        }
        header={
          <Header
            counter={"(" + documents.length.toString() + ")"}
            description="All the files in this table will be uploaded."
            actions={
              <Space direction="horizontal" size="small">
                <Button
                  disabled={!selectedItems?.length}
                  onClick={() => {
                    if (!!selectedItems && !!selectedItems?.length) {
                      const temp = differenceWith(
                        documents,
                        selectedItems,
                        compareItems
                      );
                      setDocuments(temp);
                      setSelectedItems([]);
                    }
                  }}
                >
                  Remove
                </Button>
                <Upload {...uploadProps} showUploadList={false}>
                  <Button icon={<PlusOutlined />}>Add File</Button>
                </Upload>
                <IncredableButton
                  type="primary"
                  disabled={!!isLoading}
                  onClick={async () => {
                    documents?.forEach(async (item) => {
                      if (!item.providerId && !item.facilityId) {
                        toastContext?.openErrorNotification({
                          message: `Provider or Facility is required.`,
                          placement: "top",
                        });
                        return;
                      }
                      spinnerContext?.showSpinner();
                      await saveDocument({
                        document: {
                          name: item?.name,
                          expirationDate: item?.expirationDate,
                          alertDays: item?.alertDays,
                          notes: item?.notes,
                          categoryId: item?.categoryId,
                          file: item?.file,
                          facilityId: item?.facilityId,
                          providerId: item?.providerId,
                        },
                      })
                        .unwrap()
                        .then(async (documentResponse) => {
                          if (item.file && documentResponse?.attachment?.key) {
                            try {
                              await fileAxios.put(
                                getFileUrl(documentResponse?.attachment?.key),
                                item.file
                              );
                              toastContext?.openSuccessNotification({
                                message: `Documents saved successfully`,
                                placement: "topRight",
                              });
                            } catch (error) {
                              captureException(error);
                              toastContext?.openErrorNotification({
                                message: `Failed to save Documents`,
                                placement: "topRight",
                              });
                            }
                          } else
                            toastContext?.openSuccessNotification({
                              message: `Documents saved successfully`,
                              placement: "topRight",
                            });
                        })
                        .catch((error) => {
                          captureException(error);
                          if (error.status < 500 && error.status >= 400) {
                            toastContext?.openErrorNotification({
                              message: `Failed to save Documents`,
                              placement: "topRight",
                            });
                            return;
                          } else {
                            toastContext?.openErrorNotification({
                              message: `Failed to save Documents`,
                              placement: "topRight",
                            });
                            return;
                          }
                        });
                      spinnerContext?.hidespinner();
                    });
                    if (providerId) navigate(-1);
                    else navigate(PATH_DASHBOARD.safe.list);
                  }}
                >
                  Save
                </IncredableButton>
              </Space>
            }
          >
            Files
          </Header>
        }
      />
    </div>
  );
}
export default BulkUpload;

const compareItems = (a: DocumentRequest, b: DocumentRequest) => {
  return (
    a.name === b.name &&
    a?.file?.size === b?.file?.size &&
    a?.file?.type === b?.file?.type
  );
};
