import { Button, ConfigProvider, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  useExportAllDocumentsReportMutation,
  useLazyGetAllDocumentsQuery,
} from "../redux/api/reporting/reporting";
import IncredableDateFormat from "../common/incredable-date-format";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import useFileExport from "../hooks/useFileExport";

export default function AllDocumentsReport() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { taskId, setTaskId } = useFileExport("csv");
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });

  const [trigger, { data: documentsReportsData, isLoading, isFetching }] =
    useLazyGetAllDocumentsQuery({});
  const [exportAllDocumentsReport] = useExportAllDocumentsReportMutation();

  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  useEffect(() => {
    debouncedTrigger({
      pageNumber,
      pageSize,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [pageNumber, pageSize, sortingDetails, debouncedTrigger]);
  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => <span>{item?.name}</span>,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "providerLastName",
            header: "Provider Last Name",
            cell: (item) => item?.provider?.lastName,
            sortingField: "provider?.lastName",
          },
          {
            id: "providerFirstName",
            header: "Provider First Name",
            cell: (item) => item?.provider?.firstName,
            sortingField: "provider?.firstName",
          },
          {
            id: "providerFirstName",
            header: "Provider First Name",
            cell: (item) => item?.provider?.firstName,
            sortingField: "provider?.firstName",
          },
          {
            id: "npiNumber",
            header: "NPI Number",
            cell: (item) => item?.provider?.npiNumber,
            sortingField: "provider.npiNumber",
          },
          {
            id: "email",
            header: "Email",
            cell: (item) => item?.provider?.email,
            sortingField: "provider.email",
          },
          {
            id: "licenseType",
            header: "License Type",
            cell: (item) => (
              <span>{item?.provider?.licenseType?.name || "-"}</span>
            ),
            sortingField: "provider.licenseType.name",
          },
          {
            id: "identificationNumber",
            header: "Identification Number",
            cell: (item) => (
              <span>{item?.provider?.identificationNumber || "-"}</span>
            ),
            sortingField: "provider.identificationNumber",
          },
          {
            id: "providerFacility",
            header: "Provider Facility",
            cell: (item) => (
              <span>{item?.provider?.facility?.name || "-"}</span>
            ),
            sortingField: "provider.facility.name",
          },
          {
            id: "documentFacility",
            header: "Document Facility",
            cell: (item) => <span>{item?.facility?.name || "-"}</span>,
            sortingField: "facility.name",
          },
          {
            id: "category",
            header: "Category",
            cell: (item) => item?.category?.name,
            sortingField: "category",
          },
          {
            id: "createdDate",
            header: "Created Date",
            cell: (item) => IncredableDateFormat(item?.createdDate),
            sortingField: "email",
          },
          {
            id: "expirationDate",
            header: "Expiration Date",
            cell: (item) => IncredableDateFormat(item?.expirationDate),
            sortingField: "expirationDate",
          },
          {
            id: "notes",
            header: "Notes",
            cell: (item) => item?.notes,
          },
        ]}
        items={!!documentsReportsData ? documentsReportsData?.documents : []}
        pagination={
          <Pagination
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!pageSize && pageSize !== 0
                ? Math.ceil(
                    !!documentsReportsData
                      ? documentsReportsData?.totalRecords / pageSize
                      : 0
                  )
                : 1
            }
          />
        }
        loadingText="Loading resources"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    loading={!!taskId}
                    onClick={() => {
                      const taskId = "Alldocuments_report_" + Date.now();
                      exportAllDocumentsReport({
                        taskId,
                      });
                      setTaskId(taskId);
                    }}
                  >
                    Export CSV
                  </Button>
                </ConfigProvider>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                All Documents Reports{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${
                  documentsReportsData?.totalRecords || 0
                })`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
      />
    </div>
  );
}
