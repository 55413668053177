import { api } from "..";
import { Trainee, TraineeRequest } from "./types";

const FacilityAPI = api.injectEndpoints({
    endpoints: (build) => ({
        getAllTrainee: build.query<Trainee[], void>({
            query: () => "/trainee/all",
            providesTags: ["Trainee"],
        }),
        getTrainee: build.query<Trainee, { id: string }>({
            query: ({ id }) => `/trainee/${id}`,
            providesTags: ["Trainee"],
        }),

        addTrainee: build.mutation<void, TraineeRequest>({
            query: ({ ...body }) => {
                return {
                    url: "trainee",
                    method: "POST",
                    body: body,
                };
            },
            invalidatesTags: ["Trainee"],
        }),
        updateTrainee: build.mutation<
            void,
            Pick<Trainee, "id"> & Partial<TraineeRequest>
        >({
            query: ({ id, ...patch }) => {
                return {
                    url: `trainee/${id}`,
                    method: "PUT",
                    body: patch,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Trainee", id: arg.id },
                { type: "Trainee" },
            ],
        }),
        deleteTrainee: build.mutation<
            void,
            {
                traineeId: string;
            }
        >({
            query: ({ traineeId }) => ({
                url: `trainee/${traineeId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Trainee"],
        }),
    }),
});

export const {
    useGetAllTraineeQuery,
    useGetTraineeQuery,
    useAddTraineeMutation,
    useUpdateTraineeMutation,
    useDeleteTraineeMutation,
} = FacilityAPI;
