import { Col, Divider, Flex, Form, Row, Space, Typography } from "antd";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import IncredableContent from "../../components/incredable-content";
import { FormProvider, useForm } from "react-hook-form";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import {
  useGetBasicAllProvidersQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useAddPracticeEvaluationMutation } from "../../redux/api/practiceevaluation/practiceevaluation";
import { PracticeEvaluationRequest } from "../../redux/api/practiceevaluation/types";
import { useGetAllDigitalFormsQuery } from "../../redux/api/digitalform/digitalforms";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { PrivilegeTypeOption, ResultOption, TriggerOption } from "../constants";
import { useGetAllPrivilegeTypesQuery } from "../../redux/api/privileges/privileges";

export default function AddPrivileges() {
  const methods = useForm<PracticeEvaluationRequest>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: privilegeType = [] } = useGetAllPrivilegeTypesQuery();
  const [addPracticeEvaluation] = useAddPracticeEvaluationMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const handleSubmit = methods.handleSubmit(async (data) => {
    spinnerContext?.showSpinner();
    try {
      await addPracticeEvaluation(data);
      toastContext?.openSuccessNotification({
        message: "Privileges added successfully",
        placement: "topRight",
      });
      navigate(-1);
    } catch {
      toastContext?.openErrorNotification({
        message: "Failed to add Privilege",
        placement: "topRight",
      });
    } finally {
      spinnerContext?.hidespinner();
    }
  });

  const { data: readyForms } = useGetAllDigitalFormsQuery();

  return (
    <>
      <IncredableContent
        breadcrumbGroupProps={{
          items: [
            {
              text: "Privileges",
              href: "/privileges",
            },
            {
              text: "Add",
              href: "#",
            },
          ],
        }}
      >
        <FormProvider {...methods}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Privilege
              </Typography.Text>
              <Space>
                <IncredableButton
                  secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </IncredableButton>
                <IncredableButton type="primary" htmlType="submit">
                  Save
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <RHFSelect
                  formItemProps={{ label: "Privilege Type" }}
                  controllerProps={{ name: "privilegeTypeId" }}
                  selectProps={{
                    style: { width: "100%" },
                    placeholder: "Select Privilege Type",
                    options: privilegeType.map((item) => ({
                      label: item?.name,
                      value: item?.id
                    })),
                    onModifyClick: () => navigate("/privilegetype"),
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={12}>
                <RHFSelect
                  formItemProps={{ label: "Trigger" }}
                  controllerProps={{ name: "trigger" }}
                  selectProps={{
                    style: { width: "100%" },
                    placeholder: "Select Trigger Status",
                    options: TriggerOption?.map((trigger) => ({
                      label: trigger.value,
                      value: trigger.value,
                    })),
                  }}
                />
              </Col>
              <Col span={12}>
                <RHFSelect
                  formItemProps={{ label: "Ready Form" }}
                  controllerProps={{ name: "readyFormId" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    placeholder: "Search to Select",
                    options: readyForms?.map((form) => ({
                      label: form?.name,
                      value: form?.id,
                    })),
                  }}
                />
              </Col>
              <Col span={12}>
                <RHFSelect
                  formItemProps={{ label: "Provider" }}
                  controllerProps={{ name: "providerId" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    options: providers?.map((provider) => ({
                      label: `${provider.firstName} ${provider.lastName}`,
                      value: provider.id,
                    })),
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={12}>
                <RHFSelect
                  formItemProps={{ label: "Result" }}
                  controllerProps={{ name: "result" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    options: ResultOption?.map((result) => ({
                      label: result.value,
                      value: result.value,
                    })),
                  }}
                />
              </Col>
              <Col span={12}>
                <RHFTextField
                  formItemProps={{ label: "Specific Incident" }}
                  controllerProps={{ name: "specificIncident" }}
                  inputProps={{}}
                />
              </Col>
              <Col span={12}>
                <RHFDatePicker
                  formItemProps={{ label: "Start Date" }}
                  controllerProps={{ name: "startDate" }}
                  datePickerProps={{
                    style: { width: "100%" },
                    placeholder: "Select Start Date",
                  }}
                />
              </Col>
              <Col span={12}>
                <RHFDatePicker
                  formItemProps={{ label: "Due Date" }}
                  controllerProps={{ name: "endDate" }}
                  datePickerProps={{
                    style: { width: "100%" },
                    placeholder: "Select Due Date",
                  }}
                />
              </Col>
            </Row>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <RHFTextArea
                  formItemProps={{ label: "Notes" }}
                  controllerProps={{ name: "notes" }}
                />
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </IncredableContent>
    </>
  );
}