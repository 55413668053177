import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Button, Modal, Space } from "antd";
import { Facility } from "../../../redux/api/facility/types";
import useFacilityFilter from "../../../table-filters/facility-filters";
import { useUpdateAssociatedLocationFacilityMutation } from "../../../redux/api/location/location";

export default function AssociatedFacilityModals({
  isAssociatedFaciityModalOpen,
  setAssociatedFaclityModalOpen,
  associatedFacility,
  setAssociatedFaclity,
}: {
  isAssociatedFaciityModalOpen: boolean;
  setAssociatedFaclityModalOpen: Dispatch<SetStateAction<boolean>>;
  associatedFacility: Facility[];
  setAssociatedFaclity: Dispatch<SetStateAction<Facility[]>>;
}) {
  const { locationId } = useParams();
  const [updateAssociatedLocationFacility] =
    useUpdateAssociatedLocationFacilityMutation();

  const { TableFilters, filteredFacilities, UseQueryHookResult } =
    useFacilityFilter();

  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      pagination: {},
    }
  );

  const [selectedFacility, setSelectedFacility] = useState<Facility[]>([]);

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: TableFilters,
      variant: "borderless",
      loading: UseQueryHookResult?.isLoading || UseQueryHookResult?.isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedFacility(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedFacility;
        if (
          !detail.item ||
          !!associatedFacility
            ?.map((associatedFacility) => associatedFacility?.id + "")
            ?.includes(detail.item.id + "")
        )
          return;
        if (temp?.includes(detail.item))
          setSelectedFacility(temp.filter((item) => item !== detail.item));
        else setSelectedFacility([detail.item, ...temp]);
      },
      selectedItems: selectedFacility,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "facilityType", visible: true },
        { id: "state", visible: true },
      ],
      isItemDisabled: (item) => {
        return associatedFacility
          ?.map((associatedFacility) => associatedFacility?.id + "")
          ?.includes(item?.id + "");
      },
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "facilityType",
          header: <div>Facility Type</div>,
          cell: (item) => <div>{item?.facilityType?.name ?? "-"}</div>,
        },
        {
          id: "state",
          header: <div>State</div>,
          cell: (item) => <div>{item?.facilityAddress?.state ?? "-"}</div>,
        },
      ],
    };
  }, [items, selectedFacility]);
  return (
    <Modal
      visible={isAssociatedFaciityModalOpen}
      title={
        <Header counter={`(${filteredFacilities.length})`}>Facility</Header>
      }
      onCancel={() => {
        setAssociatedFaclityModalOpen(false);
      }}
      footer={
        <Space direction="horizontal">
          <Button
            onClick={() => {
              setSelectedFacility([]);
              setAssociatedFaclityModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              try {
                if (!!selectedFacility && !!locationId) {
                  const locationFacilityToUpdate =
                    selectedFacility?.map(
                      (selectedFacilityItem) => selectedFacilityItem?.id + ""
                    ) || [];

                  await updateAssociatedLocationFacility({
                    locationId: locationId,
                    locationToUpdate: locationFacilityToUpdate,
                  });

                  setAssociatedFaclity([
                    ...associatedFacility,
                    ...selectedFacility,
                  ]);
                  setSelectedFacility([]);
                  setAssociatedFaclityModalOpen(false);
                }
              } catch (error) {
                console.error("Error updating admin location facility:", error);
              }
            }}
          >
            Submit
          </Button>
        </Space>
      }
      width={800}
      style={{ maxHeight: "90vh" }}
      styles={{
        body: { overflowY: "auto", maxHeight: "80vh" },
      }}
    >
      <div>
        <Table {...tableProps} />
      </div>
    </Modal>
  );
}
