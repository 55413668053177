import { Box, Header, PieChart } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import { useGetAllTasksQuery } from "../../../redux/api/task";
import { useGetDashboardTaskStatsQuery } from "../../../redux/api/dashboards/dashboard";

export const taskStatus: WidgetConfig = {
  title: "Task Status",
  description: "Overview of all your tasks",
  header: TaskStatusHeader,
  content: TaskStatusContent,
};

function TaskStatusHeader() {
  return <Header variant="h2">{taskStatus.title}</Header>;
}

function TaskStatusContent() {
  const { data } = useGetDashboardTaskStatsQuery();

  return (
    <div style={{ height: "400px" }}>
      <PieChart
        data={
          data?.map((task) => {
            return {
              title: task?.name,
              percentage: task?.count ?? 0,
              value: task?.count ?? 0,
            };
          }) ?? []
        }
        segmentDescription={(datum, sum) =>
          `${datum.value} tasks, ${((datum.value / sum) * 100).toFixed(0)}%`
        }
        detailPopoverContent={(datum, sum) => [
          {
            key: "Tasks count",
            value: datum.value,
          },
          {
            key: "Percentage",
            value: `${((datum.value / sum) * 100).toFixed(0)}%`,
          },
        ]}
        ariaDescription="Donut chart showing generic example data."
        ariaLabel="Donut chart"
        innerMetricDescription="Total Tasks"
        innerMetricValue={`${
          data?.reduce((sum, item) => sum + item.count, 0) ?? 0
        }`}
        size="medium"
        fitHeight
        variant="donut"
        hideFilter
        empty={
          <Box textAlign="center" color="inherit">
            <b>No data available</b>
            <Box variant="p" color="inherit">
              There is no data available
            </Box>
          </Box>
        }
      />
    </div>
  );
}
