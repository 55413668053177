import { Col, Divider, Flex, Form, Row, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";
import {
  useGetTaskQuery,
  useUpdateTaskMutation,
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import { TaskRequest } from "../../redux/api/task/types";
import { alertReminderOptions } from "../../common/constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";

export default function EditTask() {
  const methods = useForm<TaskRequest>();
  const { taskId } = useParams();
  const {
    data: task,
    isFetching,
    isLoading,
  } = useGetTaskQuery(!!taskId ? { id: taskId } : skipToken);
  const { data: organization } = useGetOrganizationDetailsQuery();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("task.edit") ?? false)
  );
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const [updateTask] = useUpdateTaskMutation();

  useEffect(() => {
    if (!!task) {
      methods.setValue("name", task.name ?? "");
      methods.setValue("description", task.description ?? "");
      methods.setValue("taskStatusId", task.taskStatus?.id ?? "");
      methods.setValue("taskTypeId", task.taskType?.id ?? "");
      methods.setValue("workflowId", task.workflow?.id ?? "");
      methods.setValue("adminId", task.admin?.id ?? "");
      methods.setValue("providerId", task.provider?.id ?? "");
      methods.setValue("facilityId", task.facility?.id ?? "");
      methods.setValue("startDate", task.startDate ?? "");
      methods.setValue("dueDate", task.dueDate ?? "");
      methods.setValue("completedDate", task?.completedDate ?? "");
      methods.setValue("alertDays", task.alertDays ?? "");
    }
  }, [methods, task]);

  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const { data: workflows = [] } = useGetAllWorkflowQuery();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();

  return (
    <IncredableContent
      spinning={isLoading || isFetching}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Tasks",
            href: "/tasks",
          },
          {
            text: "Edit",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="edittasks"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: TaskRequest) => {
            if (!!taskId) {
              spinnerContext?.showSpinner();
              await updateTask({
                id: taskId,
                ...data,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `tasks updated successfully`,
                placement: "topRight",
                onClose: () => {
                  navigate(-1);
                },
              });
            }
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Edit Tasks
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton
                disabled={!hasPermission}
                type="primary"
                htmlType="submit"
              >
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="tasks" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Task Type" }}
                            controllerProps={{
                              name: `taskTypeId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: taskTypes?.map((taskType) => {
                                return {
                                  label: taskType?.name,
                                  value: taskType?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/taskstype");
                              },
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Provider" }}
                            controllerProps={{
                              name: `providerId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: providers?.map((provider) => {
                                return {
                                  label: `${provider?.firstName} ${provider?.lastName}`,
                                  value: provider?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Task Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Task Status" }}
                            controllerProps={{
                              name: `taskStatusId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: taskstatuses?.map((taskStatus) => {
                                return {
                                  label: taskStatus?.name,
                                  value: taskStatus?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/taskstatus");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Assign To" }}
                            controllerProps={{
                              name: `adminId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: members?.map((member) => {
                                return {
                                  label: `${member?.firstName} ${member?.lastName}`,
                                  value: member?.id,
                                };
                              }),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility" }}
                            controllerProps={{
                              name: `facilityId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: facilities?.map((facility) => {
                                return {
                                  label: facility?.name,
                                  value: facility?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        {!!organization?.organizationServiceLimits
                          ?.workflows && (
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Associated Workflow" }}
                              controllerProps={{
                                name: `workflowId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: workflows?.map((workflow) => {
                                  return {
                                    label: workflow?.name,
                                    value: workflow?.id,
                                  };
                                }),
                              }}
                            />
                          </Col>
                        )}
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Start Date",
                            }}
                            controllerProps={{
                              name: `startDate`,
                            }}
                            datePickerProps={{
                              placeholder: "Select Start Date",
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Due Date",
                            }}
                            controllerProps={{
                              name: `dueDate`,
                            }}
                            datePickerProps={{
                              placeholder: "Select Due Date",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Completed Date",
                            }}
                            controllerProps={{
                              name: "completedDate",
                            }}
                            datePickerProps={{
                              placeholder: "Select Completed Date",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Follow Up Date" }}
                            controllerProps={{
                              name: `alertDays`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Days",
                              options: alertReminderOptions?.map((alert) => {
                                return {
                                  label: alert?.label,
                                  value: alert?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{ name: "description" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
