import { api } from "..";
import { PracticeEvaluation, PracticeEvaluationRequest } from "./types";

const PracticeEvaluationAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPracticeEvaluation: build.query<PracticeEvaluation[], void>({
      query: () => "/practiceevaluation/all",
      providesTags: ["PracticeEvaluation"],
    }),
    getPracticeEvaluation: build.query<PracticeEvaluation, { id: string }>({
      query: ({ id }) => `/practiceevaluation/${id}`,
      providesTags: ["PracticeEvaluation"],
    }),
    addPracticeEvaluation: build.mutation<void, PracticeEvaluationRequest>({
      query: ({ ...body }) => {
        return {
          url: "practiceevaluation",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["PracticeEvaluation"],
    }),
    updatePracticeEvaluation: build.mutation<
      void,
      { id: string } & Partial<PracticeEvaluationRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `practiceevaluation/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "PracticeEvaluation", id: arg.id },
        { type: "PracticeEvaluation" },
      ],
    }),
    // deleteCategory: build.mutation<
    //   void,
    //   {
    //     id: string;
    //   }
    // >({
    //   query: ({ id }) => ({
    //     url: `category/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["PracticeEvaluation"],
    // }),
  }),
});

export const {
  useGetAllPracticeEvaluationQuery,
  useGetPracticeEvaluationQuery,
  useAddPracticeEvaluationMutation,
  useUpdatePracticeEvaluationMutation,
  // useAddCategoryMutation,
  // useUpdateCategoryMutation,
  // useDeleteCategoryMutation,
} = PracticeEvaluationAPI;
