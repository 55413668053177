import { useEffect, useState } from "react";
import { useFileExistsQuery } from "../redux/api/reporting/reporting";
import { skipToken } from "@reduxjs/toolkit/query";
import { getFileUrl } from "../config";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

function useFileExport(type: string) {
  const [taskId, setTaskId] = useState<string>();
  const { data: org } = useGetOrganizationDetailsQuery();
  const { data, status } = useFileExistsQuery(
    !!taskId ? { taskId: taskId + "." + type } : skipToken,
    { pollingInterval: 3000 }
  );

  useEffect(() => {
    if (status === "fulfilled") {
      window.open(getFileUrl(`exports/${org?.id}/` + taskId + "." + type));
      setTaskId(undefined);
    }
  }, [status]);
  return { taskId, setTaskId };
}

export default useFileExport;
