import queryString from "query-string";
import { api } from "..";
import {
  PaginationDocumentsReport,
  PaginationExpiredDocuments,
  PaginationPayerEnrollmentReport,
  PaginationProviderAffiliationReport,
  PaginationProviderReport,
} from "./types";

const reportingAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDocuments: build.query<
      PaginationDocumentsReport,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({ pageNumber, pageSize, sortBy, direction }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/documents?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getPayerEnrollmentsReport: build.query<
      PaginationPayerEnrollmentReport,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({ pageNumber, pageSize, sortBy, direction }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/payerenrollment?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getProviderAffiliationReport: build.query<
      PaginationProviderAffiliationReport,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({ pageNumber, pageSize, sortBy, direction }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/providersaffiliations?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getAllExpiredDocuments: build.query<
      PaginationExpiredDocuments,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({ pageNumber, pageSize, sortBy, direction }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/documents/expired?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getAllExpiringDocuments: build.query<
      PaginationExpiredDocuments,
      {
        days?: string;
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({ days, pageNumber, pageSize, sortBy, direction }) => {
        const requestParams = queryString.stringify(
          {
            days,
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/documents/expiring?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getAllProvidersReport: build.query<
      PaginationProviderReport,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({ pageNumber, pageSize, sortBy, direction }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/providers?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    exportAllProvidersReport: build.mutation<
      void,
      {
        taskId: string;
      }
    >({
      query: ({ taskId }) => {
        return {
          url: "reporting/providers/export",
          method: "POST",
          body: { taskId },
        };
      },
    }),
    exportPayerEnrollmentsReport: build.mutation<
      void,
      {
        taskId: string;
      }
    >({
      query: ({ taskId }) => {
        return {
          url: "reporting/payerenrollment/export",
          method: "POST",
          body: { taskId },
        };
      },
    }),
    exportProviderAffiliationsReport: build.mutation<
      void,
      {
        taskId: string;
      }
    >({
      query: ({ taskId }) => {
        return {
          url: "reporting/provideraffiliation/export",
          method: "POST",
          body: { taskId },
        };
      },
    }),
    exportAllDocumentsReport: build.mutation<
      void,
      {
        taskId: string;
      }
    >({
      query: ({ taskId }) => {
        return {
          url: "reporting/documents/export",
          method: "POST",
          body: { taskId },
        };
      },
    }),
    exportExpiringDocumentsReport: build.mutation<
      void,
      {
        taskId: string;
        days: string;
      }
    >({
      query: ({ taskId, days }) => {
        return {
          url: `reporting/documents/expiring/export?days=${days}`,
          method: "POST",
          body: { taskId },
        };
      },
    }),
    exportExpiredDocumentsReport: build.mutation<
      void,
      {
        taskId: string;
      }
    >({
      query: ({ taskId }) => {
        return {
          url: "reporting/documents/expired/export",
          method: "POST",
          body: { taskId },
        };
      },
    }),
    fileExists: build.query<
      void,
      {
        taskId: string;
      }
    >({
      query: ({ taskId }) => {
        return `exports/file/exists?fileKey=${taskId}`;
      },
    }),
    exportAttachments: build.mutation<
      void,
      {
        taskId: string;
        attachmentKeys: string[];
      }
    >({
      query: ({ taskId, attachmentKeys }) => {
        return {
          url: `export/${taskId}`,
          method: "POST",
          body: attachmentKeys,
        };
      },
    }),
  }),
});
export const {
  useLazyGetProviderAffiliationReportQuery,
  useLazyGetPayerEnrollmentsReportQuery,
  useGetPayerEnrollmentsReportQuery,
  useGetProviderAffiliationReportQuery,
  useGetAllDocumentsQuery,
  useLazyGetAllDocumentsQuery,
  useLazyGetAllExpiredDocumentsQuery,
  useGetAllExpiredDocumentsQuery,
  useLazyGetAllExpiringDocumentsQuery,
  useGetAllExpiringDocumentsQuery,
  useLazyGetAllProvidersReportQuery,
  useGetAllProvidersReportQuery,
  useExportAllProvidersReportMutation,
  useExportPayerEnrollmentsReportMutation,
  useExportProviderAffiliationsReportMutation,
  useExportAllDocumentsReportMutation,
  useExportExpiringDocumentsReportMutation,
  useExportExpiredDocumentsReportMutation,
  useFileExistsQuery,
  useExportAttachmentsMutation,
} = reportingAPI;
