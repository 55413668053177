import { Card, Col, Divider, Form, Row, Space, Tabs, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import IncredableContent from "../../components/incredable-content";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import TabPane from "antd/es/tabs/TabPane";
import { WorkflowUpdateRequest } from "../../redux/api/workflow/types";
import {
  useGetAllWorkflowStatusQuery,
  useGetAllWorkflowTemplateQuery,
  useGetWorkflowQuery,
  useUpdateWorkflowMutation,
} from "../../redux/api/workflow/workflow";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import IncredableButton from "../../components/button";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import TasksListV2 from "../workflowtasks/tasks-list-v2";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";

export default function EditWorkflow() {
  const methods = useForm<WorkflowUpdateRequest>();
  const [showTextArea, setShowTextArea] = useState(false);
  const { workflowId } = useParams();
  const { data: workflow } = useGetWorkflowQuery(
    !!workflowId ? { id: workflowId } : skipToken
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("workflow.edit") ?? false)
  );
  const [updateWorkflow] = useUpdateWorkflowMutation();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: workflowStatuses, isLoading } = useGetAllWorkflowStatusQuery();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: facilities } = useGetFacilitiesQuery();
  const { data: workflowTemplates } = useGetAllWorkflowTemplateQuery();

  const handleLabelClick = () => {
    setShowTextArea((prev) => !prev);
  };

  useEffect(() => {
    if (!!workflow) {
      methods.setValue("name", workflow.name ?? "");
      methods.setValue("workflowStatusId", workflow.workFlowStatus?.id ?? "");
      methods.setValue(
        "workflowTemplateId",
        workflow.workflowTemplate?.id ?? ""
      );
      methods.setValue("adminId", workflow.admin?.id ?? "");
      methods.setValue("providerId", workflow.provider?.id ?? "");
      methods.setValue("facilityId", workflow.facility?.id ?? "");
      methods.setValue("startDate", workflow.startDate ?? "");
      methods.setValue("dueDate", workflow.dueDate ?? "");
      methods.setValue("completedDate", workflow.completedDate ?? "");
      methods.setValue("notes", workflow.notes ?? "");
    }
  }, [methods, workflow]);

  const onSubmit = async (data: WorkflowUpdateRequest) => {
    if (!!workflowId) {
      spinnerContext?.showSpinner();
      await updateWorkflow({ id: workflowId, ...data });
      spinnerContext?.hidespinner();
      toastContext?.openSuccessNotification({
        message: `workflow updated successfully`,
        placement: "topRight",
        onClose: () => {
          navigate(-1);
        },
      });
    }
  };
  const [activeTabKey, setActiveTabKey] = useState<string>("details");
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };
  const tabList = [
    {
      key: "details",
      label: <span style={{ color: "rgb(88 82 82)" }}>Details</span>,
    },
    {
      key: "tasks",
      label: <span style={{ color: "rgb(88 82 82)" }}>Tasks</span>,
    },
  ];
  return (
    <IncredableContent
      spinning={isLoading}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Workflow",
            href: "/Workflows",
          },
          {
            text: workflow?.name || "",
            href: "#",
          },
        ],
      }}
    >
      <Card
        style={{ width: "100%" }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        tabProps={{
          size: "middle",
        }}
      >
        {activeTabKey === "details" ?
          <>
            <Space style={{ paddingBottom: "20px" }}>
              <Typography.Text
                strong
                style={{
                  fontSize: "18px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "500",
                }}
              >
                Edit Workflow
              </Typography.Text>
            </Space>
            <FormProvider {...methods}>
              <Form layout="vertical">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={8}>
                    <RHFTextField
                      formItemProps={{
                        label: "Workflow Name",
                      }}
                      inputProps={{}}
                      controllerProps={{
                        name: `name`,
                      }}
                      rules={{ required: "This field is required" }}
                    />
                  </Col>
                  <Col span={8}>
                    <RHFSelect
                      formItemProps={{
                        label: "Workflow Status",
                      }}
                      controllerProps={{
                        name: "workflowStatusId",
                      }}
                      selectProps={{
                        allowClear: true,
                        showSearch: true,
                        placeholder: "Select Workflow Status",
                        options: workflowStatuses?.map((status) => {
                          return {
                            label: status?.name,
                            value: status?.id,
                          };
                        }),
                        onModifyClick: () => {
                          navigate("/workflowstatus");
                        },
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <RHFSelect
                      formItemProps={{ label: "Assigned To" }}
                      controllerProps={{
                        name: "adminId",
                      }}
                      selectProps={{
                        allowClear: true,
                        showSearch: true,
                        placeholder: "",
                        options: members?.map((member) => {
                          return {
                            label: `${member?.firstName} ${member?.lastName}`,
                            value: member?.id,
                          };
                        }),
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <RHFSelect
                      formItemProps={{ label: "Provider" }}
                      controllerProps={{
                        name: `providerId`,
                      }}
                      selectProps={{
                        allowClear: true,
                        showSearch: true,
                        placeholder: "Search to Select",
                        options: providers?.map((provider) => {
                          return {
                            label: `${provider?.firstName} ${provider?.lastName}`,
                            value: provider?.id,
                          };
                        }),
                        disabled: true,
                      }}
                      rules={{ required: "This field is required" }}
                    />
                  </Col>
                  <Col span={8}>
                    <RHFSelect
                      formItemProps={{ label: "Workflow Template" }}
                      controllerProps={{
                        name: "workflowTemplateId",
                      }}
                      selectProps={{
                        allowClear: true,
                        showSearch: true,
                        placeholder: "",
                        options: workflowTemplates?.map((workflowtemplate) => ({
                          label: workflowtemplate?.name,
                          value: workflowtemplate?.id,
                        })),
                        disabled: true,
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <RHFSelect
                      formItemProps={{ label: "Facility" }}
                      controllerProps={{
                        name: "facilityId",
                      }}
                      selectProps={{
                        allowClear: true,
                        showSearch: true,
                        placeholder: "Search to Select",
                        options: facilities?.map((facility) => ({
                          label: facility.name,
                          value: facility.id,
                        })),
                        disabled: true,
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <RHFDatePicker
                      formItemProps={{
                        label: "Start Date",
                      }}
                      controllerProps={{
                        name: "startDate",
                      }}
                      datePickerProps={{
                        placeholder: "Select Start Date",
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <RHFDatePicker
                      formItemProps={{
                        label: "Due Date",
                      }}
                      controllerProps={{
                        name: "dueDate",
                      }}
                      datePickerProps={{
                        placeholder: "Select Due Date",
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <RHFDatePicker
                      formItemProps={{
                        label: "Completed Date",
                      }}
                      controllerProps={{
                        name: "completedDate",
                      }}
                      datePickerProps={{
                        placeholder: "Completed Date",
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <label
                      onClick={handleLabelClick}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginLeft: "5px",
                      }}
                    >
                      {showTextArea ? <RightOutlined /> : <DownOutlined />} Notes{" "}
                    </label>
                    <Divider style={{ margin: "5px", padding: "0" }} />
                    {showTextArea && (
                      <RHFTextArea
                        formItemProps={{ label: "Notes" }}
                        controllerProps={{ name: "notes" }}
                      />
                    )}
                  </Col>
                </Row>
                <Space
                  style={{
                    width: "100%",
                    justifyContent: "end",
                  }}
                >
                  <IncredableButton
                    secondary
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </IncredableButton>
                  <IncredableButton
                    disabled={!hasPermission}
                    type="primary"
                    onClick={methods.handleSubmit(onSubmit)}
                  >
                    Save
                  </IncredableButton>
                </Space>
              </Form>
            </FormProvider>
          </>
          :
          <TasksListV2 />
        }
      </Card>
    </IncredableContent>
  );
}
