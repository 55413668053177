import React, { useContext, useState } from "react";
import {
  Avatar,
  Divider,
  Flex,
  Form,
  Layout,
  Space,
  Steps,
  Typography,
  message,
} from "antd";
import {
  FileExclamationOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import { useForm, FormProvider } from "react-hook-form";
import styled from "styled-components";
import IncredableButton from "../../components/button";
import StepOne from "./step-one";
import StepThree from "./step-three";
import StepTwo from "./step-two";
import StepFour from "./step-four";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useAddWorkflowMutation } from "../../redux/api/workflow/workflow";
import {
  WorkflowRequest,
  WorkFlowTaskRequest,
} from "../../redux/api/workflow/types";
import { useNavigate } from "react-router-dom";
import IncredableContent from "../../components/incredable-content";

const { Content } = Layout;

const StyledContent = styled(Content)`
  margin: 48px;
  padding: 32px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 25px 0px #5911920d;
`;

const AddWorkflow: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState<WorkflowRequest>();
  const [addWorkflow] = useAddWorkflowMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const methods = useForm<WorkflowRequest>({
    mode: "onBlur",
    defaultValues: formData,
  });

  const { trigger, getValues } = methods;

  const steps = [
    {
      title: "Step 1",
      content: <StepOne />,
      description: "View Details",
      icon: <FileExclamationOutlined />,
    },
    {
      title: "Step 2 (Optional)",
      content: <StepTwo />,
      description: "Start and Due Dates",
      icon: <CalendarOutlined />,
    },
    {
      title: "Step 3",
      content: (
        <StepThree
          workflowTemplateId={formData?.workflowTemplateId}
          methods={methods}
        />
      ),
      description: "Add Tasks",
      icon: <CarryOutOutlined />,
    },
    {
      title: "Step 4",
      content: <StepFour formData={formData} setCurrent={setCurrent} />,
      description: "Review and Save",
      icon: <FileProtectOutlined />,
    },
  ];

  const next = async () => {
    const valid = await trigger();
    if (!valid) {
      message.error("Please fix the errors before proceeding.");
      return;
    }
    const currentValues = getValues();
    setFormData((prev) => ({ ...prev, ...currentValues }));
    setCurrent((prev) => prev + 1);
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const onSubmit = async () => {
    if (!formData) {
      message.error("Form data is missing");
      return;
    }
    const tasks = formData.tasks as WorkFlowTaskRequest[];
    try {
      spinnerContext?.showSpinner();
      await addWorkflow({
        ...formData,
        tasks,
      });
      spinnerContext?.hidespinner();
      toastContext?.openSuccessNotification({
        message: "workflow added successfully!",
        placement: "topRight",
      });
      navigate(-1);
    } catch (error: any) {
      spinnerContext?.hidespinner();
      message.error(error?.data?.message || "Failed to submit workflow.");
    }
  };

  return (
    <>
      <IncredableContent
        style={{
          paddingBottom: "24px",
          paddingTop: "24px",
          margin: "20px 30px 0px",
        }}
        breadcrumbContainerStyle={{ margin: "20px 30px 0px" }}
        breadcrumbGroupProps={{
          items: [
            {
              text: "Workflow",
              href: "/Workflows",
            },
            {
              text: "Add Workflow",
              href: "#",
            },
          ],
        }}
      >
        <Steps current={current} labelPlacement="vertical">
          {steps.map((item, index) => (
            <Steps.Step
              key={item.title}
              icon={
                <Avatar
                  size={40}
                  style={{
                    border:
                      current === index
                        ? "1px solid #7C4095"
                        : "1px solid #8A92A6",
                    backgroundColor: "transparent",
                  }}
                >
                  <span
                    style={{
                      color: current === index ? "#7C4095" : "#8A92A6",
                    }}
                  >
                    {item.icon}
                  </span>
                </Avatar>
              }
              description={
                <span
                  style={{
                    fontWeight: current === index ? "bold" : "normal",
                    color: current === index ? "#7C4095" : "inherit",
                  }}
                >
                  {item.description}
                </span>
              }
            />
          ))}
        </Steps>
      </IncredableContent>
      <IncredableContent
        style={{
          paddingTop: "none !important",
          marginTop: "none !important",
          margin: "20px 30px 0px",
        }}
      >
        <FormProvider {...methods}>
          <Form layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "18px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "500",
                }}
              >
                {steps[current].description}
              </Typography.Text>
              <Space>
                {current > 0 && (
                  <IncredableButton secondary onClick={prev}>
                    Previous
                  </IncredableButton>
                )}
                {current < steps.length - 1 && (
                  <IncredableButton type="primary" onClick={next}>
                    Next
                  </IncredableButton>
                )}
                {current === steps.length - 1 && (
                  <IncredableButton type="primary" htmlType="submit">
                    Save
                  </IncredableButton>
                )}
              </Space>
            </Flex>
            <Divider />
            {steps[current].content}
          </Form>
        </FormProvider>
      </IncredableContent>
    </>
  );
};

export default AddWorkflow;
