import {
    CollectionPreferences,
    CollectionPreferencesProps,
  } from "@cloudscape-design/components";
  import { last } from "lodash";
  
  export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: "10 items" },
    { value: 30, label: "30 items" },
    { value: 50, label: "50 items" },
  ];
  
  export const ROLES_CONTENT_DISPLAY_OPTIONS = [
    { id: "id", label: "ID", alwaysVisible: false },
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
  ];
  export const ROLES_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
  
  export const TablePreferences = ({
    title,
    disabled,
    preferences,
    setPreferences,
    pageSizeOptions = PAGE_SIZE_OPTIONS,
    contentDisplayOptions,
  }: {
    title?: string;
    disabled?: boolean;
    preferences: CollectionPreferencesProps.Preferences<any> | undefined;
    setPreferences: (
      preferences: CollectionPreferencesProps.Preferences<any>
    ) => void;
    pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
    contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
  }) => (
    <CollectionPreferences
      disabled={disabled || !contentDisplayOptions.length}
      preferences={preferences}
      onConfirm={({ detail }) => {
        setPreferences(detail);
      }}
      title={title}
      pageSizePreference={{ options: pageSizeOptions }}
      wrapLinesPreference={{}}
      stripedRowsPreference={{}}
      contentDensityPreference={{}}
      contentDisplayPreference={{ options: contentDisplayOptions }}
      stickyColumnsPreference={{
        firstColumns: {
          title: "Stick first column(s)",
          description:
            "Keep the first column(s) visible while horizontally scrolling the table content.",
          options: [
            { label: "None", value: 0 },
            { label: "First column", value: 1 },
            { label: "First two columns", value: 2 },
          ],
        },
        lastColumns: {
          title: "Stick last column",
          description:
            "Keep the last column visible while horizontally scrolling the table content.",
          options: [
            { label: "None", value: 0 },
            { label: "Last column", value: 1 },
          ],
        },
      }}
    />
  );
  