export const PrivilegeTypeOption = [
    { value: "Initial FPPE", id: 1 },
    { value: "Increased FPPE" },
    { value: "OPPE" },
    { value: "Triggered FPPE" },
];
export const TriggerOption = [
    { value: "First evaluation step	" },
    { value: "Triggered increase" },
    { value: "Ongoing evaluation" },
    { value: "Event-based review" },
];
export const ResultOption = [
    { value: "NEEDS REVIEW" },
    { value: "MOST GOALS MET" },
    { value: "GOALS MET" },
    { value: "NEEDS REVIEW" },
];