import { Col, Row, Image, Layout, Form, Typography, Spin } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import {
  loginButton,
  forgotPassButton,
  loginContent,
} from "../login/login.styles";
import IncredableButton from "../../components/button";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { ToastContext } from "../../context/toast/incredable-toast";
import axios from "../../context/axios";
import { SpinnerContext } from "../../context/spinner/spinner";

type FormInputType = {
  email: string;
};

export default function RequestReset() {
  const methods = useForm<FormInputType>();
  const toastContext = useContext(ToastContext);
  const spinnerContext = useContext(SpinnerContext);

  const navigate = useNavigate();

  return (
    <Row>
      <Col xs={0} md={12} lg={14}>
        <Image
          height="100vh"
          width="100%"
          preview={false}
          src="/assets/Login_Bg_image.png"
          alt="Login Background"
        />
      </Col>
      <Col xs={24} md={12} lg={10}>
        <Layout
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={loginContent}>
            <FormProvider {...methods}>
              <Form
                layout="vertical"
                name="request-reset"
                onFinish={methods.handleSubmit((data: FormInputType) => {
                  if (!!data.email) {
                    try {
                      spinnerContext?.showSpinner();
                      axios
                        .post(
                          `/api/auth/password/reset`,
                          {
                            email: data.email,
                          },
                          {
                            validateStatus: (status) => status === 200,
                          }
                        )
                        .then(() => {
                          toastContext?.openSuccessNotification({
                            message: "Email sent, please check your mail",
                            placement: "top",
                          });
                          setTimeout(() => {
                            navigate("/dashboard");
                          }, 1500);
                        })
                        .catch((error) => {
                          spinnerContext?.hidespinner();
                          toastContext?.openErrorNotification({
                            message:
                              "An error occurred: Password reset failed. Please contact support.",
                            placement: "top",
                          });
                        });
                    } catch (error) {
                      spinnerContext?.hidespinner();
                      toastContext?.openErrorNotification({
                        message:
                          "An error occurred: Password reset failed. Please contact support.",
                        placement: "top",
                      });
                    }
                  }
                })}
              >
                <Typography.Title
                  level={3}
                  style={{
                    marginBottom: "20px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Reset Your Password?
                </Typography.Title>
                <RHFTextField
                  formItemProps={{ label: "Email" }}
                  controllerProps={{ name: "email" }}
                  inputProps={{ placeholder: "Enter Your Email" }}
                  rules={{ required: "This field is required" }}
                />
                <IncredableButton
                  type="link"
                  style={forgotPassButton}
                  onClick={() => navigate("/auth/login")}
                  aria-label="Back to Login"
                >
                  Back to Login
                </IncredableButton>
                <IncredableButton
                  block
                  type="primary"
                  htmlType="submit"
                  style={loginButton}
                  aria-label="Send Reset Email"
                >
                  Send Email
                </IncredableButton>
              </Form>
            </FormProvider>
          </div>
        </Layout>
      </Col>
    </Row>
  );
}
