import { Card, Typography } from "antd";
import ProviderCard from "../../components/providerCard/provider-card";
import { Images } from "../../common/images";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

export default function ProfessionalInfo() {
  const { providerId } = useParams();
  const navigate = useNavigate();

  const { data: selectedProvider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken
  );

  const professionalInfo = useMemo(
    () => [
      { label: "CAQH", value: selectedProvider?.professionalInfo?.caqh || "-" },
      {
        label: "Title",
        value: selectedProvider?.professionalInfo?.title || "-",
      },
      {
        label: "Work Phone",
        value: selectedProvider?.professionalInfo?.workPhone || "-",
      },
      {
        label: "Work Phone Alt",
        value: selectedProvider?.professionalInfo?.workPhoneAlt || "-",
      },
      {
        label: "Specialty",
        value: selectedProvider?.professionalInfo?.primarySpecialty?.name || "-",
      },
      {
        label: "Identification Number",
        value: selectedProvider?.professionalInfo?.identificationNumber || "-",
      },

      {
        label: "Medicare",
        value: selectedProvider?.professionalInfo?.medicareId || "-",
      },
      {
        label: "Medicaid",
        value: selectedProvider?.professionalInfo?.medicaidId || "-",
      },
      {
        label: "Supervising Physician Name",
        value:
          selectedProvider?.professionalInfo?.supervisingPhysicianName || "-",
      },
      {
        label: "Supervising Physician NPI",
        value:
          selectedProvider?.professionalInfo?.supervisingPhysicianNpi || "-",
      },

      {
        label: "Practice Name",
        value: selectedProvider?.professionalInfo?.practiceInfo?.name || "-",
      },
      {
        label: "Years of Practice",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.yearsOfPractice ||
          "-",
      },
      {
        label: "Start Date",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.startDate || "-",
      },
      {
        label: "End Date",
        value: selectedProvider?.professionalInfo?.practiceInfo?.endDate || "-",
      },
      {
        label: "Address Line 1",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.addressLane1 || "-",
      },
      {
        label: "Address Line 2",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.addressLane2 || "-",
      },
      {
        label: "City",
        value: selectedProvider?.professionalInfo?.practiceInfo?.city || "-",
      },
      {
        label: "State",
        value: selectedProvider?.professionalInfo?.practiceInfo?.state || "-",
      },
      {
        label: "Country",
        value: selectedProvider?.professionalInfo?.practiceInfo?.country || "-",
      },
      {
        label: "Zip Code",
        value: selectedProvider?.professionalInfo?.practiceInfo?.zipcode || "-",
      },
      {
        label: "Practice Contact Name",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.contactName || "-",
      },
      {
        label: "Practice Contact Title",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.contactTitle || "-",
      },
      {
        label: "Practice Contact Phone",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.contactPhone || "-",
      },
      {
        label: "Practice Contact Email",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.contactEmail || "-",
      },
      {
        label: "Practice Contact Fax",
        value:
          selectedProvider?.professionalInfo?.practiceInfo?.contactFax || "-",
      },
    ],
    [selectedProvider]
  );
  return (
    <>
      <ProviderCard
        title={
          <Typography.Title
            level={3}
            style={{ marginTop: "0", fontSize: "19px" }}
          >
            Professional Info
          </Typography.Title>
        }
        extraButton={{
          text: "Edit",
          icon: Images.edit2,
          onClick: () => {
            if (!!providerId)
              navigate(`/manageprovider/${providerId}/professionalinfo/edit`);
          },
        }}
        data={professionalInfo}
      />
    </>
  );
}
