import { Col, ConfigProvider, Divider, Form, Row, Tabs } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useAddFacilityMutation,
  useGetAllFacilityTypesQuery,
} from "../../redux/api/facility/facility";
import { useNavigate } from "react-router-dom";
import { FacilityRequest } from "../../redux/api/facility/types";
import { useContext } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import IncredableContent from "../../components/incredable-content";
import { Countries, USStatesList } from "../../common/constants";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import StickyFooter from "../../components/StickyFooter";

type FormInputType = FacilityRequest;

export default function AddFacility() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const { data: facilityType } = useGetAllFacilityTypesQuery();
  const { data: facilityGroup } = useGetAllFacilityGroupQuery();
  const [addFacility] = useAddFacilityMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <IncredableContent
      style={{
        position: "relative",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Facility",
            href: "/managefacilities",
          },
          {
            text: "Add Facility",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "rgb(29, 26, 31)",
              verticalItemPadding: "0",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "none ",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey="addFacility"
          size="large"
          items={[
            {
              label: (
                <span style={{ fontWeight: "600", padding: "0" }}>
                  Add Facility
                </span>
              ),
              key: "addFacility",
              children: (
                <FormProvider {...methods}>
                  <Form
                    name="addfacility"
                    layout="vertical"
                    onFinish={methods.handleSubmit(
                      async (data: FormInputType) => {
                        await addFacility({
                          name: data?.name,
                          npiNumber: data?.npiNumber,
                          description: data?.description,
                          facilityTypeId: data?.facilityTypeId,
                          facilityGroupId: data?.facilityGroupId,
                          taxId: data?.taxId,
                          cliaId: data?.cliaId,
                          phoneNumber: data?.phoneNumber,
                          email: data?.email,
                          notes: data?.notes,
                          immunizationId: data?.immunizationId,
                          taxonomyCode: data?.taxonomyCode,
                          pocName: data?.pocName,
                          pocEmail: data?.pocEmail,
                          pocFax: data?.pocFax,
                          medicaidId: data?.medicaidId,
                          facilityAddress: {
                            addressLane1: data?.facilityAddress?.addressLane1,
                            addressLane2: data?.facilityAddress?.addressLane2,
                            city: data?.facilityAddress?.city,
                            state: data?.facilityAddress?.state,
                            zipcode: data?.facilityAddress?.zipcode,
                            country: data?.facilityAddress?.country,
                          },
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                          message: `Facility saved successfully`,
                          placement: "topRight",
                        });
                        navigate(-1);
                      }
                    )}
                    style={{
                      height: "calc(100vh - 250px)",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      paddingBottom: "50px",
                    }}
                    autoComplete="off"
                  >
                    <Form.List name="facilities" initialValue={[{}]}>
                      {(fields, { add }) => {
                        return (
                          <>
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <div key={key}>
                                  {index > 0 && <Divider />}
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Name",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `name`,
                                        }}
                                        rules={{
                                          required: "This field is required",
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "NPI Number",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `npiNumber`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFSelect
                                        formItemProps={{
                                          label: "Facility Type",
                                        }}
                                        controllerProps={{
                                          name: `facilityTypeId`,
                                        }}
                                        selectProps={{
                                          allowClear: true,
                                          showSearch: true,
                                          style: { width: "100%" },
                                          placeholder: "Search to Select",
                                          options: facilityType?.map((type) => {
                                            return {
                                              label: type?.name,
                                              value: type?.id,
                                            };
                                          }),
                                          onModifyClick: () =>
                                            navigate("/facilitytype"),
                                        }}
                                        rules={{
                                          required: "This field is required",
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFSelect
                                        formItemProps={{
                                          label: "Facility Group",
                                        }}
                                        controllerProps={{
                                          name: `facilityGroupId`,
                                        }}
                                        selectProps={{
                                          allowClear: true,
                                          showSearch: true,
                                          style: { width: "100%" },
                                          placeholder: "Search to Select",
                                          options: facilityGroup?.map(
                                            (type) => {
                                              return {
                                                label: type?.name,
                                                value: type?.id,
                                              };
                                            }
                                          ),
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Tax ID",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `taxId`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "CLIA ID",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `cliaId`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Immunization ID",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `immunizationId`,
                                        }}
                                      />
                                    </Col>

                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Taxonomy Code",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `taxonomyCode`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFPhoneNumber
                                        formItemProps={{
                                          label: "Phone Number",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `phoneNumber`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Mailing Address",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `email`,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Divider orientation="left">
                                    Office Point of Contact Info
                                  </Divider>
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Name",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `pocName`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Email",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `pocEmail`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Fax",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `pocFax`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Medicaid",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `medicaidId`,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Divider orientation="left">
                                    Facility Address
                                  </Divider>
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Address Line 1",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `facilityAddress.addressLane1`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Address Line 2",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `facilityAddress.addressLane2`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "City",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `facilityAddress.city`,
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFSelect
                                        formItemProps={{ label: "State" }}
                                        controllerProps={{
                                          name: `facilityAddress.state`,
                                        }}
                                        selectProps={{
                                          allowClear: true,
                                          showSearch: true,
                                          style: { width: "100%" },
                                          placeholder: "Select state",
                                          filterOption: (input, option) =>
                                            (option?.value ?? "")
                                              .toString()
                                              .toLowerCase()
                                              .includes(input?.toLowerCase()),
                                          options: USStatesList?.map(
                                            (state) => {
                                              return {
                                                value: state?.value,
                                              };
                                            }
                                          ),
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFSelect
                                        formItemProps={{ label: "Country" }}
                                        controllerProps={{
                                          name: `facilityAddress.country`,
                                        }}
                                        selectProps={{
                                          allowClear: true,
                                          showSearch: true,
                                          style: { width: "100%" },
                                          placeholder: "Select country",
                                          filterOption: (input, option) =>
                                            (option?.value ?? "")
                                              .toString()
                                              .toLowerCase()
                                              .includes(input?.toLowerCase()),
                                          options: Countries?.map((country) => {
                                            return {
                                              value: country?.value,
                                            };
                                          }),
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <RHFTextField
                                        formItemProps={{
                                          label: "Zip",
                                        }}
                                        inputProps={{}}
                                        controllerProps={{
                                          name: `facilityAddress.zipcode`,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Divider></Divider>
                                  <Col span={24}>
                                    <RHFTextArea
                                      formItemProps={{
                                        label: "Notes",
                                      }}
                                      controllerProps={{
                                        name: `notes`,
                                      }}
                                    />
                                  </Col>
                                </div>
                              )
                            )}
                          </>
                        );
                      }}
                    </Form.List>
                    <StickyFooter
                      hasPermission={true}
                      onSubmit={() => ""}
                      onCancel={() => {
                        navigate(-1);
                      }}
                    />
                  </Form>
                </FormProvider>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </IncredableContent>
  );
}
