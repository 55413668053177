import {
  Col,
  Divider,
  Form,
  Row,
  theme,
  Card,
  Tabs as AntTabs,
  ConfigProvider,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderWorkHistoryRequestType } from "../../redux/api/provider/types";
import {
  useAddProviderWorkHistoryMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import {
  alertReminderOptions,
  Countries,
  USStatesList,
} from "../../common/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import { DocumentRequest } from "../../redux/api/document/types";
import {
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import StickyFooter from "../../components/StickyFooter";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/browser";
import ProviderDocumentDetails from "../provider-document-details";

const { useToken } = theme;

export default function EditProviderWorkHistory() {
  const { providerId, workhistoryId } = useParams();
  const methods = useForm<ProviderWorkHistoryRequestType>();
  const documentMethods = useForm<DocumentRequest & { documentId: string }>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (provider && provider.providerWorkHistories) {
      const workhistory = provider?.providerWorkHistories?.find(
        (workhistory) => workhistory?.id + "" === workhistoryId + ""
      );
      if (workhistory) {
        methods.setValue("id", workhistory.id);
        methods.setValue("type", workhistory.type);
        methods.setValue("name", workhistory.name);
        methods.setValue("startDate", workhistory.startDate);
        methods.setValue("endDate", workhistory.endDate);
        methods.setValue("positionHeld", workhistory.positionHeld);
        methods.setValue("gapExplanation", workhistory.gapExplanation);
        methods.setValue("referenceName", workhistory.referenceName);
        methods.setValue("referenceEmail", workhistory.referenceEmail);
        methods.setValue("referencePhone", workhistory.referencePhone);
        methods.setValue("referenceFax", workhistory.referenceFax);
        methods.setValue("addressLane1", workhistory.addressLane1);
        methods.setValue("addressLane2", workhistory.addressLane2);
        methods.setValue("city", workhistory.city);
        methods.setValue("state", workhistory.state);
        methods.setValue("zip", workhistory.zip);
        methods.setValue("country", workhistory.country);
        if (!!workhistory?.document) {
          documentMethods.setValue("documentId", workhistory?.document?.id);
          documentMethods.setValue("name", workhistory?.document?.name);
          documentMethods.setValue(
            "categoryId",
            workhistory?.document?.category?.id
          );
          documentMethods.setValue("notes", workhistory?.document?.notes);
          documentMethods.setValue(
            "expirationDate",
            workhistory?.document?.expirationDate
          );
          documentMethods.setValue(
            "alertDays",
            workhistory?.document?.alertDays
          );
        }
      }
    }
  }, [provider, workhistoryId]);

  const [updateProviderWorkHistory] = useAddProviderWorkHistoryMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [saveDocument] = useSaveDocumentMutation();

  return (
    <IncredableContent
      spinning={isLoading || isFetching}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: `${provider?.firstName || ""} ${provider?.lastName || ""}`,
            href: `/manageprovider/${providerId}`,
          },
          {
            text: workhistoryId ? "Edit Work History" : "Add Work History",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="workhistory"
            size="large"
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    {workhistoryId ? "Edit Work History" : "Add Work History"}
                  </span>
                ),
                key: "workhistory",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                      name="editproviderworkhistory"
                      layout="vertical"
                      onFinish={methods.handleSubmit(
                        async (data: ProviderWorkHistoryRequestType) => {
                          if (!!providerId) {
                            spinnerContext?.showSpinner();
                            await updateProviderWorkHistory({
                              ...data,
                              providerId: providerId,
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `Work History saved successfully`,
                              placement: "topRight",
                            });
                          }
                          navigate(-1);
                        }
                      )}
                      autoComplete="off"
                    >
                      <Card>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Type" }}
                              controllerProps={{
                                name: `type`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select type",
                                options: ["Current", "Previous", "Gap"].map(
                                  (type) => ({
                                    value: type,
                                  })
                                ),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "name",
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Start Date",
                              }}
                              controllerProps={{
                                name: `startDate`,
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "End Date",
                              }}
                              controllerProps={{
                                name: `endDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Position Held",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "positionHeld",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Gap Explanation",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "gapExplanation",
                              }}
                            />
                          </Col>
                        </Row>
                        <Divider />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Reference Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "referenceName",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Reference Email",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "referenceEmail",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFPhoneNumber
                              formItemProps={{
                                label: "Reference Phone",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "referencePhone",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Reference Fax",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "referenceFax",
                              }}
                            />
                          </Col>
                        </Row>
                        <Divider />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Address Line 1",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "addressLane1",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Address Line 2",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "addressLane2",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "City",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "city",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "State" }}
                              controllerProps={{
                                name: `state`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select state",
                                filterOption: (input, option) =>
                                  (option?.value ?? '').toString().toLowerCase().includes(input?.toLowerCase())
                                ,
                                options: USStatesList?.map((state) => {
                                  return {
                                    label: state?.value,
                                    value: state?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Country" }}
                              controllerProps={{
                                name: `country`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select country",
                                filterOption: (input, option) =>
                                  (option?.value ?? '').toString().toLowerCase().includes(input?.toLowerCase())
                                ,
                                options: Countries?.map((country) => {
                                  return {
                                    label: country?.value,
                                    value: country?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Zip",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "zip",
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Document</span>,
                key: "document",
                children: (
                  <FormProvider {...documentMethods}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Form: {
                            verticalLabelMargin: -4,
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "30px",
                        }}
                        onFinish={documentMethods.handleSubmit(
                          async (data: DocumentRequest) => {
                            console.log("document methods");
                          }
                        )}
                        layout="vertical"
                        size="middle"
                        autoComplete="off"
                      >
                        <ProviderDocumentDetails
                          uploadedFile={uploadedFile}
                          setUploadedFile={setUploadedFile}
                        />
                      </Form>
                    </ConfigProvider>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Alert</span>,
                key: "alert",
                children: (
                  <FormProvider {...documentMethods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "30px",
                      }}
                      layout="vertical"
                      size="middle"
                      autoComplete="off"
                    >
                      <Card>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={12}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Expiration Date",
                                tooltip:
                                  "Re-Enrollment Date is set as the default expiration date. You may modify it below if needed.",
                              }}
                              controllerProps={{
                                name: "expirationDate",
                              }}
                            />
                          </Col>
                          <Col span={12}>
                            <RHFSelect
                              formItemProps={{
                                label: "Expiration Alert Reminder",
                              }}
                              controllerProps={{ name: "alertDays" }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select Expiration Reminder",
                                options: alertReminderOptions?.map((alert) => {
                                  return {
                                    label: alert?.label,
                                    value: alert?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Form>
                  </FormProvider>
                ),
              },
            ]}
          />
          <StickyFooter
            hasPermission={true}
            onSubmit={async () => {
              const hasDocumentValues =
                documentMethods.getValues("alertDays") ||
                documentMethods.getValues("expirationDate") ||
                documentMethods.getValues("name") ||
                documentMethods.getValues("file");

              const handleProviderWorkHistorySubmit = async (
                documentId?: string
              ): Promise<void> => {
                methods.handleSubmit(
                  async (data: ProviderWorkHistoryRequestType) => {
                    if (!!providerId) {
                      spinnerContext?.showSpinner();
                      await updateProviderWorkHistory({
                        ...data,
                        providerId: providerId,
                        ...(documentId && { documentId }),
                      });
                      spinnerContext?.hidespinner();
                      toastContext?.openSuccessNotification({
                        message: "Work History saved successfully",
                        placement: "topRight",
                      });
                      navigate(-1);
                    }
                  }
                )();
              };

              const handleFileUpload = async (
                key: string,
                file?: File
              ): Promise<void> => {
                if (!!file && key) {
                  try {
                    await fileAxios.put(getFileUrl(key), file);
                    toastContext?.openSuccessNotification({
                      message: `Document saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                }
              };

              if (!hasDocumentValues) {
                handleProviderWorkHistorySubmit();
              } else {
                const documentId = documentMethods.getValues("documentId");

                const handleDocumentUpdate = async (
                  data: DocumentRequest & { documentId: string }
                ): Promise<void> => {
                  try {
                    const result = await updateDocument({
                      documentId: data.documentId,
                      isVersioned: false,
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderWorkHistorySubmit(result?.id);
                    await handleFileUpload(
                      result?.attachment?.key,
                      uploadedFile
                    );
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: `Document updated successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    spinnerContext?.hidespinner();
                    toastContext?.openErrorNotification({
                      message: `Document update failed`,
                      placement: "topRight",
                    });
                  }
                };

                const handleDocumentSave = async (
                  data: DocumentRequest
                ): Promise<void> => {
                  try {
                    const documentResponse = await saveDocument({
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderWorkHistorySubmit(documentResponse?.id);
                    await handleFileUpload(
                      documentResponse?.attachment?.key,
                      uploadedFile
                    );
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                };

                documentMethods.handleSubmit(
                  documentId ? handleDocumentUpdate : handleDocumentSave
                )();
              }
            }}
            onCancel={() => {
              navigate(-1);
            }}
          />
        </div>
      </ConfigProvider>
    </IncredableContent>
  );
}
