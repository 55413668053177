import queryString from "query-string";
import { api } from "..";
import {
  Document,
  DocumentHistory,
  DocumentRequest,
  DocumentVersion,
  PaginationDocument,
  Psv,
  PsvRequest,
} from "./types";

const documentAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getProviderDocument: build.query<
      Document,
      { providerId: string; documentId: string }
    >({
      query: ({ providerId, documentId }) =>
        `provider/${providerId}/document/${documentId}`,
      providesTags: (result, error, { documentId }) => [
        { type: "Document", id: documentId },
      ],
    }),
    getAllProviderDocumentVersions: build.query<
      DocumentVersion[],
      { providerId: string; documentId: string }
    >({
      query: ({ providerId, documentId }) =>
        `provider/${providerId}/document/${documentId}/versions`,
      providesTags: ["Document"],
    }),
    getProviderDocumentVersion: build.query<
      DocumentVersion,
      { providerId: string; documentId: string; versionId: string }
    >({
      query: ({ providerId, documentId, versionId }) =>
        `provider/${providerId}/document/${documentId}/versions/${versionId}`,
      providesTags: ["Document"],
    }),
    getAllProviderDocumentActivities: build.query<
      DocumentHistory,
      { providerId: string; documentId: string }
    >({
      query: ({ providerId, documentId }) =>
        `provider/${providerId}/document/${documentId}/activities`,
      providesTags: ["Document"],
    }),
    saveProviderDocument: build.mutation<
      Document,
      { providerId: string; document: DocumentRequest }
    >({
      query({ providerId, document }) {
        console.log({ document });
        return {
          url: `provider/${providerId}/document`,
          method: "POST",
          body: {
            ...document,
            attachment:
              !!document.file && document.file?.size > 0
                ? {
                    name: document.file?.name,
                    size: document.file?.size,
                    contentType: document.file?.type,
                  }
                : null,
          },
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    updateProviderDocument: build.mutation<
      Document,
      {
        providerId: string;
        documentId: string;
        document: DocumentRequest;
        isVersioned: boolean;
      }
    >({
      query({ providerId, documentId, document, isVersioned = false }) {
        return {
          url: `provider/${providerId}/document/${documentId}?isVersioned=${isVersioned}`,
          method: "PUT",
          body: {
            ...document,
            attachment:
              !!document.file && document.file?.size > 0
                ? {
                    name: document.file?.name,
                    size: document.file?.size,
                    contentType: document.file?.type,
                  }
                : null,
          },
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    shareProviderDocument: build.mutation<
      void,
      { attachmentKeys: string[]; email: string }
    >({
      query({ attachmentKeys, email }) {
        return {
          url: `share`,
          method: "POST",
          body: {
            documents: attachmentKeys,
            emailAddress: email,
          },
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    savePsv: build.mutation<Psv, PsvRequest>({
      query({
        documentId,
        sourceType,
        primarySource,
        status,
        expiration,
        alertDays,
        file,
      }) {
        const formData = new FormData();
        if (sourceType) formData.append("sourceType", sourceType);
        if (primarySource) formData.append("primarySource", primarySource);
        if (status) formData.append("status", status);
        if (expiration) formData.append("expiration", expiration);
        if (alertDays) formData.append("alertDays", String(alertDays));
        if (file) formData.append("file", file);
        return {
          url: `document/${documentId}/psv/upload`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    updatePSV: build.mutation<Psv, PsvRequest>({
      query: ({
        documentId,
        id,
        sourceType,
        primarySource,
        status,
        expiration,
        alertDays,
        file,
      }) => {
        const formData = new FormData();
        if (sourceType) formData.append("sourceType", sourceType);
        if (primarySource) formData.append("primarySource", primarySource);
        if (status) formData.append("status", status);
        if (expiration) formData.append("expiration", expiration);
        if (alertDays) formData.append("alertDays", String(alertDays));
        if (file) formData.append("file", file);
        return {
          url: `document/${documentId}/psv/${id}/upload`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Document", id: arg.id },
        { type: "Document" },
      ],
    }),
    deletePsv: build.mutation<void, { documentId: string; psvId: string }>({
      query({ documentId, psvId }) {
        return {
          url: `document/${documentId}/psv/${psvId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
  }),
});

export const {
  useGetAllProviderDocumentActivitiesQuery,
  useGetAllProviderDocumentVersionsQuery,
  useGetProviderDocumentVersionQuery,
  useGetProviderDocumentQuery,
  useSaveProviderDocumentMutation,
  useShareProviderDocumentMutation,
  useUpdateProviderDocumentMutation,
  useSavePsvMutation,
  useUpdatePSVMutation,
  useDeletePsvMutation,
} = documentAPI;
