import { Col, Divider, Flex, Form, Row, Space, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
    useAddFacilityMutation,
    useGetAllFacilityTypesQuery,
} from "../../redux/api/facility/facility";
import IncredableButton from "../../components/button";
import { useNavigate } from "react-router-dom";
import { FacilityRequest } from "../../redux/api/facility/types";
import { useContext } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import IncredableContent from "../../components/incredable-content";
import { Countries, USStatesList } from "../../common/constants";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import { TraineeRequest } from "../../redux/api/gme/types";
import { useAddTraineeMutation } from "../../redux/api/gme/gme";
import { evaluationStatus, programs, traineeTypes, trainingYear } from "../constants";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { useGetAllSpecialtyQuery } from "../../redux/api/specialty/specialty";

type FormInputType = TraineeRequest;

export default function AddTrainee() {
    const navigate = useNavigate();
    const methods = useForm<FormInputType>();
    const { data: specialties } = useGetAllSpecialtyQuery();
    const [addTrainee] = useAddTraineeMutation();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);

    return (
        <IncredableContent
            breadcrumbGroupProps={{
                items: [
                    {
                        text: "Trainee",
                        href: "/gme",
                    },
                    {
                        text: "Add Trainee",
                        href: "#",
                    },
                ],
            }}
        >
            <FormProvider {...methods}>
                <Form
                    name="addTrainee"
                    layout="vertical"
                    onFinish={methods.handleSubmit(async (data: FormInputType) => {
                        await addTrainee({
                            traineeFirstName: data?.traineeFirstName,
                            traineeLastName: data?.traineeLastName,
                            notes: data?.notes,
                            type: data?.type,
                            program: data?.program,
                            trainingYear: data?.trainingYear,
                            evaluationDate: data?.evaluationDate,
                            currentRotationId: data?.currentRotationId,
                            evaluation: data?.evaluation
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                            message: `Trainee saved successfully`,
                            placement: "topRight",
                        });
                        navigate(-1);
                    })}
                    autoComplete="off"
                >
                    <Flex gap="small" wrap justify="space-between">
                        <Typography.Text
                            strong
                            style={{
                                fontSize: "22px",
                                color: "#1D1A1F",
                                lineHeight: "25px",
                                fontWeight: "700",
                            }}
                        >
                            Trainee
                        </Typography.Text>
                        <Space>
                            <IncredableButton
                                secondary
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </IncredableButton>
                            <IncredableButton type="primary" htmlType="submit">
                                Save
                            </IncredableButton>
                        </Space>
                    </Flex>
                    <Divider />
                    <Form.List name="trainee" initialValue={[{}]}>
                        {(fields, { add }) => {
                            return (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <div key={key}>
                                            {index > 0 && <Divider />}
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col span={8}>
                                                    <RHFTextField
                                                        formItemProps={{
                                                            label: "First Name",
                                                        }}
                                                        inputProps={{}}
                                                        controllerProps={{
                                                            name: `traineeFirstName`,
                                                        }}
                                                        rules={{ required: "This field is required" }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFTextField
                                                        formItemProps={{
                                                            label: "Last Name",
                                                        }}
                                                        inputProps={{}}
                                                        controllerProps={{
                                                            name: `traineeLastName`,
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFSelect
                                                        formItemProps={{ label: "Trainee Type" }}
                                                        controllerProps={{
                                                            name: `type`,
                                                        }}
                                                        selectProps={{
                                                            allowClear: true,
                                                            showSearch: true,
                                                            style: { width: "100%" },
                                                            placeholder: "Search to Select",
                                                            options: traineeTypes?.map((type) => {
                                                                return {
                                                                    label: type?.value,
                                                                    value: type?.value,
                                                                };
                                                            }),
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFSelect
                                                        formItemProps={{ label: "Program" }}
                                                        controllerProps={{
                                                            name: `program`,
                                                        }}
                                                        selectProps={{
                                                            allowClear: true,
                                                            showSearch: true,
                                                            style: { width: "100%" },
                                                            placeholder: "Search to Select",
                                                            options: programs?.map((program) => {
                                                                return {
                                                                    label: program?.value,
                                                                    value: program?.value,
                                                                };
                                                            }),
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFSelect
                                                        formItemProps={{ label: "Current Rotation" }}
                                                        controllerProps={{
                                                            name: `currentRotationId`,
                                                        }}
                                                        selectProps={{
                                                            allowClear: true,
                                                            showSearch: true,
                                                            style: { width: "100%" },
                                                            placeholder: "Search to Select",
                                                            options: specialties?.map((name) => {
                                                                return {
                                                                    label: name?.name,
                                                                    value: name?.id,
                                                                };
                                                            }),
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFSelect
                                                        formItemProps={{ label: "Training Year" }}
                                                        controllerProps={{
                                                            name: `trainingYear`,
                                                        }}
                                                        selectProps={{
                                                            allowClear: true,
                                                            showSearch: true,
                                                            style: { width: "100%" },
                                                            placeholder: "Search to Select",
                                                            options: trainingYear?.map((year) => {
                                                                return {
                                                                    label: year?.value,
                                                                    value: year?.value,
                                                                };
                                                            }),
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFSelect
                                                        formItemProps={{ label: "Evaluation Status" }}
                                                        controllerProps={{
                                                            name: `evaluation`,
                                                        }}
                                                        selectProps={{
                                                            allowClear: true,
                                                            showSearch: true,
                                                            style: { width: "100%" },
                                                            placeholder: "Search to Select",
                                                            options: evaluationStatus?.map((status) => {
                                                                return {
                                                                    label: status?.value,
                                                                    value: status?.value,
                                                                };
                                                            }),

                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <RHFDatePicker
                                                        formItemProps={{
                                                            label: "Evaluation Date",
                                                        }}
                                                        controllerProps={{
                                                            name: `evaluationDate`,
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Divider></Divider>
                                            <Col span={24}>
                                                <RHFTextArea
                                                    formItemProps={{
                                                        label: "Notes",
                                                    }}
                                                    controllerProps={{
                                                        name: `notes`,
                                                    }}
                                                />
                                            </Col>
                                        </div>
                                    ))}
                                </>
                            );
                        }}
                    </Form.List>
                </Form>
            </FormProvider>
        </IncredableContent>
    );
}
