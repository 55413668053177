import React from "react";
import { Space } from "antd";
import IncredableButton from "./button";

const footerStyles: React.CSSProperties = {
  position: "absolute",
  bottom: 0,
  zIndex: 99,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  minHeight: "64px",
  backgroundColor: "hsla(0, 0%, 100%, .90)",
  borderBlockStart: "1px solid rgba(5, 5, 5, 0.20)",
};

interface StickyFooterProps {
  onCancel: () => void;
  onSubmit?: () => void;
  cancelText?: string;
  saveText?: string;
  className?: string;
  hasPermission?: boolean;
}

const StickyFooter: React.FC<StickyFooterProps> = ({
  onCancel,
  onSubmit,
  cancelText = "Cancel",
  saveText = "Save",
  className = "",
  hasPermission,
}) => {
  return (
    <div style={footerStyles} className={`${className}`}>
      <Space size="middle">
        <IncredableButton secondary onClick={onCancel}>
          {cancelText}
        </IncredableButton>
        <IncredableButton
          disabled={!hasPermission}
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
        >
          {saveText}
        </IncredableButton>
      </Space>
    </div>
  );
};

export default StickyFooter;
