import { useCollection } from "@cloudscape-design/collection-hooks";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { Button, ConfigProvider } from "antd";
import { Facility } from "../../../redux/api/facility/types";
import useFacilityFilter from "../../../table-filters/facility-filters";
import AssociatedFacilityModals from "./associated-location-modal";
import { useParams } from "react-router-dom";
import { useRemoveAssociatedLocationsfromLocationsMutation } from "../../../redux/api/location/location";

export default function AssoicatedLocationList({
  associatedFacility,
  setAssociatedFacility,
}: {
  associatedFacility: Facility[];
  setAssociatedFacility: Dispatch<SetStateAction<Facility[]>>;
}) {
  const [selectedFacility, setSelectedFacility] = useState<Facility[]>([]);
  const [deleteAssociatedLocation] = useRemoveAssociatedLocationsfromLocationsMutation();
  const [isAssociatedFacilityModalOpen, setAssociatedFacilityModalOpen] =
    useState<boolean>(false);
  const { locationId } = useParams();

  const { TableFilters, filteredFacilities, UseQueryHookResult } =
    useFacilityFilter(associatedFacility);
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      pagination: {},
    }
  );

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredFacilities?.length})`}
          actions={
            <SpaceBetween size="l" direction="horizontal">
              <Button disabled={!selectedFacility?.at(0)?.id}
                onClick={async () => {
                  try {
                    if (!locationId) return;
                    const location =
                      selectedFacility?.map(
                        (selectedFacility) => selectedFacility?.id + "",
                      ) || [];

                    await deleteAssociatedLocation({
                      locationId: locationId,
                      id: location,
                    });

                    const temp = associatedFacility.filter(
                      (facility) => !location.includes(facility?.id + ""),
                    );
                    setAssociatedFacility(temp);
                    setSelectedFacility([]);
                  } catch (error) {
                    console.error("Error deleting location:", error);
                  }
                }}
              >Remove</Button>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#2B6BE6",
                  },
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setAssociatedFacilityModalOpen(true);
                  }}
                >
                  Associate Locations
                </Button>
              </ConfigProvider>
            </SpaceBetween>
          }
        >
          Associated Locations
        </Header>
      ),
      items,
      ...collectionProps,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: TableFilters,
      variant: "container",
      loading: UseQueryHookResult.isLoading || UseQueryHookResult.isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: true,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedFacility(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedFacility;
        if (!detail.item) return;
        if (temp?.includes(detail.item))
          setSelectedFacility(temp.filter((item) => item !== detail.item));
        else setSelectedFacility([detail.item, ...temp]);
      },
      selectedItems: selectedFacility,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "facilityType", visible: true },
        { id: "state", visible: true },
      ],
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "facilityType",
          header: <div>Facility Type</div>,
          cell: (item) => <div>{item?.facilityType?.name ?? "-"}</div>,
        },
        {
          id: "state",
          header: <div>State</div>,
          cell: (item) => <div>{item?.facilityAddress?.state ?? "-"}</div>,
        },
      ],
    };
  }, [items, selectedFacility]);

  return (
    <div>
      <Table {...tableProps} />
      <AssociatedFacilityModals
        associatedFacility={associatedFacility}
        isAssociatedFaciityModalOpen={isAssociatedFacilityModalOpen}
        setAssociatedFaclity={setAssociatedFacility}
        setAssociatedFaclityModalOpen={setAssociatedFacilityModalOpen}
      />
    </div>
  );
}
