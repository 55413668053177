import { useContext, useEffect, useState } from "react";
import { Button, ConfigProvider, Dropdown, Flex, Layout, Space, theme, Typography } from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { WorkFlowTemplate } from "../../redux/api/workflow/types";
import { useDeleteWorkflowTemplateMutation } from "../../redux/api/workflow/workflow";
import useWorkFlowTemplateFilter from "../../table-filters/workflowtemplate-filter";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useLocalStorage } from "../../common/localStorage";
import { TablePreferences, WORKFLOW_TEMPLATE_CONTENT_DISPLAY_OPTIONS, WORKFLOW_TEMPLATE_TABLE_DEFAULT_PREFERENCES } from "./table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";

export default function WorkflowTemplateListV2() {
    const [selectedRows, setSelectedRows] = useState<WorkFlowTemplate[]>([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const hasPermission = useSelector(
        (state: ApplicationState) =>
            state.ui.is_owner ||
            (state.ui.permissions?.includes("workflow.edit") ?? false)
    );
    const hasDeletePermission = useSelector(
        (state: ApplicationState) =>
            state.ui.is_owner ||
            (state.ui.permissions?.includes("workflow.delete") ?? false)
    );
    const { TableFilters, filteredWorkFlowTemplate, UseQueryHookResult: {
        refetch,
        setPageNumber,
        setPageSize,
        pageNumber,
        pageSize,
        isFetching,
        isLoading,
        sortingDetails,
        setSortingDetails,
    } } = useWorkFlowTemplateFilter();

    const navigate = useNavigate();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const [deleteWorkFlowTemplate] = useDeleteWorkflowTemplateMutation();

    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-WorkflowTemplate-Table-Preferences",
        WORKFLOW_TEMPLATE_TABLE_DEFAULT_PREFERENCES
    );

    useEffect(() => {
        if (tablePreferences?.pageSize) setPageSize(tablePreferences.pageSize);
    }, [tablePreferences, setPageSize]);

    const { items, collectionProps, paginationProps } = useCollection(
        filteredWorkFlowTemplate,
        {
            pagination: { pageSize },
        }
    );

    return (
        <Layout style={{ margin: "20px 30px 0 30px" }}>
            <Space size="large" direction="vertical">
                <Table
                    loading={isFetching || isLoading}
                    onSelectionChange={({ detail }) => {
                        setSelectedRows([...detail.selectedItems]);
                    }}
                    onRowClick={(onRowClickDetails) => {
                        const selectedItem = onRowClickDetails?.detail?.item;
                        if (selectedItem) {
                            if (selectedRows?.at(0)?.id === selectedItem?.id) {
                                setSelectedRows([]);
                            } else {
                                setSelectedRows([selectedItem]);
                            }
                        }
                    }}
                    selectedItems={selectedRows || []}
                    sortingColumn={{ sortingField: sortingDetails?.sortingField }}
                    sortingDescending={sortingDetails?.isDescending}
                    onSortingChange={({ detail }) => {
                        setSortingDetails({
                            sortingField: detail?.sortingColumn?.sortingField,
                            isDescending: detail?.isDescending,
                        });
                    }}
                    columnDefinitions={[
                        {
                            id: "id",
                            header: <div>Template ID</div>,
                            cell: (item) => <div>{item?.id}</div>,
                        },
                        {
                            id: "name",
                            header: <div>Name</div>,
                            cell: (item) => (
                                <NavLink
                                    to={hasPermission ? `/workflowtemplate/${item.id}/edit` : `#`}>
                                    {item?.name}
                                </NavLink>
                            ),
                        },
                    ]}
                    items={items}
                    pagination={
                        <Pagination
                            {...paginationProps}
                        />
                    }
                    preferences={
                        <TablePreferences
                            preferences={tablePreferences}
                            setPreferences={(preferences) => {
                                if (preferences.pageSize !== tablePreferences.pageSize) {
                                    preferences.pageSize && setPageSize(preferences.pageSize);
                                }
                                setTablePreferences(preferences);
                            }}
                            contentDisplayOptions={WORKFLOW_TEMPLATE_CONTENT_DISPLAY_OPTIONS}
                        />
                    }
                    loadingText="Loading resources"
                    selectionType="single"
                    trackBy="id"
                    filter={TableFilters}
                    header={
                        <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                            <Header
                                actions={
                                    <Space size="middle">
                                        <Button
                                            onClick={() => {
                                                !!refetch && refetch();
                                            }}
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                        <Dropdown
                                            menu={{
                                                disabled: selectedRows?.length !== 1,
                                                items: [
                                                    {
                                                        label: hasPermission ? "Edit" : "View",
                                                        key: "edit",
                                                        icon: <EditOutlined />,
                                                        onClick: () => { navigate(`${selectedRows?.at(0)?.id}/edit`); },
                                                    },
                                                    {
                                                        label: "Delete",
                                                        key: "delete",
                                                        onClick: () => setDeleteModalOpen(true),
                                                        disabled: !hasDeletePermission,
                                                        icon: <DeleteOutlined />,
                                                        danger: true,
                                                    },
                                                ],
                                            }}
                                        >
                                            <Button>
                                                Actions
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                        <ConfigProvider
                                            theme={{
                                                token: {
                                                    colorPrimary: "#2B6BE6",
                                                },
                                            }}
                                        >
                                            <Button
                                                disabled={!hasPermission}
                                                onClick={() => navigate(`add`)}
                                                type="primary"
                                                icon={<PlusOutlined />}
                                            >
                                                Add Workflow Template
                                            </Button>
                                        </ConfigProvider>
                                    </Space>
                                }
                            >
                                <Typography.Title level={3} style={{ marginTop: "0" }}>
                                    Workflow Template{" "}
                                    <Typography.Text
                                        type="secondary"
                                        style={{ fontSize: "16px" }}
                                    >{`(${filteredWorkFlowTemplate?.length})`}</Typography.Text>
                                </Typography.Title>
                            </Header>
                        </div>
                    }
                    {...collectionProps}
                    columnDisplay={tablePreferences?.contentDisplay}
                    wrapLines={tablePreferences?.wrapLines}
                    stripedRows={tablePreferences?.stripedRows}
                    contentDensity={tablePreferences?.contentDensity}
                    stickyColumns={tablePreferences?.stickyColumns}
                />
                <DeleteAlertModal
                    visible={isDeleteModalOpen}
                    action={async () => {
                        if (selectedRows?.at(0)?.id) {
                            setDeleteModalOpen(false);
                            spinnerContext?.showSpinner();
                            await deleteWorkFlowTemplate({
                                id: String(selectedRows?.at(0)?.id),
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                                message: `Workflow Template deleted successfully`,
                                placement: "topRight",
                            });
                        }
                    }}
                    closeModal={() => setDeleteModalOpen(false)}
                    header="Delete Workflow Template"
                    content={
                        <Typography.Text>
                            Workflow Template Name:{" "}
                            <Typography.Text strong>{selectedRows?.at(0)?.name}</Typography.Text>
                        </Typography.Text>
                    }
                    description="Are you sure you want to delete this workflow template?"
                />
            </Space>
        </Layout>
    );
}
