import { Button, ConfigProvider, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  useExportProviderAffiliationsReportMutation,
  useLazyGetProviderAffiliationReportQuery,
} from "../redux/api/reporting/reporting";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import IncredableDateFormat from "../common/incredable-date-format";
import useFileExport from "../hooks/useFileExport";

export default function ProviderAffiliationsReport() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { taskId, setTaskId } = useFileExport("csv");
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });

  const [
    trigger,
    { data: providerAffiliationReportsData, isLoading, isFetching },
  ] = useLazyGetProviderAffiliationReportQuery({});
  const [exportProviderAffiliationsReport] =
    useExportProviderAffiliationsReportMutation();

  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  useEffect(() => {
    debouncedTrigger({
      pageNumber,
      pageSize,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [pageNumber, pageSize, sortingDetails, debouncedTrigger]);
  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "facility",
            header: "Facility",
            cell: (item) => <span>{item?.facility?.name}</span>,
            sortingField: "facility",
            isRowHeader: true,
          },
          {
            id: "affiliationStatus",
            header: "Affiliation Status",
            cell: (item) => item?.affiliationStatus?.name,
            sortingField: "affiliationStatus",
          },
          {
            id: "affiliationType",
            header: "Affiliation Type",
            cell: (item) => item?.affiliationType?.name,
            sortingField: "affiliationType",
          },
          {
            id: "name",
            header: "Name",
            cell: (item) => item?.name,
            sortingField: "name",
          },
          {
            id: "email",
            header: "Email address",
            cell: (item) => item?.email,
            sortingField: "email",
          },
          {
            id: "fax",
            header: "Fax",
            cell: (item) => item?.fax,
            sortingField: "fax",
          },
          {
            id: "phone",
            header: "Phone Number",
            cell: (item) => item?.phone,
            sortingField: "phone",
          },
          {
            id: "provider",
            header: "Provider",
            cell: (item) =>
              `${item?.provider?.lastName} ${item?.provider?.firstName}`,
            sortingField: "provider",
          },
          {
            id: "providerEmail",
            header: "Provider Email",
            cell: (item) => <span>{item?.provider?.email || "-"}</span>,
            sortingField: "provider.email",
          },
          {
            id: "providerLicenseType",
            header: "Provider License Type",
            cell: (item) => (
              <span>{item?.provider?.licenseType?.name || "-"}</span>
            ),
            sortingField: "provider.licenseType.name",
          },
          {
            id: "identificationNumber",
            header: "Identification Number",
            cell: (item) => (
              <span>{item?.provider?.identificationNumber || "-"}</span>
            ),
            sortingField: "provider.identificationNumber",
          },
          {
            id: "alertDays",
            header: "Expiration Remainder",
            cell: (item) => (
              <span>{item?.alertDays ? `${item.alertDays} Days` : ""}</span>
            ),
          },
          {
            id: "startDate",
            header: "Start Date",
            cell: (item) => IncredableDateFormat(item?.startDate),
          },
          {
            id: "endDate",
            header: "End Date",
            cell: (item) => IncredableDateFormat(item?.endDate),
          },
          {
            id: "documentName",
            header: "Document Name",
            cell: (item) => item?.document?.name,
          },
          {
            id: "notes",
            header: "Notes",
            cell: (item) => item?.notes,
          },
        ]}
        items={
          !!providerAffiliationReportsData
            ? providerAffiliationReportsData?.providerAffiliations
            : []
        }
        pagination={
          <Pagination
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!pageSize && pageSize !== 0
                ? Math.ceil(
                    !!providerAffiliationReportsData
                      ? providerAffiliationReportsData?.totalRecords / pageSize
                      : 0
                  )
                : 1
            }
          />
        }
        loadingText="Loading resources"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    loading={!!taskId}
                    onClick={() => {
                      const taskId =
                        "Provider_Affiliations_Report_" + Date.now();
                      exportProviderAffiliationsReport({
                        taskId: taskId,
                      });
                      setTaskId(taskId);
                    }}
                  >
                    Export CSV
                  </Button>
                </ConfigProvider>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Provider Affiliation Reports{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${
                  providerAffiliationReportsData?.totalRecords || 0
                })`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
      />
    </div>
  );
}
