import { useEffect } from "react";
import {
  Alert,
  AttributeEditor,
  Box,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Textarea,
  Select as CloudSelect,
  SelectProps,
} from "@cloudscape-design/components";
import { useSearchParams } from "react-router-dom";
import { useGetProviderQuery } from "../../../redux/api/provider/provider";
import useIsLoading from "../../../hooks/useIsLoading";
import {
  getRecipientTypeOption,
  RecipientType,
} from "../../../redux/api/signaturerequest/types";
import { v4 as uuidv4 } from "uuid";
import { Col, DatePicker, Divider, Row } from "antd";
import { useGetAllCategoryQuery } from "../../../redux/api/category/category";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { alertReminderOptions } from "../../../common/constants";
import Recipient, {
  AddlRecipientColors,
  ColorIcon,
  RecipientValue,
  TypeSelection,
} from "./recipient";
import { skipToken } from "@reduxjs/toolkit/query";
dayjs.extend(dayjsPluginUTC);

type SignatureRequestStepOneProps = {
  primaryRecipient: RecipientType | undefined;
  setPrimaryRecipient: React.Dispatch<
    React.SetStateAction<RecipientType | undefined>
  >;
  addlRecipients: RecipientType[];
  alertDaysOption: SelectProps.Option | null;
  setAlertDaysOption: React.Dispatch<
    React.SetStateAction<SelectProps.Option | null>
  >;
  categoryOption: SelectProps.Option | null;
  setCategoryOption: React.Dispatch<
    React.SetStateAction<SelectProps.Option | null>
  >;
  expirationDate: string | Date | undefined;
  setExpirationDate: React.Dispatch<
    React.SetStateAction<string | Date | undefined>
  >;
  setAddlRecipients: React.Dispatch<React.SetStateAction<RecipientType[]>>;
  subject: string;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  messageBody: string;
  setMessageBody: React.Dispatch<React.SetStateAction<string>>;
  errorText: string | undefined;
  setErrorText: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export function SignatureRequestStepOne({
  primaryRecipient,
  setPrimaryRecipient,
  addlRecipients,
  setAddlRecipients,
  alertDaysOption,
  setAlertDaysOption,
  expirationDate,
  setExpirationDate,
  categoryOption,
  setCategoryOption,
  subject,
  setSubject,
  messageBody,
  setMessageBody,
  errorText,
  setErrorText,
}: SignatureRequestStepOneProps) {
  const [searchParams] = useSearchParams();
  const { data: categories = [] } = useGetAllCategoryQuery();
  const { data: provider, isLoading: isProviderLoading } = useGetProviderQuery(
    searchParams.has("providerId")
      ? { providerId: searchParams.get("providerId") ?? "" }
      : skipToken
  );

  useIsLoading(isProviderLoading);

  useEffect(() => {
    if (!!provider)
      setPrimaryRecipient({
        id: uuidv4(),
        member: undefined,
        emailAddress: undefined,
        provider: provider,
        action: "sign",
      });
  }, [provider]);

  return (
    <>
      <SpaceBetween size={"xxs"}>
        <Header variant="h3" info={<span style={{ color: "#fc0a37" }}>*</span>}>
          Primary Recipient
        </Header>
        <Recipient
          recipient={primaryRecipient}
          displayLabels
          dragabble={false}
          disableFields={{ action: true }}
          hideDelete
          onRecipientChange={setPrimaryRecipient}
        />
      </SpaceBetween>
      <Divider />
      <SpaceBetween size="xs" direction="horizontal">
        <Box fontSize="body-m" fontWeight="bold">
          Additional Recipients
        </Box>
        <Box color="text-body-secondary">
          <i>(optional)</i>
        </Box>
      </SpaceBetween>

      <AttributeEditor
        onAddButtonClick={() =>
          setAddlRecipients([
            ...addlRecipients,
            {
              id: uuidv4(),
              action: "sign",
              backgroundColor:
                AddlRecipientColors[
                  addlRecipients.length % AddlRecipientColors.length
                ],
            },
          ])
        }
        onRemoveButtonClick={({ detail: { itemIndex } }) => {
          const tmpItems = [...addlRecipients];
          tmpItems.splice(itemIndex, 1);
          tmpItems.forEach((item, index) => {
            item.backgroundColor =
              AddlRecipientColors[index % AddlRecipientColors.length];
          });
          setAddlRecipients(tmpItems);
        }}
        removeButtonText="Remove"
        items={addlRecipients}
        addButtonText="Add Additional Recipient"
        definition={[
          {
            label: "Type",
            control: (item, index) => {
              return (
                <Row gutter={12}>
                  <Col span={2}>
                    <div style={{ marginTop: "6px" }}>
                      <ColorIcon backgroundColor={item.backgroundColor} />
                    </div>
                  </Col>
                  <Col span={20}>
                    <TypeSelection
                      displayLabels={false}
                      selectedOption={getRecipientTypeOption(item)}
                      onChange={({ detail }) => {
                        const tmpItems = [...addlRecipients];
                        if (!!detail.selectedOption?.value)
                          tmpItems[index] = {
                            ...tmpItems[index],
                            provider: undefined,
                            member: undefined,
                            emailAddress: undefined,
                            type: detail.selectedOption.value,
                          };
                        setAddlRecipients(tmpItems);
                      }}
                    />
                  </Col>
                </Row>
              );
            },
          },
          {
            label: "Recipient",
            control: (item, index) => (
              <RecipientValue
                type={getRecipientTypeOption(item).value}
                recipient={item}
                onRecipientChange={(recipient) => {
                  const tmpItems = [...addlRecipients];
                  if (!!recipient.provider)
                    tmpItems[index] = {
                      ...tmpItems[index],
                      provider: recipient.provider,
                      member: undefined,
                      emailAddress: undefined,
                    };
                  else if (!!recipient.member)
                    tmpItems[index] = {
                      ...tmpItems[index],
                      member: recipient.member,
                      provider: undefined,
                      emailAddress: undefined,
                    };
                  else
                    tmpItems[index] = {
                      ...tmpItems[index],
                      emailAddress: recipient.emailAddress,
                      provider: undefined,
                      member: undefined,
                    };

                  setAddlRecipients(tmpItems);
                }}
              />
            ),
          },
        ]}
      />
      <Divider />
      <FormField
        label={
          <span>
            Category <span style={{ color: "#fc0a37" }}>*</span>
          </span>
        }
        description="Choose the document category where the signed signature request will be stored."
        stretch={false}
      >
        <CloudSelect
          selectedOption={categoryOption}
          filteringType="auto"
          onChange={({ detail }) => setCategoryOption(detail.selectedOption)}
          options={categories?.map((category) => ({
            label: category?.name,
            value: category?.id + "",
          }))}
        />
      </FormField>
      <Divider />
      <FormField
        label={
          <span>
            Expiration <span style={{ color: "#fc0a37" }}>*</span>
          </span>
        }
        description="By default, signature requests expire after 30 days. You can set the expiration up to a maximum of 120 days, after which recipients cannot view or sign."
        stretch={false}
      >
        <DatePicker
          style={{ width: "100%" }}
          onChange={(date, dateString) => {
            console.log({ date, dateString });
            if (!!date) {
              setExpirationDate(date.format("YYYY-MM-DD") + "T00:00:00");
            }
          }}
          value={
            dayjs(expirationDate).isValid() &&
            !!expirationDate &&
            dayjs.utc(expirationDate)
          }
          showNow={false}
          allowClear={false}
          format={"MM-DD-YYYY"}
          minDate={dayjs().add(1, "days")}
          maxDate={dayjs().add(120, "days")}
        />
      </FormField>
      <FormField label={<span>Alert Reminder</span>} stretch={false}>
        <CloudSelect
          selectedOption={alertDaysOption}
          onChange={({ detail }) => setAlertDaysOption(detail.selectedOption)}
          options={alertReminderOptions}
        />
      </FormField>
      <Divider />
      <Box fontSize="body-m" fontWeight="bold">
        Add Message
      </Box>
      <FormField label="Subject" info={<i>(optional)</i>} stretch={false}>
        <Input
          value={subject}
          onChange={(changeDetails) => {
            setSubject(changeDetails.detail.value);
          }}
        ></Input>
      </FormField>
      <FormField info={<i>(optional)</i>} label="Message" stretch={false}>
        <Textarea
          value={messageBody}
          onChange={(changeDetails) => {
            setMessageBody(changeDetails.detail.value);
          }}
        />
      </FormField>
      {!!errorText && (
        <FormField stretch={true}>
          <Alert statusIconAriaLabel="Error" type="error" header={errorText} />
        </FormField>
      )}
    </>
  );
}
