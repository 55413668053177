import {
  Col,
  Divider,
  Form,
  Row,
  Tabs as AntTabs,
  Card,
  ConfigProvider,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderLicenseRequestType } from "../../redux/api/provider/types";
import {
  useAddProviderLicenseMutation,
  useGetAllLicenseTypesQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import RHFSelect from "../../components/RHF/RHFSelect";
import { alertReminderOptions, USStatesList } from "../../common/constants";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import StickyFooter from "../../components/StickyFooter";
import { DocumentRequest } from "../../redux/api/document/types";
import ProviderDocumentDetails from "../provider-document-details";
import {
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/browser";
export default function EditProviderLicense() {
  const { providerId, licenseId } = useParams();
  const methods = useForm<ProviderLicenseRequestType>();
  const documentMethods = useForm<DocumentRequest & { documentId: string }>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && provider?.providerLicenses) {
      const license = provider?.providerLicenses?.find(
        (license) => license?.id + "" === licenseId + ""
      );
      if (!!license) {
        methods.setValue("id", license.id);
        methods.setValue("licenseType", license.licenseType);
        methods.setValue("licenseNumber", license.licenseNumber);
        methods.setValue("startDate", license.startDate);
        methods.setValue("endDate", license.endDate);
        methods.setValue("isCurrent", license.isCurrent);
        methods.setValue("state", license.state);
        methods.setValue("notes", license?.notes);
        if (!!license?.document) {
          documentMethods.setValue("documentId", license?.document?.id);
          documentMethods.setValue("name", license?.document?.name);
          documentMethods.setValue(
            "categoryId",
            license?.document?.category?.id
          );
          documentMethods.setValue("notes", license?.document?.notes);
          documentMethods.setValue(
            "expirationDate",
            license?.document?.expirationDate
          );
          documentMethods.setValue("alertDays", license?.document?.alertDays);
        }
      }
    }
  }, [provider, licenseId]);

  const [updateProviderLicense] = useAddProviderLicenseMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [saveDocument] = useSaveDocumentMutation();

  return (
    <IncredableContent
      style={{ position: "relative", paddingTop: "10px" }}
      spinning={isLoading || isFetching}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: `${provider?.firstName || ""} ${provider?.lastName || ""}`,
            href: `/manageprovider/${providerId}`,
          },
          {
            text: licenseId ? "Edit License" : "Add License",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="license"
            size="large"
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    {licenseId ? "Edit License" : "Add License"}
                  </span>
                ),
                key: "license",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                      name="editproviderlicense"
                      layout="vertical"
                      onFinish={methods.handleSubmit(
                        async (data: ProviderLicenseRequestType) => {
                          if (!!providerId) {
                            spinnerContext?.showSpinner();
                            await updateProviderLicense({
                              ...data,
                              providerId: providerId,
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `License saved successfully`,
                              placement: "topRight",
                            });
                            navigate(-1);
                          }
                        }
                      )}
                      autoComplete="off"
                    >
                      <Form.List name="license" initialValue={[{}]}>
                        {(fields, { add }) => {
                          return (
                            <>
                              {fields.map(
                                ({ key, name, ...restField }, index) => (
                                  <div key={key}>
                                    {index > 0 && <Divider />}
                                    <Row
                                      gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                      }}
                                    >
                                      <Col span={8}>
                                        <RHFTextField
                                          formItemProps={{
                                            label: "License Number",
                                          }}
                                          inputProps={{}}
                                          controllerProps={{
                                            name: "licenseNumber",
                                          }}
                                          rules={{
                                            required: "This field is required",
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFSelect
                                          formItemProps={{
                                            label: "License Type",
                                          }}
                                          controllerProps={{
                                            name: `licenseType`,
                                          }}
                                          selectProps={{
                                            allowClear: true,
                                            showSearch: true,
                                            style: { width: "100%" },
                                            placeholder: "Select license type",
                                            options: ["Medical", "State"]?.map(
                                              (license) => ({
                                                label: license,
                                                value: license,
                                              })
                                            ),
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFSelect
                                          formItemProps={{ label: "State" }}
                                          controllerProps={{
                                            name: `state`,
                                          }}
                                          selectProps={{
                                            allowClear: true,
                                            showSearch: true,
                                            style: { width: "100%" },
                                            placeholder: "Select state",
                                            filterOption: (input, option) =>
                                              (option?.value ?? '').toString().toLowerCase().includes(input?.toLowerCase())
                                            ,
                                            options: USStatesList?.map(
                                              (state) => {
                                                return {
                                                  value: state?.value,
                                                };
                                              }
                                            ),
                                          }}
                                        />
                                      </Col>

                                      <Col span={8}>
                                        <RHFDatePicker
                                          formItemProps={{
                                            label: "Start Date",
                                          }}
                                          controllerProps={{
                                            name: `startDate`,
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <RHFDatePicker
                                          formItemProps={{
                                            label: "End Date",
                                          }}
                                          controllerProps={{
                                            name: `endDate`,
                                          }}
                                        />
                                      </Col>
                                      <Col span={24}>
                                        <RHFTextArea
                                          formItemProps={{ label: "Notes" }}
                                          controllerProps={{ name: "notes" }}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              )}
                            </>
                          );
                        }}
                      </Form.List>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Document</span>,
                key: "document",
                children: (
                  <FormProvider {...documentMethods}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Form: {
                            verticalLabelMargin: -4,
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "30px",
                        }}
                        onFinish={documentMethods.handleSubmit(
                          async (data: DocumentRequest) => {
                            console.log("document methods");
                          }
                        )}
                        layout="vertical"
                        size="middle"
                        autoComplete="off"
                      >
                        <ProviderDocumentDetails
                          uploadedFile={uploadedFile}
                          setUploadedFile={setUploadedFile}
                        />
                      </Form>
                    </ConfigProvider>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Alert</span>,
                key: "alert",
                children: (
                  <FormProvider {...documentMethods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "30px",
                      }}
                      layout="vertical"
                      size="middle"
                      autoComplete="off"
                    >
                      <Card>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={12}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Expiration Date",
                                tooltip:
                                  "Re-Enrollment Date is set as the default expiration date. You may modify it below if needed.",
                              }}
                              controllerProps={{
                                name: "expirationDate",
                              }}
                            />
                          </Col>
                          <Col span={12}>
                            <RHFSelect
                              formItemProps={{
                                label: "Expiration Alert Reminder",
                              }}
                              controllerProps={{ name: "alertDays" }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select Expiration Reminder",
                                options: alertReminderOptions?.map((alert) => {
                                  return {
                                    label: alert?.label,
                                    value: alert?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Form>
                  </FormProvider>
                ),
              },
            ]}
          />
          <StickyFooter
            hasPermission={true}
            onSubmit={async () => {
              const hasDocumentValues =
                documentMethods.getValues("alertDays") ||
                documentMethods.getValues("expirationDate") ||
                documentMethods.getValues("name") ||
                documentMethods.getValues("file");

              const handleProviderLicenseSubmit = async (
                documentId?: string
              ): Promise<void> => {
                methods.handleSubmit(
                  async (data: ProviderLicenseRequestType) => {
                    if (!!providerId) {
                      spinnerContext?.showSpinner();
                      await updateProviderLicense({
                        ...data,
                        providerId: providerId,
                        ...(documentId && { documentId }),
                      });
                      spinnerContext?.hidespinner();
                      toastContext?.openSuccessNotification({
                        message: "License saved successfully",
                        placement: "topRight",
                      });
                      navigate(-1);
                    }
                  }
                )();
              };

              const handleFileUpload = async (
                key: string,
                file?: File
              ): Promise<void> => {
                if (!!file && key) {
                  try {
                    await fileAxios.put(getFileUrl(key), file);
                    toastContext?.openSuccessNotification({
                      message: `Document saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                }
              };

              if (!hasDocumentValues) {
                handleProviderLicenseSubmit();
              } else {
                const documentId = documentMethods.getValues("documentId");

                const handleDocumentUpdate = async (
                  data: DocumentRequest & { documentId: string }
                ): Promise<void> => {
                  try {
                    const result = await updateDocument({
                      documentId: data.documentId,
                      isVersioned: false,
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderLicenseSubmit(result?.id);
                    await handleFileUpload(
                      result?.attachment?.key,
                      uploadedFile
                    );
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: `Document updated successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    spinnerContext?.hidespinner();
                    toastContext?.openErrorNotification({
                      message: `Document update failed`,
                      placement: "topRight",
                    });
                  }
                };

                const handleDocumentSave = async (
                  data: DocumentRequest
                ): Promise<void> => {
                  try {
                    const documentResponse = await saveDocument({
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderLicenseSubmit(documentResponse?.id);
                    await handleFileUpload(
                      documentResponse?.attachment?.key,
                      uploadedFile
                    );
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                };

                documentMethods.handleSubmit(
                  documentId ? handleDocumentUpdate : handleDocumentSave
                )();
              }
            }}
            onCancel={() => {
              navigate(-1);
            }}
          />
        </div>
      </ConfigProvider>
    </IncredableContent>
  );
}
