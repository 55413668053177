import { Col, Divider, Form, Row, Tabs as AntTabs, ConfigProvider } from "antd";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import RHFTextField from "../../components/RHF/RHFTextField";
import IncredableContent from "../../components/incredable-content";
import { FormProvider, useForm } from "react-hook-form";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { useGetAllAffiliationTypeQuery } from "../../redux/api/affiliationType/affiliation";
import {
  useAddProviderAffiliationMutation,
  useGetAllAffiliationStatusQuery,
  useGetAllProviderSafeDocumentsQuery,
  useGetBasicAllProvidersQuery,
} from "../../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import IncredableButton from "../../components/button";
import { ReloadOutlined } from "@ant-design/icons";
import {
  AffiliationStatus,
  ProviderAffiliationTypeRequest,
} from "../../redux/api/provider/types";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useFindAffiliationByIdQuery } from "../../redux/api/affiliation/affiliation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { alertReminderOptions } from "../../common/constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import StickyFooter from "../../components/StickyFooter";

export default function AddAffiliation() {
  const methods = useForm<ProviderAffiliationTypeRequest>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const { affiliationId } = useParams();
  const { data: AffiliationType, isLoading } = useGetAllAffiliationTypeQuery();
  const { data: AffiliationStatus } = useGetAllAffiliationStatusQuery();
  const { data: facilities } = useGetFacilitiesQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: affiliation, isLoading: isLoadingByID } =
    useFindAffiliationByIdQuery(affiliationId ? { affiliationId } : skipToken);
  const [addProviderAffiliation] = useAddProviderAffiliationMutation();
  const [selectedDocument, setSelectedDocument] = useState({
    label: "None - no document selected",
    value: "-1",
  });
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const handleSubmit = methods.handleSubmit(async (data) => {
    spinnerContext?.showSpinner();
    try {
      await addProviderAffiliation({
        ...data,
        documentId:
          selectedDocument.value !== "-1" ? selectedDocument.value : "",
      });
      toastContext?.openSuccessNotification({
        message: affiliationId
          ? "Affiliation edited successfully"
          : "Affiliation saved successfully",
        placement: "topRight",
      });
      navigate(-1);
    } catch {
      toastContext?.openErrorNotification({
        message: affiliationId
          ? "Failed to edit affiliation"
          : "Failed to save affiliation",
        placement: "topRight",
      });
    } finally {
      spinnerContext?.hidespinner();
    }
  });

  useEffect(() => {
    if (affiliation) {
      methods.reset({
        id: affiliation.id,
        name: affiliation?.name,
        startDate: affiliation?.startDate,
        endDate: affiliation?.endDate,
        facilityId: affiliation?.facility?.id,
        affiliationStatusId: affiliation?.affiliationStatus?.id,
        affiliationTypeId: affiliation?.affiliationType?.id,
        email: affiliation?.email,
        phone: affiliation?.phone,
        fax: affiliation?.fax,
        alertDays: affiliation?.alertDays,
        documentId: affiliation?.document?.id,
        providerId: affiliation?.provider?.id,
        notes: affiliation?.notes,
      });

      if (affiliation?.document) {
        setSelectedDocument({
          label: affiliation.document.name,
          value: affiliation.document.id,
        });
      }
    }
  }, [affiliation]);

  return (
    <>
      <IncredableContent
        spinning={isLoading || isLoadingByID}
        style={{
          position: "relative",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
        breadcrumbGroupProps={{
          items: [
            {
              text: "Affiliations",
              href: "/affiliations",
            },
            {
              text: "Add",
              href: "#",
            },
          ],
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemSelectedColor: "rgb(29, 26, 31)",

                verticalItemPadding: "0",
                cardBg: "#F5F5F5",
                colorBgContainer: "#2B6BE6",
                inkBarColor: "none ",
                colorBorderSecondary: "#c6c6cd",
                fontSize: 16,
              },
            },
          }}
        >
          <AntTabs
            defaultActiveKey="affiliation"
            size="large"
            items={[
              {
                label: (
                  <span style={{ fontWeight: "600", padding: "0" }}>
                    {affiliationId ? "Edit Affiliation" : "Add Affiliation"}
                  </span>
                ),
                key: "affiliation",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      layout="vertical"
                      onFinish={handleSubmit}
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                    >
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFSelect
                            formItemProps={{ label: "Affiliation Type" }}
                            controllerProps={{ name: "affiliationTypeId" }}
                            selectProps={{
                              style: { width: "100%" },
                              placeholder: "Select Affiliation Type",
                              options: AffiliationType?.map((type) => ({
                                label: type.name,
                                value: type.id,
                              })),
                              onModifyClick: () => {
                                navigate("/affiliationtype");
                              },
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFSelect
                            formItemProps={{ label: "Affiliation Status" }}
                            controllerProps={{ name: "affiliationStatusId" }}
                            selectProps={{
                              style: { width: "100%" },
                              placeholder: "Select Affiliation Status",
                              options: AffiliationStatus?.map(
                                (status: AffiliationStatus) => ({
                                  label: status.name,
                                  value: status.id,
                                })
                              ),
                              onModifyClick: () => {
                                navigate("/affiliationstatus");
                              },
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFSelect
                            formItemProps={{ label: "Facility" }}
                            controllerProps={{ name: "facilityId" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: facilities?.map((facility) => ({
                                label: facility.name,
                                value: facility.id,
                              })),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFSelect
                            formItemProps={{ label: "Provider" }}
                            controllerProps={{ name: "providerId" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              options: providers?.map((provider) => ({
                                label: `${provider.firstName} ${provider.lastName}`,
                                value: provider.id,
                              })),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{ label: "Start Date" }}
                            controllerProps={{ name: "startDate" }}
                            datePickerProps={{
                              style: { width: "100%" },
                              placeholder: "Select Start Date",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{ label: "End Date" }}
                            controllerProps={{ name: "endDate" }}
                            datePickerProps={{
                              style: { width: "100%" },
                              placeholder: "Select End Date",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Expiration Reminder" }}
                            controllerProps={{ name: "alertDays" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: alertReminderOptions?.map((alert) => {
                                return {
                                  label: alert?.label,
                                  value: alert?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>

                      <Divider orientation="left">Contact Info</Divider>

                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Name" }}
                            controllerProps={{ name: "name" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Email" }}
                            controllerProps={{ name: "email" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFPhoneNumber
                            formItemProps={{ label: "Phone" }}
                            controllerProps={{ name: "phone" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Fax" }}
                            controllerProps={{ name: "fax" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{ name: "notes" }}
                          />
                        </Col>
                      </Row>

                      <Divider orientation="left">Document</Divider>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <DocumentSection providerId={affiliationId} />
                      </Row>
                      <StickyFooter
                        hasPermission={true}
                        onSubmit={() => ""}
                        onCancel={() => {
                          navigate(-1);
                        }}
                      />
                    </Form>
                  </FormProvider>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </IncredableContent>
    </>
  );
}

export const DocumentSection = ({ providerId }: { providerId?: string }) => {
  const { data: allDocuments, refetch } = useGetAllProviderSafeDocumentsQuery(
    providerId ? { providerId } : skipToken
  );
  const navigate = useNavigate();

  return (
    <>
      <Col span={12}>
        <RHFSelect
          formItemProps={{ label: "" }}
          controllerProps={{ name: "documentId" }}
          selectProps={{
            style: { width: "100%" },
            placeholder: "Select Document",
            options: allDocuments?.documents?.map((document) => ({
              label: document.name,
              value: document.id,
            })),
          }}
        />
      </Col>
      <Col span={12}>
        <ReloadOutlined
          size={20}
          style={{ marginLeft: "10px", marginTop: "42px" }}
          onClick={() => refetch()}
        />
        <IncredableButton
          style={{
            borderColor: "#598BEB",
            color: "#598BEB",
            marginLeft: "10px",
          }}
          secondary
          iconPosition={"end"}
          icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
          onClick={() => navigate("#")}
        >
          Create New Document
        </IncredableButton>
      </Col>
    </>
  );
};
