import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Flex, Layout, Space, Typography } from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  ProfileOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useCollection } from "@cloudscape-design/collection-hooks";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import {
  useDeleteRoleMutation,
  useGetAllRoleQuery,
} from "../../redux/api/role/role";
import { RoleMetaData } from "../../redux/api/role/types";
import AddRoleModal from "../modals/add-role-modal";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useLocalStorage } from "../../common/localStorage";
import { ROLES_CONTENT_DISPLAY_OPTIONS, ROLES_TABLE_DEFAULT_PREFERENCES, TablePreferences } from "../table-config";
import { Header, Pagination, Table } from "@cloudscape-design/components";

const columns = [
  {
    title: () => <Typography.Text>Roles</Typography.Text>,
    dataIndex: "name",
    key: "name",
    width: "50%",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Description</Typography.Text>,
    dataIndex: "description",
    key: "description",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function RolesTable() {
  const [selectedRow, setSelectedRow] = useState<RoleMetaData[] | undefined>([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.delete") ?? false),
  );

  const [addRoleModal, setAddRoleModal] = useState(false);
  const {
    data: RoleMetaData,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllRoleQuery();
  const [deleteRole] = useDeleteRoleMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Roled\s-Table-Preferences",
    ROLES_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    RoleMetaData ?? [],
    {
      pagination: { pageSize: tablePreferences?.pageSize },
    }
  );


  const handleModal =
    (setter: (value: boolean) => void, state?: boolean) => () => {
      setter(state ?? false);
    };

  const deleteSelectedRole = async () => {
    try {
      if (selectedRow?.at(0)?.id) {
        setDeleteModalOpen(false);
        spinnerContext?.showSpinner();
        await deleteRole({ roleId: String(selectedRow?.at(0)?.id) });
        toastContext?.openSuccessNotification({
          message: `Role deleted successfully`,
          placement: "topRight",
        });
      }
    } catch (error) {
      toastContext?.openErrorNotification({
        message: `Failed to delete role`,
        placement: "topRight",
      });
    } finally {
      spinnerContext?.hidespinner();
    }
  };

  return (
    <Layout>
      <Space size="large" direction="vertical">
        <Table
          loading={isFetching || isLoading}
          onSelectionChange={({ detail }) => {
            setSelectedRow([...detail.selectedItems]);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRow?.at(0)?.id === selectedItem?.id) {
                setSelectedRow([]);
              } else {
                setSelectedRow([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRow || []}
          columnDefinitions={[
            {
              id: "name",
              header: <div>Name</div>,
              cell: (item) => <NavLink to={`/settings/roles/${item?.id}`}>{item?.name}</NavLink>
            },
            {
              id: "description",
              header: <div>Description</div>,
              cell: (item) => <span>{item?.description}</span>,
            },
          ]}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              setPreferences={(preferences) => {
                setTablePreferences(preferences);
              }}
              contentDisplayOptions={ROLES_CONTENT_DISPLAY_OPTIONS}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        !!refetch && refetch();
                      }}
                    >
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      menu={{
                        disabled: selectedRow?.length !== 1,
                        items: [
                          {
                            label: "Details",
                            key: "details",
                            icon: <ProfileOutlined />,
                            onClick: () => {
                              navigate(`/settings/roles/${selectedRow?.at(0)?.id}`);
                            },
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            onClick: () => setDeleteModalOpen(true),
                            icon: <DeleteOutlined />,
                            danger: true,
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button
                        onClick={handleModal(setAddRoleModal, true)}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add Role
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: "0" }}>
                  Roles{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >{`(${RoleMetaData?.length || 0})`}</Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences?.contentDisplay}
          wrapLines={tablePreferences?.wrapLines}
          stripedRows={tablePreferences?.stripedRows}
          contentDensity={tablePreferences?.contentDensity}
          stickyColumns={tablePreferences?.stickyColumns}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={deleteSelectedRole}
          closeModal={handleModal(setDeleteModalOpen)}
          header="Delete Role"
          content={
            <>
              <Typography.Text>Role</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {selectedRow?.at(0)?.name}
              </Typography.Text>
            </>
          }
          description="Are you sure you want to delete this Role?"
        />
      </Space>
      <AddRoleModal
        visible={addRoleModal}
        onClose={handleModal(setAddRoleModal)}
      />
    </Layout>
  );
}
