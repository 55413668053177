import { Col, Divider, Form, Row, Tabs as AntTabs, ConfigProvider } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { PayerEnrollmentRequest } from "../../redux/api/payerenrollment/types";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useGetPayerEnrollmentQuery,
  useUpdatePayerEnrollmentMutation,
} from "../../redux/api/payerenrollment/payerenrollment";
import { useGetAllPayerQuery } from "../../redux/api/payers/payers";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableContent from "../../components/incredable-content";
import { alertReminderOptions } from "../../common/constants";
import { ReloadOutlined } from "@ant-design/icons";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import StickyFooter from "../../components/StickyFooter";
import { Facility } from "../../redux/api/facility/types";
import { useGetAllPayerGroupQuery } from "../../redux/api/payergroup/payergroup";
import { PayerGroup } from "../../redux/api/payergroup/types";
import PayerEnrollmentComment from "../payerenrollment-comments";

export default function EditPayerEnrollment() {
  const navigate = useNavigate();
  const { enrollmentId } = useParams();
  const methods = useForm<PayerEnrollmentRequest>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false)
  );
  const { data: payer = [] } = useGetAllPayerQuery();
  const { data: payerGroup = [] } = useGetAllPayerGroupQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: facilityGroups } = useGetAllFacilityGroupQuery();
  const { data: status } = useGetAllPayerEnrollmentStatusQuery();
  const { data: workflows, refetch } = useGetAllWorkflowQuery();
  const selectedPayerId = methods.watch("payerId");
  const selectedFacilityGroupId = methods.watch("facilityGroupId");
  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]);
  const [filteredPayergroup, setFilteredPayergroup] = useState<PayerGroup[]>(
    []
  );

  const [updatepayerenrollment] = useUpdatePayerEnrollmentMutation();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: payerEnrollment, isLoading } = useGetPayerEnrollmentQuery(
    enrollmentId ? { payerEnrollmentId: enrollmentId } : skipToken
  );
  useEffect(() => {
    if (payerEnrollment) {
      !!payerEnrollment?.status?.id &&
        methods.setValue("enrollmentStatusId", payerEnrollment?.status?.id);
      !!payerEnrollment?.provider?.id &&
        methods.setValue("providerId", payerEnrollment?.provider?.id);
      !!payerEnrollment?.facility?.id &&
        methods.setValue("facilityId", payerEnrollment?.facility?.id);
      !!payerEnrollment?.facilityGroup?.id &&
        methods.setValue("facilityGroupId", payerEnrollment?.facilityGroup?.id);
      !!payerEnrollment?.payer?.id &&
        methods.setValue("payerId", payerEnrollment?.payer?.id);
      !!payerEnrollment?.payerGroup?.id &&
        methods.setValue("payerGroupId", payerEnrollment?.payerGroup?.id);
      !!payerEnrollment?.workflow?.id &&
        methods.setValue("workflowId", payerEnrollment?.workflow?.id);
      methods.setValue("description", payerEnrollment?.description);
      methods.setValue("submittedDate", payerEnrollment?.submittedDate);
      methods.setValue("approvedDate", payerEnrollment?.approvedDate);
      methods.setValue("effectiveDate", payerEnrollment?.effectiveDate);
      methods.setValue(
        "recredentialingDate",
        payerEnrollment?.recredentialingDate
      );
      methods.setValue("alertDays", payerEnrollment?.alertDays);
      methods.setValue("payerProviderId", payerEnrollment?.payerProviderId);
    }
  }, [payerEnrollment, enrollmentId]);

  useEffect(() => {
    if (!!selectedFacilityGroupId) {
      const selectedFacilityGroup = facilityGroups?.find(
        (fg) => fg.id + "" === selectedFacilityGroupId + ""
      );
      setFilteredFacilities(selectedFacilityGroup?.facilities ?? []);
    } else setFilteredFacilities(facilities);
  }, [selectedFacilityGroupId, facilityGroups, facilities]);

  useEffect(() => {
    if (!!selectedPayerId) {
      const selectedPayer = payer?.find(
        (pg) => pg.id + "" === selectedPayerId + ""
      );
      setFilteredPayergroup(selectedPayer?.payerGroup ?? []);
    } else setFilteredPayergroup(payerGroup);
  }, [selectedPayerId, payerGroup, payer]);

  return (
    <IncredableContent
      spinning={isLoading}
      style={{
        position: "relative",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Payer Enrollments",
            href: "/payerenrollment",
          },
          {
            text: "Edit",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="enrollment"
            size="large"
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    Edit Payer Enrollment
                  </span>
                ),
                key: "enrollment",
                children: (
                  <div>
                    <FormProvider {...methods}>
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "50px",
                        }}
                        name="editpayerenrollment"
                        layout="vertical"
                        autoComplete="off"
                      >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Payer" }}
                              controllerProps={{
                                name: `payerId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: payer?.map((name) => {
                                  return {
                                    label: name?.name,
                                    value: name?.id,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Network" }}
                              controllerProps={{
                                name: `payerGroupId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select network",
                                options: filteredPayergroup?.map((network) => {
                                  return {
                                    label: network?.name,
                                    value: network?.id,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Provider" }}
                              controllerProps={{
                                name: `providerId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: providers?.map((provider) => {
                                  return {
                                    label: `${provider?.firstName} ${provider?.lastName}`,
                                    value: provider?.id + "",
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{
                                label: "Facility Group",
                              }}
                              controllerProps={{
                                name: `facilityGroupId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: facilityGroups?.map((name) => {
                                  return {
                                    label: name?.name + "",
                                    value: name?.id + "",
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Facility" }}
                              controllerProps={{
                                name: `facilityId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: filteredFacilities?.map((facility) => {
                                  return {
                                    label: facility?.name,
                                    value: facility?.id,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Status" }}
                              controllerProps={{
                                name: `enrollmentStatusId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: status?.map((name) => {
                                  return {
                                    label: name?.name,
                                    value: name?.id,
                                  };
                                }),
                                onModifyClick: () => {
                                  navigate("/payerenrollmentstatus");
                                },
                              }}
                              rules={{
                                required: "This field is required",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Submitted Date",
                              }}
                              controllerProps={{
                                name: `submittedDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Approved Date",
                              }}
                              controllerProps={{
                                name: `approvedDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Effective Date",
                              }}
                              controllerProps={{
                                name: `effectiveDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Re-Enrollment Date",
                              }}
                              controllerProps={{
                                name: `recredentialingDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Payer Provider Id",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerProviderId`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{
                                label: "Follow Up Date",
                              }}
                              controllerProps={{
                                name: `alertDays`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select Days",
                                options: alertReminderOptions?.map((alert) => {
                                  return {
                                    label: alert?.label,
                                    value: alert?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                        </Row>
                        <Divider />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={12}>
                            <RHFSelect
                              formItemProps={{ label: "Workflows" }}
                              controllerProps={{
                                name: `workflowId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select workflow",
                                options: workflows?.map((name) => {
                                  return {
                                    label: name?.name,
                                    value: name?.id,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col
                            span={12}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ReloadOutlined
                              size={20}
                              style={{ marginLeft: "10px" }}
                              onClick={() => refetch()}
                            />
                            <IncredableButton
                              style={{
                                borderColor: "#598BEB",
                                color: "#598BEB",
                                marginLeft: "10px",
                              }}
                              secondary
                              iconPosition={"end"}
                              onClick={() => navigate("/workflows/add")}
                            >
                              Create New Workflow
                            </IncredableButton>
                          </Col>
                        </Row>
                        <Divider />
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={24}>
                            <RHFTextArea
                              formItemProps={{ label: "Notes" }}
                              controllerProps={{ name: "description" }}
                            />
                          </Col>
                        </Row>
                        <StickyFooter
                          hasPermission={true}
                          onSubmit={() => {
                            methods.handleSubmit(
                              async (data: PayerEnrollmentRequest) => {
                                if (!data.payerId && !data.payerGroupId) {
                                  toastContext?.openErrorNotification({
                                    message: `Network or Payer is required.`,
                                    placement: "top",
                                  });
                                  return;
                                }
                                if (!data.providerId && !data.facilityId) {
                                  toastContext?.openErrorNotification({
                                    message: `Provider or Facility is required.`,
                                    placement: "top",
                                  });
                                  return;
                                }
                                spinnerContext?.showSpinner();
                                if (!!enrollmentId) {
                                  await updatepayerenrollment({
                                    ...data,
                                    id: enrollmentId,
                                  });
                                }
                                spinnerContext?.hidespinner();
                                toastContext?.openSuccessNotification({
                                  message: `Payer Enrollment saved successfully`,
                                  placement: "topRight",
                                });
                                navigate(-1);
                              }
                            )();
                          }}
                          onCancel={() => {
                            navigate(-1);
                          }}
                        />
                      </Form>
                    </FormProvider>
                  </div>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Comments</span>,
                key: "comments",
                children: <PayerEnrollmentComment />,
              },
            ]}
          />
        </div>
      </ConfigProvider>
    </IncredableContent>
  );
}
