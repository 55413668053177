import { Col, Divider, Flex, Form, Row, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderRequest } from "../../redux/api/provider/types";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetAllLicenseTypeQuery } from "../../redux/api/licensetype/licensetype";
import {
  useGetProviderQuery,
  useUpdateProviderMutation,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { Facility } from "../../redux/api/facility/types";
export default function EditProvider() {
  const methods = useForm<ProviderRequest>();
  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: licensetypes } = useGetAllLicenseTypeQuery();
  const { data: facilityGroup = [] } = useGetAllFacilityGroupQuery();
  const { providerId } = useParams();
  const {
    data: provider,
    isFetching,
    isLoading,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (provider) {
      methods.setValue("firstName", provider.firstName);
      methods.setValue("lastName", provider.lastName);
      methods.setValue("email", provider.email);
      methods.setValue("npiNumber", provider.npiNumber);
      methods.setValue("facilityId", provider.facility?.id);
      methods.setValue("licenseTypeId", provider.licenseType?.id);
      methods.setValue("facilityGroupId", provider.facilityGroup?.id);
    }
  }, [provider]);
  const [updateProvider] = useUpdateProviderMutation();
  const selectedFacilityGroupId = methods.watch("facilityGroupId");
  useEffect(() => {
    if (!!selectedFacilityGroupId) {
      const selectedFacilityGroup = facilityGroup.find(
        (fg) => fg.id + "" === selectedFacilityGroupId + ""
      );
      setFilteredFacilities(selectedFacilityGroup?.facilities ?? []);
    } else setFilteredFacilities(facilities);
  }, [selectedFacilityGroupId, facilityGroup, facilities]);

  return (
    <>
      <IncredableContent
        spinning={isLoading || isFetching}
        breadcrumbGroupProps={{
          items: [
            {
              text: "Provider",
              href: "/manageprovider",
            },
            {
              text: `${provider?.firstName} ${provider?.lastName}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: "Add Provider",
              href: "#",
            },
          ],
        }}
      >
        <FormProvider {...methods}>
          <Form
            name="addprovider"
            layout="vertical"
            onFinish={methods.handleSubmit(async (data: ProviderRequest) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProvider({ id: providerId, ...data });
                spinnerContext?.hidespinner();
                toastContext?.openInfoNotification({
                  message: `Provider saved successfully`,
                  placement: "topRight",
                });
                navigate(-1);
              }
            })}
            autoComplete="off"
          >
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Edit Provider
              </Typography.Text>
              <Space>
                <IncredableButton
                  secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </IncredableButton>
                <IncredableButton
                  disabled={!hasPermission}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Form.List name="providers" initialValue={[{}]}>
              {(fields, { add }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div key={key}>
                        {index > 0 && <Divider />}
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "First Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "firstName",
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Last Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "lastName",
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Email",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "email",
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "NPI Number",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "npiNumber",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Facility" }}
                              controllerProps={{ name: "facilityId" }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: filteredFacilities?.map((facility) => {
                                  return {
                                    label: facility?.name,
                                    value: facility?.id,
                                  };
                                }),
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Facility Group" }}
                              controllerProps={{
                                name: `facilityGroupId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: facilityGroup?.map((group) => {
                                  return {
                                    label: group?.name,
                                    value: group?.id,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "License Type" }}
                              controllerProps={{ name: "licenseTypeId" }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: licensetypes?.map((licensetype) => {
                                  return {
                                    label: licensetype?.name,
                                    value: licensetype?.id + "",
                                  };
                                }),
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Form>
        </FormProvider>
      </IncredableContent>
    </>
  );
}
