import { Spin, Card, Row, Col } from "antd";
import { useFormContext } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/query";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useGetDocumentQuery } from "../redux/api/document/document";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import RHFTextField from "../components/RHF/RHFTextField";
import RHFSelect from "../components/RHF/RHFSelect";
import RHFTextArea from "../components/RHF/RHFTextArea";
import AttachmentViewer from "../components/attachment-viewer";

export default function ProviderDocumentDetails({
  title,
  documentId,
  uploadedFile,
  setUploadedFile,
}: {
  documentId?: string;
  title?: React.ReactNode;
  uploadedFile: File | undefined;
  setUploadedFile: Dispatch<SetStateAction<File | undefined>>;
}) {
  const methods = useFormContext();
  const { data: document, isLoading } = useGetDocumentQuery(
    documentId ? { documentId } : skipToken
  );

  const { data: categories } = useGetAllCategoryQuery();

  useEffect(() => {
    if (!document) return;
    methods.reset({
      name: document?.name,
      alertDays: document?.alertDays,
      expirationDate: document?.expirationDate,
      notes: document?.notes,
      categoryId: document?.category?.id,
      providerId: document?.provider?.id,
      facilityId: document?.facility?.id,
    });
  }, [document, methods]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <Row gutter={24}>
      <Col flex="360px">
        <Card style={{ height: "100%" }} title={title}>
          <RHFTextField
            formItemProps={{
              label: "Document Name",
            }}
            inputProps={{}}
            controllerProps={{ name: "name" }}
            rules={{ required: "This field is required" }}
          />
          <RHFSelect
            formItemProps={{
              label: "Category",
            }}
            controllerProps={{ name: "categoryId" }}
            selectProps={{
              allowClear: true,
              showSearch: true,
              style: { width: "100%" },
              placeholder: "Select Category",
              options: categories?.map((category) => {
                return {
                  label: category?.name,
                  value: category?.id,
                };
              }),
            }}
          />
          <RHFTextArea
            formItemProps={{
              label: "Notes",
            }}
            controllerProps={{ name: "notes" }}
            textAreaProps={{}}
          />
        </Card>
      </Col>
      <Col flex="auto">
        <AttachmentViewer
          onFileChange={(file) => {
            setUploadedFile(file);
          }}
          file={uploadedFile}
        />
      </Col>
    </Row>
  );
}
