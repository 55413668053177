import { api } from "..";
import { Location, LocationRequest } from "./types";
const locationAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllLocation: build.query<Location[], void>({
      query: () => `location/all`,
      providesTags: ["Location"],
    }),
    getLocation: build.query<Location, { id: string }>({
      query: ({ id }) => `location/${id}`,
      providesTags: (result, error, { id }) => [{ type: "Location", id: id }],
    }),
    createLocation: build.mutation<void, LocationRequest>({
      query: ({ ...body }) => {
        return {
          url: `location`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Location"],
    }),
    updateLocation: build.mutation<
      Location,
      Pick<Location, "id"> & Partial<LocationRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `location/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Location", id: arg.id },
        { type: "Location" },
      ],
    }),
    deleteLocation: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `location/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Location"],
    }),
    updateAssociatedLocationFacility: build.mutation<
      void,
      { locationId: string; locationToUpdate: string[] }
    >({
      query: ({ locationId, locationToUpdate }) => ({
        url: `location/${locationId}/associatefacilities`,
        method: "PUT",
        body: locationToUpdate,
      }),
      invalidatesTags: ["Location"],
    }),
    removeAssociatedLocationsfromLocations: build.mutation<
    void,
    { locationId: string; id: string[] }
  >({
    query: ({ locationId, id }) => ({
      url: `/loaction/${locationId}/disassociatefacilities`,
      method: "DELETE",
      body: id, // Wrap faciltiesToRemove in an object
    }),
    invalidatesTags: ["Location"],
  }),
  }),
});

export const {
  useGetAllLocationQuery,
  useGetLocationQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useUpdateAssociatedLocationFacilityMutation,
  useRemoveAssociatedLocationsfromLocationsMutation,
  useDeleteLocationMutation,
} = locationAPI;
