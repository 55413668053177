import { Dropdown, Button, Space, Typography, ConfigProvider } from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import useAffiliationFilter from "../table-filters/affiliations-filters";
import { ProviderAffiliation } from "../redux/api/affiliation/types";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeleteProviderAffiliationMutation } from "../redux/api/provider/provider";
import IncredableDateFormat from "../common/incredable-date-format";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useState, useContext, useEffect } from "react";
import { useLocalStorage } from "../common/localStorage";
import { Header, Link, Pagination, Table } from "@cloudscape-design/components";
import {
  AFFILIATION_CONTENT_DISPLAY_OPTIONS,
  AFFILIATION_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";

export default function AffiliationsListV2() {
  const [selectedRows, setSelectedRows] = useState<ProviderAffiliation[]>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );
  const {
    TableFilters,
    filteredAffiliations = [],
    affiliationTotalRecords,
    UseQueryHookResult: {
      reload,
      isFetching,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  } = useAffiliationFilter();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteProviderAffiliation] = useDeleteProviderAffiliationMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Affiliation-Table-Preferences",
    AFFILIATION_TABLE_DEFAULT_PREFERENCES
  );
  useEffect(() => {
    if (!!tablePreferences?.pageSize) setPageSize(tablePreferences?.pageSize);
  }, [setPageSize, tablePreferences?.pageSize]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredAffiliations,
    {
      pagination: { pageSize: pageSize },
    }
  );
  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail?.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={!!selectedRows ? selectedRows : []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          console.log({ detail });
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "facility",
            header: "Facility",
            cell: (item) => (
              <NavLink
                to={`/affiliations/editaffiliation/${item?.id}`}
              >{`${item?.facility?.name}`}</NavLink>
            ),
            sortingField: "facility.name",
            isRowHeader: true,
          },
          {
            id: "affiliationStatus",
            header: "Affiliation Status",
            cell: (item) => item?.affiliationStatus?.name,
            sortingField: "affiliationStatus.name",
          },
          {
            id: "affiliationType",
            header: "Affiliation Type",
            cell: (item) => item?.affiliationType?.name,
            sortingField: "affiliationType.name",
          },
          {
            id: "name",
            header: "Name",
            cell: (item) => item?.name,
          },
          {
            id: "email",
            header: "Email Address",
            cell: (item) => item?.email,
          },
          {
            id: "fax",
            header: "Fax",
            cell: (item) => item?.fax,
          },
          {
            id: "phone",
            header: "Phone Number",
            cell: (item) => item?.phone,
          },
          {
            id: "provider",
            header: "Provider",
            cell: (item) =>
              `${item?.provider?.lastName} ${item?.provider?.firstName}`,
            sortingField: "provider.lastName",
          },
          {
            id: "alertDays",
            header: "Expiration Remainder",
            cell: (item) => (
              <span>{item?.alertDays ? `${item.alertDays} Days` : ""}</span>
            ),
          },
          {
            id: "startDate",
            header: "Start Date",
            cell: (item) => IncredableDateFormat(item?.startDate),
            sortingField: "startDate"
          },
          {
            id: "endDate",
            header: "End Date",
            cell: (item) => IncredableDateFormat(item?.endDate),
            sortingField: "endDate"
          },
          {
            id: "documentName",
            header: "Document Name",
            cell: (item) => item?.document?.name,
          },
          {
            id: "notes",
            header: "Notes",
            cell: (item) => item?.notes,
          },
        ]}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!tablePreferences?.pageSize && tablePreferences?.pageSize !== 0
                ? Math.ceil(
                  affiliationTotalRecords / tablePreferences?.pageSize
                )
                : 1
            }
          />
        }
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              if (preferences.pageSize !== tablePreferences.pageSize) {
                preferences.pageSize && setPageSize(preferences.pageSize);
              }
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={AFFILIATION_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <Header
            counter={`(${filteredAffiliations?.length})`}
            actions={
              <Space size="middle">
                <Button
                  onClick={() => {
                    !!reload && reload();
                  }}
                >
                  <ReloadOutlined />
                </Button>
                <Dropdown
                  menu={{
                    disabled: selectedRows?.length !== 1,
                    items: [
                      {
                        label: hasPermission ? "Edit" : "View",
                        key: "edit",
                        icon: <EditOutlined />,
                        onClick: () => {
                          navigate(
                            `editaffiliation/${selectedRows?.at(0)?.id}`
                          );
                        },
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                        disabled: !hasDeletePermission,
                        onClick: () => {
                          handleOpenDeleteModal();
                        },
                      },
                    ],
                  }}
                >
                  <Button>
                    Actions
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Button
                    disabled={!hasPermission}
                    onClick={() => navigate("addAffiliation")}
                    type="primary"
                    icon={<PlusOutlined />}
                  >
                    Add Affiliation
                  </Button>
                </ConfigProvider>
              </Space>
            }
          >
            Affiliations
          </Header>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (
            selectedRows?.length === 1 &&
            selectedRows?.at(0)?.provider?.id &&
            selectedRows?.at(0)?.id
          ) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteProviderAffiliation({
              providerId: String(selectedRows?.at(0)?.provider?.id),
              affiliationId: String(selectedRows?.at(0)?.id),
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Affiliation deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Affiliation "
        content={
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>Affiliation</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {selectedRows?.at(0)?.name}
              </Typography.Text>
            </div>
          </>
        }
        description="Are you sure you want to delete this affiliation?"
      />
    </div>
  );
}
