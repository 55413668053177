import { Row, Col, Tabs as AntTabs, ConfigProvider } from "antd";
import DocumentDetails from "../versions/document-details";
import DocumentComment from "../versions/document-comments";
import { useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetDocumentQuery } from "../../redux/api/document/document";
import AttachmentViewer from "../../components/attachment-viewer";
import DocumentVersionsV1 from "../versions/document-versions-v1";
import DocumentActiviesV1 from "../versions/document-activities-v1";
import IncredableButton from "../../components/button";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { BreadcrumbGroup } from "@cloudscape-design/components";

type FormInputType = {
  name: string;
  expirationDate?: string | Date;
  createdDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
  providerId: string;
  facilityId: string;
  isVersioned: boolean;
};
export default function EditDocument() {
  const methods = useForm<FormInputType>();
  const { documentId } = useParams();
  const { data: document } = useGetDocumentQuery(
    !!documentId ? { documentId } : skipToken
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );

  const location = useLocation();
  let isProvider = location.pathname.includes("manageprovider");

  const [uploadedFile, setUploadedFile] = useState<File>();
  useEffect(() => {
    if (!document) return;
    methods.reset({
      name: document?.name,
      alertDays: document?.alertDays,
      expirationDate: document?.expirationDate,
      notes: document?.notes,
      categoryId: document?.category?.id,
      providerId: document?.provider?.id,
      facilityId: document?.facility?.id,
    });
  }, [document, methods]);

  return (
    <div
      style={{
        padding: "12px 24px 12px 24px",
      }}
    >
      <div
        style={{
          padding: "0 0 8px 0",
        }}
      >
        <BreadcrumbGroup
          items={[
            {
              text: isProvider ? "Provider" : "safe",
              href: isProvider
                ? `/manageprovider/${document?.provider?.id}`
                : "/safe",
            },
            { text: "Edit", href: "" },
          ]}
          ariaLabel="Breadcrumbs"
        />
      </div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <AntTabs
          defaultActiveKey="details"
          size="large"
          tabBarExtraContent={
            <IncredableButton secondary>Link to profile</IncredableButton>
          }
          items={[
            {
              label: <span style={{ fontWeight: "700" }}>Document</span>,
              key: "details",
              children: (
                <Row gutter={24}>
                  <Col flex="360px">
                    <DocumentDetails
                      uploadedFile={uploadedFile}
                      title={"Document details"}
                    />
                  </Col>
                  <Col flex="auto">
                    <AttachmentViewer
                      attachment={document?.attachment}
                      onFileChange={(file) => {
                        setUploadedFile(file);
                      }}
                      file={uploadedFile}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: <span style={{ fontWeight: "700" }}>Versions</span>,
              key: "versions",
              children: <DocumentVersionsV1 />,
            },
            {
              label: <span style={{ fontWeight: "700" }}>Audit logs</span>,
              key: "audit_logs",
              children: <DocumentActiviesV1 />,
            },
            {
              label: <span style={{ fontWeight: "700" }}>Comments</span>,
              key: "comments",
              children: <DocumentComment />,
            },
          ]}
        />
      </ConfigProvider>
    </div>
  );
}
