import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 items" },
  { value: 30, label: "30 items" },
  { value: 50, label: "50 items" },
];
export const FACILITY_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Facility ID" },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "npiNumber", label: "NPI number" },
  { id: "facilityType", label: "Facility Type" },
  { id: "facilityGroup", label: "Facility Group" },
  { id: "cliaId", label: "CLIA Id" },
  { id: "taxId", label: "Tax Id" },
  { id: "immunizationId", label: "Immunization Id" },
  { id: "medicaidId", label: "Medicaid" },
  { id: "taxonomyCode", label: "Taxonomy Code" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "mailingAddress", label: "Mailing Address" },
  { id: "addressLane1", label: "Address Line1" },
  { id: "addressLane2", label: "Address Line2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zipcode", label: "Zip Code" },
  { id: "country", label: "Country" },
  { id: "pocName", label: "POC Name" },
  { id: "pocEmail", label: "POC Email" },
  { id: "pocFax", label: "POC Fax" },
  { id: "notes", label: "Notes" },
];
export const FACILITY_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
{
  pageSize: 10,
  contentDisplay: [
    { id: "id", visible: true },
    { id: "name", visible: true },
    { id: "npiNumber", visible: true },
    { id: "facilityType", visible: true },
    { id: "facilityGroup", visible: true },
    { id: "taxId", visible: true },
    { id: "cliaId", visible: true },
    { id: "immunizationId", visible: true },
    { id: "medicaidId", visible: true },
    { id: "taxonomyCode", visible: true },
    { id: "phoneNumber", visible: true },
    { id: "mailingAddress", visible: true },
    { id: "addressLane1", visible: true },
    { id: "addressLane2", visible: true },
    { id: "city", visible: true },
    { id: "state", visible: true },
    { id: "zipcode", visible: true },
    { id: "country", visible: true },
    { id: "pocName", visible: true },
    { id: "pocEmail", visible: true },
    { id: "pocFax", visible: true },
    { id: "notes", visible: true },
  ],
  wrapLines: false,
  stripedRows: true,
  contentDensity: "comfortable",
  stickyColumns: { first: 0, last: 0 },
};

export const FACILITYTYPE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "description", label: "Description" },
];

export const FACILITYTYPE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
{
  pageSize: 10,
  contentDisplay: [
    { id: "id", visible: false },
    { id: "name", visible: true },
    { id: "description", visible: true },
  ],
  wrapLines: false,
  stripedRows: true,
  contentDensity: "comfortable",
  stickyColumns: { first: 0, last: 0 },
};

export const TablePreferences = ({
  title,
  disabled,
  preferences,
  setPreferences,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions,
}: {
  title?: string;
  disabled?: boolean;
  preferences: CollectionPreferencesProps.Preferences<any> | undefined;
  setPreferences: (
    preferences: CollectionPreferencesProps.Preferences<any>
  ) => void;
  pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
}) => (
  <CollectionPreferences
    disabled={disabled || !contentDisplayOptions.length}
    preferences={preferences}
    onConfirm={({ detail }) => {
      setPreferences(detail);
    }}
    title={title}
    pageSizePreference={{ options: pageSizeOptions }}
    wrapLinesPreference={{}}
    stripedRowsPreference={{}}
    contentDensityPreference={{}}
    contentDisplayPreference={{ options: contentDisplayOptions }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
