import React, { useEffect, useState } from "react";
import { Drawer, Layout, Typography } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import { Sidebar } from "../pages/index";
import { Topbar } from "../pages/index";
import { Images } from "../common/images";
import MyTasksList from "../tasks/mytasks/mytasks-list";
import NotificationsList from "../notification";
const { Header, Sider } = Layout;
const { Text } = Typography;
const siderStyle: React.CSSProperties = {
  overflow: "auto",
  height: "calc(100vh - 113px)",
  position: "fixed",
  insetInlineStart: 0,
  top: "65px",
  bottom: 0,
  scrollbarWidth: "none",
  scrollbarColor: "unset",
  transition: "all .2s linear !important",
  overflowX: "hidden",
};
const IncredableLayout: React.FC = () => {
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [activeHref, setActiveHref] = useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openNotificationsDrawer, setOpenNotificationsDrawer] = useState(false);

  const [logo, setLogo] = useState(Images.logo);
  useEffect(() => {
    setActiveHref("/" + (pathname.split("/")[1] ?? "#"));
  }, [pathname]);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const toggleNotificationsDrawer = () => {
    setOpenNotificationsDrawer(!openNotificationsDrawer);
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setLogo(collapsed ? Images.logo : Images.responsiveLogo);
  };
  return (
    <Layout hasSider>
      <Header
        style={{
          padding: "0 10px 0 10px",
          background: "#fff",
          position: "fixed",
          top: 0,
          zIndex: 1,
          width: "-webkit-fill-available",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "0px -1px 30px 1px rgba(47, 11, 56, 0.1)",
        }}
      >
        <Topbar
          showDrawer={toggleDrawer}
          showAlerts={toggleNotificationsDrawer}
          activeMenu={""}
        />
      </Header>
      <Layout
        style={{
          width: "100%",
          transition: "all .2s linear",
          justifyContent: "flex-end",
        }}
      >
        <Sider
          width={240}
          collapsedWidth={100}
          collapsible
          collapsed={collapsed}
          style={siderStyle}
          onCollapse={toggleCollapsed}
        >
          <div style={{ marginTop: "10px" }} className="demo-logo-vertical">
            <Text
              style={{
                color: "white",
                marginTop: "31px",
                marginLeft: "25px",
              }}
            >
              MENUS
            </Text>
          </div>
          <Sidebar
            activeHref={activeHref}
            collapseSideBar={() => {
              setCollapsed(true);
            }}
          />
        </Sider>
        <div
          style={{
            paddingTop: "72px",
            minHeight: `calc(100vh - 18px)`,
            width: !collapsed ? "calc(100% - 240px)" : "calc(100% - 100px)",
            transition: "all .2s linear",
          }}
        >
          <Outlet />
        </div>
        <Drawer
          title="My Tasks"
          size="large"
          onClose={toggleDrawer}
          open={drawerOpen}
        >
          <MyTasksList />
        </Drawer>
        <Drawer
          title="Notifications"
          size="large"
          onClose={toggleNotificationsDrawer}
          open={openNotificationsDrawer}
        >
          <NotificationsList />
        </Drawer>
      </Layout>
    </Layout>
  );
};
export default IncredableLayout;
