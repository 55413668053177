import { Card, Space, Typography } from "antd";
import IncredableContent from "../components/incredable-content";
import "../index.css";
import { useGetAllNotificationsQuery } from "../redux/api/notification/notification";
import {
  ContractNotificationType,
  DocumentNotificationType,
  NotificationType,
  PayerEnrollmentNotificationType,
  SignatureRequestNotificationType,
} from "../redux/api/notification/types";
import IncredableDateFormat from "../common/incredable-date-format";
import { Link } from "@cloudscape-design/components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
export default function NotificationsList() {
  const { data } = useGetAllNotificationsQuery();
  return (
    <div style={{ overflowX: "auto" }}>
      <Space size="large" direction="vertical">
        <Card title="Today">
          <NotificationCard data={data} subtractDays={0} />
        </Card>
        <Card title="Yesterday">
          <NotificationCard data={data} subtractDays={1} />
        </Card>
        <Card title={dayjs().subtract(2, "day").format("MM/DD/YYYY")}>
          <NotificationCard data={data} subtractDays={2} />
        </Card>
        <Card title={dayjs().subtract(3, "day").format("MM/DD/YYYY")}>
          <NotificationCard data={data} subtractDays={3} />
        </Card>
        <Card title={dayjs().subtract(4, "day").format("MM/DD/YYYY")}>
          <NotificationCard data={data} subtractDays={4} />
        </Card>
        <Card title={dayjs().subtract(5, "day").format("MM/DD/YYYY")}>
          <NotificationCard data={data} subtractDays={5} />
        </Card>
        <Card title={dayjs().subtract(6, "day").format("MM/DD/YYYY")}>
          <NotificationCard data={data} subtractDays={6} />
        </Card>
      </Space>
    </div>
  );
}

const NotificationCard = ({
  data,
  subtractDays,
}: {
  data: NotificationType | undefined;
  subtractDays: number;
}) => {
  // Filtering all notification types
  const documentNotifications = data?.document?.filter(
    (dn) =>
      dn?.createdDate ===
      dayjs().subtract(subtractDays, "day").format("MM/DD/YYYY")
  );

  const contractNotifications = data?.contract?.filter(
    (cn) =>
      cn?.createdDate ===
      dayjs().subtract(subtractDays, "day").format("MM/DD/YYYY")
  );

  const payerEnrollmentNotifications = data?.payerEnrollment?.filter(
    (cn) =>
      cn?.createdDate ===
      dayjs().subtract(subtractDays, "day").format("MM/DD/YYYY")
  );

  const signatureRequestNotifications = data?.signatureRequest?.filter(
    (cn) =>
      cn?.createdDate ===
      dayjs().subtract(subtractDays, "day").format("MM/DD/YYYY")
  );

  // Check if all notifications are empty
  const hasNotifications =
    documentNotifications?.length ||
    contractNotifications?.length ||
    payerEnrollmentNotifications?.length ||
    signatureRequestNotifications?.length;

  return (
    <Space direction="vertical">
      {/* Document Notifications */}
      {documentNotifications?.map((docNotification) => (
        <DocumentNotificationCard notificaiton={docNotification} />
      ))}

      {/* Contract Notifications */}
      {contractNotifications?.map((cn) => (
        <ContractNotificationCard notificaiton={cn} />
      ))}

      {/* Payer Enrollment Notifications */}
      {payerEnrollmentNotifications?.map((cn) => (
        <EnrollmentNotificationCard notificaiton={cn} />
      ))}

      {/* Signature Request Notifications */}
      {signatureRequestNotifications?.map((cn) => (
        <SignatureRequestNotificationCard notificaiton={cn} />
      ))}

      {/* Display "No Notifications" if everything is empty */}
      {!hasNotifications && (
        <Title type="secondary" level={4} style={{ marginTop: "0" }}>
          No notifications
        </Title>
      )}
    </Space>
  );
};

const DocumentNotificationCard = ({
  notificaiton,
}: {
  notificaiton: DocumentNotificationType;
}) => {
  const navigate = useNavigate();
  return (
    <Card
      hoverable
      id="notification"
      type="inner"
      onClick={() => {
        navigate(`/safe/${notificaiton?.documentId}/edit`);
      }}
    >
      <Space direction="vertical">
        <Link external>
          <span
            style={{
              fontSize: "15px",
              fontWeight: 600,
              color: "#2B6BE6",
              letterSpacing: 0.25,
              wordSpacing: 1,
            }}
          >
            {notificaiton?.providerName}:{" "}
            {!!notificaiton?.categoryName
              ? `${notificaiton?.categoryName} document`
              : notificaiton?.name}{" "}
            is {notificaiton?.status?.toLowerCase()}
          </span>
        </Link>
        <Space size="large">
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Document Name:</span>{" "}
            {notificaiton?.name}
          </span>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Expiration Date:</span>
            {!!notificaiton?.expirationDate &&
              IncredableDateFormat("", { date: notificaiton?.expirationDate })}
          </span>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Category:</span>{" "}
            {notificaiton?.categoryName}
          </span>
        </Space>
      </Space>
    </Card>
  );
};

const ContractNotificationCard = ({
  notificaiton,
}: {
  notificaiton: ContractNotificationType;
}) => {
  const navigate = useNavigate();

  return (
    <Card
      hoverable
      id="notification"
      type="inner"
      onClick={() => {
        navigate(`/contract/${notificaiton?.contractId}/edit`);
      }}
    >
      <Space direction="vertical">
        <Link external>
          <span
            style={{
              fontSize: "15px",
              fontWeight: 600,
              color: "#2B6BE6",
              letterSpacing: 0.25,
              wordSpacing: 1,
            }}
          >
            {notificaiton?.facilityName}: {notificaiton?.title} contract is{" "}
            {notificaiton?.status?.toLowerCase()}
          </span>
        </Link>
        <Space size="large">
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Expiration Date:</span>
            {!!notificaiton?.expirationDate &&
              IncredableDateFormat("", { date: notificaiton?.expirationDate })}
          </span>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Contract Type:</span>{" "}
            {notificaiton?.typeName}
          </span>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Assigned To:</span>{" "}
            {notificaiton?.assignedTo ?? "-"}
          </span>
        </Space>
      </Space>
    </Card>
  );
};

const EnrollmentNotificationCard = ({
  notificaiton,
}: {
  notificaiton: PayerEnrollmentNotificationType;
}) => {
  const navigate = useNavigate();

  return (
    <Card hoverable id="notification" type="inner" onClick={() => {}}>
      <Space direction="vertical">
        <Link external>
          <span
            style={{
              fontSize: "15px",
              fontWeight: 600,
              color: "#2B6BE6",
              letterSpacing: 0.25,
              wordSpacing: 1,
            }}
          >
            {notificaiton?.providerName}:{" "}
            {!!notificaiton?.networkName
              ? notificaiton?.networkName
              : notificaiton?.payerName}{" "}
            enrollment is {notificaiton?.status?.toLowerCase()}
          </span>
        </Link>
        <Space size="large">
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Expiration Date:</span>
            {!!notificaiton?.expirationDate &&
              IncredableDateFormat("", { date: notificaiton?.expirationDate })}
          </span>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Network Name:</span>{" "}
            {notificaiton?.networkName ?? "-"}
          </span>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: 500 }}>Payer Name:</span>{" "}
            {notificaiton?.payerName ?? "-"}
          </span>
        </Space>
      </Space>
    </Card>
  );
};

const SignatureRequestNotificationCard = ({
  notificaiton,
}: {
  notificaiton: SignatureRequestNotificationType;
}) => (
  <Card hoverable id="notification" type="inner">
    <Space direction="vertical">
      <Link external>
        <span
          style={{
            fontSize: "15px",
            fontWeight: 600,
            color: "#2B6BE6",
            letterSpacing: 0.25,
            wordSpacing: 1,
          }}
        >
          {notificaiton?.primaryRecipient}: {notificaiton?.formName} form
          signature request is {notificaiton?.status?.toLowerCase()}
        </span>
      </Link>
      <Space size="large">
        <span style={{ fontSize: "14px" }}>
          <span style={{ fontWeight: 500 }}>Expiration Date:</span>
          {!!notificaiton?.expirationDate &&
            IncredableDateFormat("", { date: notificaiton?.expirationDate })}
        </span>
        <span style={{ fontSize: "14px" }}>
          <span style={{ fontWeight: 500 }}>Primary Recipient:</span>{" "}
          {notificaiton?.primaryRecipient ?? "-"}
        </span>
        <span style={{ fontSize: "14px" }}>
          <span style={{ fontWeight: 500 }}>Form Name:</span>{" "}
          {notificaiton?.formName ?? "-"}
        </span>
      </Space>
    </Space>
  </Card>
);
