import { Row, Col, Breadcrumb, Form, Card, Button, Typography } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetDocumentVersionByIdQuery } from "../redux/api/document/document";
import AttachmentViewer from "../components/attachment-viewer";
import RHFTextArea from "../components/RHF/RHFTextArea";
import { alertReminderOptions } from "../common/constants";
import RHFSelect from "../components/RHF/RHFSelect";
import RHFDatePicker from "../components/RHF/RHFDatePicker";
import RHFTextField from "../components/RHF/RHFTextField";
import { useGetAllCategoryQuery } from "../redux/api/category/category";

type FormInputType = {
    name: string;
    expirationDate?: string | Date;
    createdDate: string;
    alertDays: number;
    notes: string;
    categoryId: string;
    providerId: string;
    facilityId: string;
    isVersioned: boolean;
};
export default function ViewDocumentVersion() {
    const navigate = useNavigate();
    const methods = useForm<FormInputType>();
    const { documentId, versionId } = useParams();
    const { data: categories } = useGetAllCategoryQuery();
    const { data: documentVersion } = useGetDocumentVersionByIdQuery(
        !!documentId && !!versionId ? { documentId, versionId } : skipToken
    );

    const location = useLocation();
    let isProvider = location.pathname.includes("manageprovider");

    const [uploadedFile, setUploadedFile] = useState<File>();
    useEffect(() => {
        if (!documentVersion) return;
        methods.reset({
            name: documentVersion?.name,
            alertDays: documentVersion?.alertDays,
            expirationDate: documentVersion?.expirationDate,
            notes: documentVersion?.notes,
            categoryId: documentVersion?.category?.id,
            providerId: documentVersion?.provider?.id,
            facilityId: documentVersion?.facility?.id,
        });
    }, [documentVersion, methods]);

    const breadcrumbProps = [
        {
            title: (
                <NavLink to={isProvider ? "/manageprovider" : "/safe"}>
                    {isProvider ? "Provider" : "Safe"}
                </NavLink>
            ),
        },
        {
            title: !!documentVersion?.createdDate
                ? `Version(${new Date(
                    documentVersion?.createdDate,
                ).toLocaleString()})`
                : "Version",
        },
    ];

    return (
        <div
            style={{
                padding: "12px 24px 12px 24px",
            }}
        >
            <div
                style={{
                    padding: "0 0 8px 0",
                }}
            >
                <Breadcrumb items={breadcrumbProps} />
            </div>
            <FormProvider {...methods}>
                <Form
                    layout="vertical"
                    size="middle"
                    autoComplete="off">
                    <Row gutter={24}>
                        <Card
                            title={
                                <Typography.Title level={4}
                                    style={{
                                        marginTop: "0",
                                        textOverflow: "ellipsis",
                                        width: "100px",
                                        overflow: "hidden"
                                    }}>
                                    {documentVersion?.name}
                                </Typography.Title>
                            }
                            extra={<Button
                                formAction="none"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                View Current
                            </Button>}
                        >
                            <Col flex="360px">
                                <RHFTextField
                                    formItemProps={{
                                        label: "Document Name",
                                    }}
                                    inputProps={{ disabled: true }}
                                    controllerProps={{ name: "name" }}

                                    rules={{ required: "This field is required" }}
                                />
                                <RHFSelect
                                    disabled
                                    formItemProps={{
                                        label: "Category",
                                    }}
                                    controllerProps={{ name: "categoryId" }}
                                    selectProps={{
                                        allowClear: true,
                                        showSearch: true,
                                        style: { width: "100%" },
                                        placeholder: "Select Category",
                                        options: categories?.map((category) => {
                                            return {
                                                label: category?.name,
                                                value: category?.id,
                                            };
                                        }),
                                    }}
                                />
                                <RHFDatePicker
                                    datePickerProps={{ disabled: true }}
                                    formItemProps={{
                                        label: "Expiration Date",
                                    }}
                                    controllerProps={{
                                        name: "expirationDate",
                                    }}
                                />
                                <RHFSelect
                                    disabled
                                    formItemProps={{
                                        label: "Expiration Alert Reminder",
                                    }}
                                    controllerProps={{ name: "alertDays" }}
                                    selectProps={{
                                        allowClear: true,
                                        showSearch: true,
                                        style: { width: "100%" },
                                        placeholder: "Select Expiration Reminder",
                                        options: alertReminderOptions?.map((alert) => {
                                            return {
                                                label: alert?.label,
                                                value: alert?.value,
                                            };
                                        }),
                                    }}
                                />
                                <RHFTextArea
                                    formItemProps={{
                                        label: "Notes",
                                    }}
                                    controllerProps={{ name: "notes" }}
                                    textAreaProps={{ disabled: true }}
                                />
                            </Col>
                        </Card>
                        <Col flex="auto">
                            <AttachmentViewer
                                attachment={documentVersion?.attachment}
                                onFileChange={(file) => {
                                    setUploadedFile(file);
                                }}
                                file={uploadedFile}
                            />
                        </Col>
                    </Row>
                </Form>
            </FormProvider >
        </div >
    );
}
