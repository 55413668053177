import * as React from "react";
import Button from "@cloudscape-design/components/button";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../components/RHF/RHFTextField";
import {
  Card,
  Col,
  ConfigProvider,
  Form,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import { useEffect } from "react";
import IncredableButton from "../components/button";
import { useShareProviderDocumentMutation } from "../redux/api/document/provider_document";

export default function ShareDocumentModal({
  title,
  attachmentKeys,
  closeModal,
  visible,
}: {
  title: string;
  attachmentKeys: string[];
  closeModal: VoidFunction;
  visible: boolean;
}) {
  const methods = useForm<{ email: string }>({ defaultValues: { email: "" } });
  const [shareDocument] = useShareProviderDocumentMutation();

  const onSubmit = (data: { email: string }) => {
    methods.resetField("email");
    shareDocument({
      attachmentKeys: attachmentKeys,
      email: "",
    });
  };

  useEffect(() => {
    methods.resetField("email");
  }, []);

  return (
    <Modal
      onCancel={() => {
        methods.resetField("email");
        closeModal();
      }}
      open={visible}
      footer={null}
      closeIcon={null}
      title={null}
    >
      <Card title={title}>
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            onFinish={methods.handleSubmit(async (data: { email: string }) => {
              methods.resetField("email");
              closeModal();
              await shareDocument({
                attachmentKeys: attachmentKeys,
                email: data.email,
              });
            })}
          >
            <RHFTextField
              formItemProps={{ label: "Email address" }}
              controllerProps={{ name: "email" }}
              inputProps={{ placeholder: "Enter email address" }}
              rules={{
                required: "This field is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email",
                },
              }}
            />

            <Row justify="end" gutter={16}>
              <Col>
                <IncredableButton
                  formAction="none"
                  onClick={() => {
                    methods.resetField("email");
                    closeModal();
                  }}
                >
                  Cancel
                </IncredableButton>
              </Col>
              <Col>
                <Button formAction="submit">Share</Button>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Card>
    </Modal>
  );
}
