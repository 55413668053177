export const traineeTypes = [
    { value: "Residency" },
    { value: "Fellowship" },
    { value: "Internship" },
    { value: "Research Fellowship" },
    { value: "Observership" },
    { value: "Clinical Fellowship" },
    { value: "Administrative Fellowship" },
    { value: "Visiting Trainee" },
    { value: "Pre-Residency Training" },
    { value: "Special Program" },
];
export const programs = [
    { value: "Internal Medicine" },
    { value: "General Surgery" },
    { value: "Pediatrics" },
    { value: "Family Medicine" },
    { value: "Psychiatry" },
    { value: "Obstetrics and Gynecology" },
    { value: "Anesthesiology" },
    { value: "Emergency Medicine" },
    { value: "Radiology (Diagnostic)" },
    { value: "Pathology" },
    { value: "Neurology" },
    { value: "Orthopedic Surgery" },
    { value: "Otolaryngology" },
    { value: "Dermatology" },
    { value: "Urology" },
    { value: "Physical Medicine and Rehabilitation" },
    { value: "Ophthalmology" },
    { value: "Plastic Surgery" },
    { value: "Radiation Oncology" },
    { value: "Nuclear Medicine" },
    { value: "Cardiology" },
    { value: "Gastroenterology" },
    { value: "Endocrinology" },
    { value: "Hematology/Oncology" },
    { value: "Pulmonary and Critical Care Medicine" },
    { value: "Nephrology" },
    { value: "Infectious Diseases" },
    { value: "Neonatology" },
    { value: "Pediatric Cardiology" },
    { value: "Maternal-Fetal Medicine" },
    { value: "Gynecologic Oncology" },
    { value: "Trauma Surgery" },
    { value: "Hand Surgery" },
    { value: "Surgical Oncology" },
    { value: "Interventional Radiology" },
    { value: "Pain Medicine" },
    { value: "Addiction Psychiatry" },
    { value: "Child and Adolescent Psychiatry" }
];
export const evaluationStatus = [
    { value: "Pending" },
    { value: "In Progress" },
    { value: "Completed" },
    { value: "Overdue" },
    { value: "Reviewed" }
];

export const trainingYear = [
    { value: "PGY-1" },
    { value: "PGY-2" },
    { value: "PGY-3" },
    { value: "PGY-4" },
    { value: "PGY-5 and Above" },
    { value: "Fellowship" },
    { value: "Alumni/Completed" }
]