import { useCallback, useEffect, useState } from "react";
import { Button, ConfigProvider, Typography } from "antd";
import {
  Header,
  Pagination,
  StatusIndicator,
  Table,
} from "@cloudscape-design/components";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";
import {
  useExportAllProvidersReportMutation,
  useLazyGetAllProvidersReportQuery,
} from "../redux/api/reporting/reporting";
import { DownloadOutlined } from "@ant-design/icons";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";
import useFileExport from "../hooks/useFileExport";

export default function ProviderReports() {
  const { taskId, setTaskId } = useFileExport("csv");

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "lastName", isDescending: false });
  const [exportAllProvidersReport] = useExportAllProvidersReportMutation();

  const [trigger, { data: providersReportsData, isLoading, isFetching }] =
    useLazyGetAllProvidersReportQuery({});

  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  useEffect(() => {
    debouncedTrigger({
      pageNumber,
      pageSize,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [pageNumber, pageSize, sortingDetails, debouncedTrigger]);

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "fullname",
            header: "Full Name",
            cell: (item) => `${item.lastName} , ${item.firstName}`,
            sortingField: "lastName",
            isRowHeader: true,
          },
          {
            id: "lastName",
            header: "Last Name",
            cell: (item) => item?.lastName,
            sortingField: "lastName",
          },
          {
            id: "firstName",
            header: "First Name",
            cell: (item) => item?.firstName,
            sortingField: "firstName",
          },
          {
            id: "npiNumber",
            header: "NPI Number",
            cell: (item) => item?.npiNumber,
            sortingField: "npiNumber",
          },
          {
            id: "email",
            header: "Email Address",
            cell: (item) => item?.email,
            sortingField: "email",
          },
          {
            id: "licenseType",
            header: "License Type",
            cell: (item) => item?.licenseType?.name,
            sortingField: "licenseType",
          },
          {
            id: "facility",
            header: "Facility",
            cell: (item) => item?.facility?.name,
            sortingField: "facility",
          },
          {
            id: "facilityGroup",
            header: "Facility Group",
            cell: (item) => item?.facilityGroup?.name,
            sortingField: "facilityGroup",
          },
          {
            id: "identificationNumber",
            header: "Identification Number",
            cell: (item) => item?.identificationNumber,
            sortingField: "identificationNumber",
          },
          {
            id: "status",
            header: <div>Status</div>,
            cell: (item) => (
              <StatusIndicator
                type={item?.isActive === true ? "success" : "stopped"}
                colorOverride={item?.isActive === true ? "green" : "red"}
              >
                {item?.isActive === true ? "Active" : "Inactive"}
              </StatusIndicator>
            ),
            sortingField: "status",
          },
        ]}
        items={!!providersReportsData ? providersReportsData?.providers : []}
        pagination={
          <Pagination
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!pageSize && pageSize !== 0
                ? Math.ceil(
                    !!providersReportsData
                      ? providersReportsData?.totalRecords / pageSize
                      : 0
                  )
                : 1
            }
          />
        }
        loadingText="Loading resources"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    loading={!!taskId}
                    onClick={() => {
                      const taskId = "AllProviders_Report_" + Date.now();
                      exportAllProvidersReport({
                        taskId: taskId,
                      });
                      setTaskId(taskId);
                    }}
                  >
                    Export CSV
                  </Button>
                </ConfigProvider>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Provider Reports{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${
                  providersReportsData?.totalRecords || 0
                })`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
      />
    </div>
  );
}
