import { Select, Space, Tag } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { debounce } from "lodash";
import { useLazyGetAllSignatureRequestsQuery } from "../redux/api/signaturerequest/signaturerequest";
import { useGetAllDigitalFormsQuery } from "../redux/api/digitalform/digitalforms";

export default function useSignatureFilter() {
  const [selectedFilterkey, setSelectedFilterKey] =
    useState<string>("provider");
  const [providerNameQueryFilterValue, setProviderNameQueryFilterValue] =
    useState<string | undefined>(undefined);
  const [statusNameQueryFilterValue, setStatusNameQueryFilterValue] =
    useState<string | undefined>(undefined);
  const [readyFormNameQueryFilterValue, setReadyFormNameQueryFilterValue] =
    useState<string | undefined>(undefined);

  const [
    trigger,
    { data: eSignatureResponse, isLoading: signatureLoading, isFetching },
  ] = useLazyGetAllSignatureRequestsQuery({});
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });
  const { data: providers, isLoading: providersLoading } =
    useGetBasicAllProvidersQuery();
  const { data: readyForms } = useGetAllDigitalFormsQuery();
  const status = [
    { value: "completed", label: "Completed" },
    { value: "voided", label: "Voided" },
    { value: "pending", label: "Pending" },
  ];
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  useEffect(() => {
    setPageNumber(0);
  }, [
    providerNameQueryFilterValue,
    statusNameQueryFilterValue,
    readyFormNameQueryFilterValue
  ]);
  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  const reload = () => {
    trigger({
      pageNumber,
      pageSize,
      providerId: providerNameQueryFilterValue,
      signatureRequestsStatus: statusNameQueryFilterValue,
      digitalFormId: readyFormNameQueryFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  };
  useEffect(() => {
    debouncedTrigger({
      pageNumber,
      pageSize,
      providerId: providerNameQueryFilterValue,
      signatureRequestsStatus: statusNameQueryFilterValue,
      digitalFormId: readyFormNameQueryFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [
    pageNumber,
    pageSize,
    providerNameQueryFilterValue,
    statusNameQueryFilterValue,
    readyFormNameQueryFilterValue,
    sortingDetails,
    debouncedTrigger,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterkey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "provider", label: "Provider" },
            { value: "status", label: "Status" },
            { value: "readyForm", label: "Ready Form" }
          ]}
        />
        {selectedFilterkey === "provider" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={providerNameQueryFilterValue}
            placeholder="Select Provider"
            onChange={(value) => setProviderNameQueryFilterValue(value)}
            style={{ width: "320px" }}
            options={providers?.map((provider) => ({
              label: `${provider?.lastName} ${provider?.firstName}`,
              value: provider?.id,
            }))}
          />
        )}

        {selectedFilterkey === "status" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={statusNameQueryFilterValue}
            placeholder="Select Status"
            onChange={(value) => setStatusNameQueryFilterValue(value)}
            style={{ width: "320px" }}
            options={status}
          />
        )}
        {selectedFilterkey === "readyForm" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={readyFormNameQueryFilterValue}
            placeholder="Select Ready Form"
            onChange={(value) => setReadyFormNameQueryFilterValue(value)}
            style={{ width: "320px" }}
            options={readyForms?.map((form) => ({
              label: form?.name,
              value: form?.id,
            }))}
          />
        )}

      </Space>
      <div>
        {!!providerNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setProviderNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Provider ={" "}
              {
                providers?.find(
                  (provider) =>
                    provider?.id + "" === providerNameQueryFilterValue + ""
                )?.firstName
              }
            </span>
          </Tag>
        )}
        {!!readyFormNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setReadyFormNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Ready Form ={" "}
              {
                readyForms?.find(
                  (form) =>
                    form?.id + "" === readyFormNameQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!statusNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setStatusNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Status ={" "}
              {
                status?.find(
                  (status) =>
                    status?.value + "" === statusNameQueryFilterValue + ""
                )?.value
              }
            </span>
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredEsignature: eSignatureResponse?.signatureRequests || [],
    eSignatureTotalRecords: eSignatureResponse?.totalRecords || 0,
    UseQueryHookResult: {
      reload,
      isLoading: signatureLoading || providersLoading,
      isFetching,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  };
}
