import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import IncredableButton from "../../../components/button";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { useAddCategoryMutation } from "../../../redux/api/category/category";
import { CategoryRequest } from "../../../redux/api/category/types";
import RHFCheckBox from "../../../components/RHF/RHFCheckbox";
import IncredableContent from "../../../components/incredable-content";

const { useToken } = theme;
type FormInputType = CategoryRequest;

export default function AddCategory() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const [addCategory] = useAddCategoryMutation();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <IncredableContent
      breadcrumbGroupProps={{
        items: [
          {
            text: "Categories",
            href: "/categories",
          },
          {
            text: "Add",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addcategory"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            await addCategory({
              name: data?.name,
              description: data?.description,
              stateRequired: data?.stateRequired,
              expirationRequired: data?.expirationRequired,
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Category saved successfully`,
              placement: "topRight",
            });
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Category
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="category" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Description",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `description`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFCheckBox
                            formItemProps={{ label: "" }}
                            controllerProps={{ name: "stateRequired" }}
                            checkboxProps={{ label: "State Required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFCheckBox
                            formItemProps={{ label: "" }}
                            controllerProps={{ name: "expirationRequired" }}
                            checkboxProps={{ label: "Expiration Required" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
