import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { USStatesList, Countries } from "../../../common/constants";
import IncredableButton from "../../../components/button";
import IncredableContent from "../../../components/incredable-content";
import RHFSelect from "../../../components/RHF/RHFSelect";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import { LocationRequest } from "../../../redux/api/location/types";
import {
  useGetLocationQuery,
  useUpdateLocationMutation,
} from "../../../redux/api/location/location";
import AssoicatedLocationList from "./associated-location-list";
import { Facility } from "../../../redux/api/facility/types";

const { useToken } = theme;

type FormInputType = LocationRequest;

export default function EditLocation() {
  const methods = useForm<FormInputType>();

  const { locationId } = useParams();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const navigate = useNavigate();
  const { token } = useToken();

  const [editLocation] = useUpdateLocationMutation();
  const { data: locationData, isLoading } = useGetLocationQuery(
    !!locationId ? { id: locationId } : skipToken
  );

  const [associatedFacility, setAssociatedFacility] = useState<Facility[]>([]);

  useEffect(() => {
    if (locationData) {
      methods.setValue("name", locationData?.name ?? "");
      methods.setValue("description", locationData?.description ?? "");
      methods.setValue("addressLine1", locationData?.addressLine1 ?? "");
      methods.setValue("addressLine2", locationData?.addressLine2 ?? "");
      methods.setValue("city", locationData?.city ?? "");
      methods.setValue("state", locationData?.state ?? "");
      methods.setValue("country", locationData?.country ?? "");
      methods.setValue("zip", locationData?.zip ?? "");
      methods.setValue("notes", locationData?.notes ?? "");

      if (!!locationData?.facilities)
        setAssociatedFacility(locationData?.facilities);
    }
  }, [methods, locationData]);

  return (
    <>
      <IncredableContent
        spinning={isLoading}
        breadcrumbGroupProps={{
          items: [
            {
              text: "Location",
              href: "/location",
            },
            {
              text: "Edit",
              href: "#",
            },
          ],
        }}
      >
        <FormProvider {...methods}>
          <Form
            name="editLocation"
            layout="vertical"
            onFinish={methods.handleSubmit(async (data: FormInputType) => {
              if (!!locationId) {
                await editLocation({
                  id: locationId + "",
                  ...data,
                });
              }
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Location updated successfully`,
                placement: "topRight",
              });
              navigate(-1);
            })}
            autoComplete="off"
          >
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Edit Location
              </Typography.Text>
              <Space>
                <IncredableButton secondary onClick={() => navigate(-1)}>
                  Cancel
                </IncredableButton>
                <IncredableButton type="primary" htmlType="submit">
                  Save
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Form.List name="editLocation" initialValue={[{}]}>
              {(fields, { add }) => {
                return (
                  <Space>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div key={key}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "name",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Description",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "description",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Address Line 1",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "addressLine1",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Address Line 2",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "addressLine2",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "City",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "city",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "State" }}
                              controllerProps={{
                                name: `state`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select state",
                                filterOption: (input, option) =>
                                  (option?.value ?? '').toString().toLowerCase().includes(input?.toLowerCase())
                                ,
                                options: USStatesList?.map((state) => {
                                  return {
                                    label: state?.value,
                                    value: state?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Country" }}
                              controllerProps={{
                                name: `country`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select country",
                                filterOption: (input, option) =>
                                  (option?.value ?? '').toString().toLowerCase().includes(input?.toLowerCase())
                                ,
                                options: Countries?.map((country) => {
                                  return {
                                    label: country?.value,
                                    value: country?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Zip",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "zip",
                              }}
                            />
                          </Col>

                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Notes",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "notes",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Space>
                );
              }}
            </Form.List>
          </Form>
        </FormProvider>
      </IncredableContent>
      <IncredableContent >
        <AssoicatedLocationList
          associatedFacility={associatedFacility}
          setAssociatedFacility={setAssociatedFacility}
        />
      </IncredableContent>
    </>
  );
}
