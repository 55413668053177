import { useContext, useEffect, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Tag, Typography } from "antd";
import { Provider } from "../redux/api/provider/types";
import {
  Header,
  Pagination,
  StatusIndicator,
  Table,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  CheckCircleOutlined,
  DownOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import useProviderFilter from "../table-filters/provider-filters";
import { useLocalStorage } from "../common/localStorage";
import {
  PROVIDER_CONTENT_DISPLAY_OPTIONS,
  PROVIDER_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import {
  useActiveProviderMutation,
  useInActiveAllProviderMutation,
} from "../redux/api/provider/provider";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { isEmpty } from "lodash";
import IncredableButton from "../components/button";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";

export const columns = [
  {
    title: () => <Typography.Text>Profile</Typography.Text>,
    key: "profile",
    width: "200px",
    ellipsis: true,
    render: (provider: Provider) => (
      <Typography.Link href={`manageprovider/${provider?.id}`}>
        {provider?.firstName
          ? `${provider.firstName} ${provider.lastName}`
          : "-"}
      </Typography.Link>
    ),
    sorter: (a: Provider, b: Provider) =>
      a?.firstName?.toLowerCase()?.localeCompare(b?.firstName?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Last Name</Typography.Text>,
    dataIndex: "lastName",
    key: "lastName",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>First Name</Typography.Text>,
    dataIndex: "firstName",
    key: "firstName",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>NPI Number</Typography.Text>,
    dataIndex: "npiNumber",
    key: "npiNumber",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Email Address</Typography.Text>,
    dataIndex: "email",
    key: "emailAddress",
    width: "250px",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>License Type</Typography.Text>,
    dataIndex: ["licenseType", "name"],
    key: "licenseType",
    width: "230px",
    ellipsis: true,
    render: (text: string) => (
      <Tag>
        <Typography.Text>{text || "-"}</Typography.Text>
      </Tag>
    ),
  },
  {
    title: () => <Typography.Text>Facility Name</Typography.Text>,
    dataIndex: ["facility", "name"],
    key: "facilityName",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function ProviderListV3() {
  const [selectedRows, setSelectedRows] = useState<Provider[]>();

  const {
    TableFilters,
    filteredProviders = [],
    providerTotalRecords,
    UseQueryHookResult: {
      reload,
      isFetching,
      pageNumber,
      setPageNumber,
      pageSize,
      statusQueryFilterValue,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  } = useProviderFilter();
  const [activeProvider] = useActiveProviderMutation();
  const [deActiveAllProvider] = useInActiveAllProviderMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Provider-Table-Preferences",
    PROVIDER_TABLE_DEFAULT_PREFERENCES
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (tablePreferences?.pageSize) setPageSize(tablePreferences?.pageSize);
  }, [setPageSize, tablePreferences?.pageSize]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredProviders,
    {
      pagination: { pageSize: pageSize },
    }
  );

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "fullname",
            header: "Full Name",
            cell: (item) => (
              <NavLink
                to={`${item.id}`}
              >{`${item.lastName}, ${item.firstName}`}</NavLink>
            ),
            sortingField: "lastName",
            isRowHeader: true,
          },
          {
            id: "lastName",
            header: "Last Name",
            cell: (item) => item?.lastName,
            sortingField: "lastName",
          },
          {
            id: "firstName",
            header: "First Name",
            cell: (item) => item?.firstName,
            sortingField: "firstName",
          },
          {
            id: "npiNumber",
            header: "NPI Number",
            cell: (item) => item?.npiNumber,
            sortingField: "npiNumber",
          },
          {
            id: "email",
            header: "Email Address",
            cell: (item) => item?.email,
            sortingField: "email",
          },
          {
            id: "licenseType",
            header: "License Type",
            cell: (item) => item?.licenseType?.name,
            sortingField: "licenseType.id",
          },
          {
            id: "facility",
            header: "Facility",
            cell: (item) => item?.facility?.name,
            sortingField: "facility.name",
          },
          {
            id: "facilityGroup",
            header: "Facility Group",
            cell: (item) => item?.facilityGroup?.name,
            sortingField: "facilityGroup.name",
          },
          {
            id: "status",
            header: <div>Status</div>,
            cell: (item) => (
              <StatusIndicator
                type={item?.isActive === true ? "success" : "stopped"}
                colorOverride={item?.isActive === true ? "green" : "red"}
              >
                {item?.isActive === true ? "Active" : "Inactive"}
              </StatusIndicator>
            ),
          },
        ]}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!tablePreferences?.pageSize && tablePreferences?.pageSize !== 0
                ? Math.ceil(providerTotalRecords / tablePreferences?.pageSize)
                : 1
            }
          />
        }
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              if (preferences.pageSize !== tablePreferences.pageSize) {
                preferences.pageSize && setPageSize(preferences.pageSize);
              }
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={PROVIDER_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        !!reload && reload();
                      }}
                    >
                      <ReloadOutlined />
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(`/safe?providerId=${selectedRows?.at(0)?.id}`);
                      }}
                      disabled={!selectedRows || selectedRows?.length !== 1}
                      iconPosition={"end"}
                    >
                      Go To Safe
                    </Button>
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: hasPermission ? "Edit" : "View",
                            key: "edit",
                            disabled: selectedRows?.length !== 1,
                            icon: <EditOutlined />,
                            onClick: () => {
                              navigate(`${selectedRows?.at(0)?.id}`);
                            },
                          },
                          {
                            label:
                              statusQueryFilterValue === "inactive"
                                ? "Active"
                                : "InActive",
                            key:
                              statusQueryFilterValue === "inactive"
                                ? "active"
                                : "inactive",
                            icon:
                              statusQueryFilterValue === "inactive" ? (
                                <CheckCircleOutlined />
                              ) : (
                                <MinusCircleOutlined />
                              ),
                            danger:
                              statusQueryFilterValue === "inactive"
                                ? false
                                : true,
                            disabled: !hasDeletePermission &&
                              statusQueryFilterValue === "inactive"
                              ? selectedRows?.length !== 1
                              : isEmpty(selectedRows)
                                ? true
                                : false,
                          },
                        ],
                        onClick: async (e) => {
                          if (e?.key === "inactive") {
                            spinnerContext?.hidespinner();
                            if (selectedRows) {
                              const data = selectedRows?.map((row) =>
                                Number(row.id)
                              );
                              await deActiveAllProvider({ data });
                            }
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `Provider Inactive successfully`,
                              placement: "topRight",
                            });
                            setSelectedRows(undefined);
                          }
                          if (e?.key === "active") {
                            spinnerContext?.hidespinner();

                            await activeProvider({
                              providerId: String(selectedRows?.at(0)?.id),
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `Provider Active successfully`,
                              placement: "topRight",
                            });
                            setSelectedRows(undefined);
                          }
                        },
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <IncredableButton
                      onClick={() => navigate("add")}
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{ backgroundColor: "#598BEB" }}
                    >
                      Add Provider
                    </IncredableButton>
                  </Space>
                </ConfigProvider>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Providers{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${providerTotalRecords})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
    </div>
  );
}