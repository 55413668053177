import { Layout, Space } from "antd";
import { IncredableSpin } from "../context/spinner/spinner";
import { CSSProperties, ReactNode } from "react";
import { BreadcrumbGroupProps } from "@cloudscape-design/components";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
const IncredableContent = ({
  spinning,
  children,
  style,
  breadcrumbGroupProps,
  breadcrumbContainerStyle,
}: {
  spinning?: boolean;
  children: ReactNode;
  style?: CSSProperties | undefined;
  breadcrumbGroupProps?: BreadcrumbGroupProps | any;
  breadcrumbContainerStyle?: CSSProperties | undefined;
}) => (
  <Space style={{ display: "block" }} direction="vertical">
    <div
      style={{
        margin: "20px 30px 0",
        ...breadcrumbContainerStyle,
      }}
    >
      {!!breadcrumbGroupProps && <BreadcrumbGroup {...breadcrumbGroupProps} />}
    </div>
    <Layout.Content
      style={{
        margin: "20px 30px 0",
        padding: "32px",
        backgroundColor: "white",
        boxShadow: "0px 10px 25px 0px #5911920D",
        borderRadius: "16px",
        border: "1px solid #E5E7EB",
        overflow: "hidden",
        ...style,
      }}
    >
      <IncredableSpin spinning={!!spinning} />
      {children}
    </Layout.Content>
  </Space>
);
export default IncredableContent;
