import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import IncredableButton from "../../../components/button";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { skipToken } from "@reduxjs/toolkit/query";
import { FacilityGroupRequest } from "../../../redux/api/facilitygroup/types";
import {
  useGetFacilityGroupQuery,
  useUpdateFacilityGroupMutation,
} from "../../../redux/api/facilitygroup/facilityGroup";
import IncredableContent from "../../../components/incredable-content";
import { Facility } from "../../../redux/api/facility/types";
import AssoicatedFacilityList from "./associated-facility-list";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";

const { useToken } = theme;
type FormInputType = FacilityGroupRequest;

export default function EditFacilityGroup() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const [associatedFacilities, setAssociatedFacilities] = useState<Facility[]>(
    []
  );
  const { facilitygroupId } = useParams();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: facilitygroup } = useGetFacilityGroupQuery(
    !!facilitygroupId ? { id: facilitygroupId } : skipToken
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.edit") ?? false)
  );
  useEffect(() => {
    if (facilitygroup) {
      methods.setValue("name", facilitygroup?.name ?? "");
      methods.setValue("description", facilitygroup?.description ?? "");
    }
  }, [methods, facilitygroup]);

  const [updateFacilityGroup] = useUpdateFacilityGroupMutation();

  return (
    <>
      <IncredableContent
        breadcrumbGroupProps={{
          items: [
            {
              text: "Facility Group",
              href: "/facilitygroup",
            },
            {
              text: "Edit",
              href: "#",
            },
          ],
        }}
      >
        <FormProvider {...methods}>
          <Form
            name="editfacilitygroup"
            layout="vertical"
            onFinish={methods.handleSubmit(async (data: FormInputType) => {
              if (!!facilitygroupId)
                await updateFacilityGroup({
                  id: facilitygroupId,
                  name: data?.name,
                  description: data?.description,
                });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Facility Group saved successfully`,
                placement: "topRight",
              });
              navigate(-1);
            })}
            autoComplete="off"
          >
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Facility Group
              </Typography.Text>
              <Space>
                <IncredableButton
                  secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </IncredableButton>
                <IncredableButton
                  disabled={!hasPermission}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Form.List name="facilitygroup" initialValue={[{}]}>
              {(fields, { add }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div key={key}>
                        {index > 0 && <Divider />}
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `name`,
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>

                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Description",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `description`,
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Form>
        </FormProvider>
      </IncredableContent>
      <IncredableContent style={{ margin: "20px 30px 0" }}>
        <AssoicatedFacilityList
          associatedFacilities={associatedFacilities}
          setAssociatedFacilities={setAssociatedFacilities}
        />
      </IncredableContent>
    </>
  );
}
