import {
  Card,
  Col,
  Popover,
  Row,
  Space,
  Statistic,
  Typography,
  Descriptions,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Images } from "../../common/images";
import ProviderCard from "../../components/providerCard/provider-card";
import {
  useDocumentstatsQuery,
  useGetExclusionsQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";

import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import { Link } from "@cloudscape-design/components";

export function Overview() {
  const { providerId } = useParams();

  const { data: selectedProvider } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );

  const navigate = useNavigate();

  const generalInfo = [
    { label: "First Name", value: selectedProvider?.firstName || "N/A" },
    { label: "Last Name", value: selectedProvider?.lastName || "N/A" },
    { label: "Email", value: selectedProvider?.email || "N/A" },
    { label: "NPI Number", value: selectedProvider?.npiNumber || "N/A" },
    { label: "Facility Group", value: selectedProvider?.facilityGroup?.name || "N/A" },
    { label: "Facility", value: selectedProvider?.facility?.name || "N/A" },
    {
      label: "License Type",
      value: selectedProvider?.licenseType?.name || "N/A",
    },
  ];

  return (
    <>
      <ProviderCard
        title={<span style={{ fontSize: "18px" }}>General Information</span>}
        extraButton={{
          text: "Edit",
          icon: Images.edit2,
          onClick: () => {
            if (!!providerId) navigate(`/manageprovider/${providerId}/edit`);
          },
        }}
        data={generalInfo}
      />
    </>
  );
}

export function ProviderDocumentSummary() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: providerDocumentsStats } = useDocumentstatsQuery(
    !!providerId ? { providerID: providerId } : skipToken
  );

  return (
    <Card
      title={
        <Typography.Title
          level={3}
          style={{ marginTop: "0", fontSize: "19px" }}
        >
          Documents Summary
        </Typography.Title>
      }
      extra={
        <IncredableButton type="primary">
          <a href="#safe"> Safe</a>
        </IncredableButton>
      }
    >
      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={true}>
            <Statistic
              title="Total count"
              value={providerDocumentsStats?.totalDocument ?? "0"}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={true}>
            <Statistic
              title={
                <Space>
                  Expiring count
                  <Popover
                    content={
                      "Documents with expiration dates within the next 90 days are considered expiring documents."
                    }
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </Space>
              }
              value={providerDocumentsStats?.expiringDocument ?? "0"}
              valueStyle={{ color: "#faad14" }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={true}>
            <Statistic
              title="Expired count"
              value={providerDocumentsStats?.expiredDocument ?? "0"}
              valueStyle={{ color: "#cf1322" }}
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
}

interface ExclusionProps {
  npi_number: string;
}
export const NoExlusions = () => {
  return (
    <Card
      title={
        <Typography.Title
          level={3}
          style={{ marginTop: "0", fontSize: "19px" }}
        >
          Exclusions
        </Typography.Title>
      }
      extra={<IncredableButton type="primary">Scan</IncredableButton>}
    >
      <Row gutter={16}>
        <Col span={8}>
          <i>No exclusions found.</i>
        </Col>
      </Row>
    </Card>
  );
};

function Exclusions({ npi_number }: ExclusionProps) {
  const { data, isLoading } = useGetExclusionsQuery({ npi_number: npi_number });
  if (data && data?.length > 0)
    return (
      <Card
        title={
          <Typography.Title
            level={3}
            style={{ marginTop: "0", fontSize: "19px" }}
          >
            Exclusions
          </Typography.Title>
        }
      >
        {data.map((exclusion) => (
          <Descriptions bordered layout="vertical">
            <Descriptions.Item label="Exclusion Name">
              {exclusion?.name}
              <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href={exclusion.link}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Exclusion Date">
              {exclusion?.exclusionDate}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </Card>
    );
  else return <NoExlusions key={npi_number} />;
}

export default Exclusions;
