import {
  useFormContext,
  Controller,
  UseControllerProps,
  RegisterOptions,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { Rifm } from "rifm";
import {
  Form,
  FormItemProps,
  Input,
  InputProps,
  Space,
  Tag,
  Typography,
} from "antd";
import { PlusCircleFilled, StarFilled, StarOutlined } from "@ant-design/icons";

// ----------------------------------------------------------------------

type RFimProps = {
  accept?: RegExp;
  format?: (v: string) => string;
  replace?: (str: string) => string;
};

type Props = {
  formItemProps: FormItemProps;
  inputProps: InputProps;
  readonly?: boolean;
  rfim?: RFimProps;
  controllerProps: UseControllerProps;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
};

export default function RHFTextField({
  formItemProps,
  inputProps,
  readonly,
  rfim,
  controllerProps,
  rules,
}: Props) {
  const { control } = useFormContext();
  const { Text } = Typography;
  return (
    <Controller
      name={controllerProps.name}
      control={control}
      defaultValue={controllerProps.defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Form.Item
            {...formItemProps}
            label={
              <span style={{ fontWeight: "600" }}>
                {formItemProps?.label}{" "}
                {!!rules?.required ? <Text type="danger">*</Text> : <></>}
              </span>
            }
            // required={!!rules?.required}
            validateStatus={!!fieldState.error ? "error" : ""}
            help={!!fieldState.error && getErrorMessage(fieldState.error)}
          >
            <Rifm
              accept={rfim?.accept}
              format={rfim?.format ?? defaultFormat}
              replace={rfim?.replace}
              value={field.value}
              onChange={(str) => {
                field.onChange(str);
              }}
            >
              {({ value: rifm_value, onChange }) => {
                if (inputProps.type === "password") {
                  return (
                    <Input.Password
                      style={{
                        borderRadius: "6px",
                        boxShadow: "0px 1px 2px 0px #1018280A",
                      }}
                      {...inputProps}
                      value={rifm_value}
                      onChange={(changeDetail) => {
                        field.onChange(changeDetail.target.value);
                      }}
                    />
                  );
                } else {
                  return (
                    <Input
                      style={{
                        borderRadius: "6px",
                        boxShadow: "0px 1px 2px 0px #1018280A",
                      }}
                      readOnly={readonly}
                      {...inputProps}
                      value={rifm_value}
                      onChange={(changeDetail) => {
                        field.onChange(changeDetail.target.value);
                      }}
                    />
                  );
                }
              }}
            </Rifm>
          </Form.Item>
        );
      }}
      rules={{ maxLength: 256, ...rules }}
    />
  );
}

const getErrorMessage = (error?: FieldError | undefined) => {
  console.log({ error });
  if (!error) return;
  if (error.message && error.message.length > 0) return error.message;
  if (error.type === "maxLength") return "Value is too long";
  return "Invalid value";
};

const defaultFormat = (v: string) => v;
