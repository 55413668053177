import {
  Col,
  Divider,
  Tabs as AntTabs,
  Form,
  Row,
  theme,
  ConfigProvider,
  Card,
  Modal,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderEducationRequestType } from "../../redux/api/provider/types";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useAddProviderEducationMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import {
  alertReminderOptions,
  Countries,
  USStatesList,
} from "../../common/constants";
import StickyFooter from "../../components/StickyFooter";
import { DocumentRequest } from "../../redux/api/document/types";
import ProviderDocumentDetails from "../provider-document-details";
import {
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/browser";

export default function EditProviderEducation() {
  const { providerId, educationId } = useParams();
  const methods = useForm<ProviderEducationRequestType>();
  const documentMethods = useForm<DocumentRequest & { documentId: string }>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: degree } = useGetAllDegreeQuery();

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && provider.providerEducations) {
      const education = provider?.providerEducations?.find(
        (education) => education?.id + "" === educationId + ""
      );
      if (!!education) {
        methods.setValue("id", education.id);
        methods.setValue("type", education.type);
        methods.setValue("name", education.name);
        methods.setValue("startDate", education.startDate);
        methods.setValue("endDate", education.endDate);
        methods.setValue("isCurrent", education.isCurrent);
        education.degree?.id &&
          methods.setValue("degreeId", education.degree?.id);
        methods.setValue("email", education.email);
        methods.setValue("phone", education.phone);
        methods.setValue("fax", education.fax);
        methods.setValue("addressLane1", education.addressLane1);
        methods.setValue("addressLane2", education.addressLane2);
        methods.setValue("city", education.city);
        methods.setValue("state", education.state);
        methods.setValue("zip", education.zip);
        methods.setValue("country", education.country);
        methods.setValue("residencyDirector", education.residencyDirector);
        methods.setValue("fellowshipDirector", education.fellowshipDirector);
        if (!!education?.document) {
          documentMethods.setValue("documentId", education?.document?.id);
          documentMethods.setValue("name", education?.document?.name);
          documentMethods.setValue(
            "categoryId",
            education?.document?.category?.id
          );
          documentMethods.setValue("notes", education?.document?.notes);
          documentMethods.setValue(
            "expirationDate",
            education?.document?.expirationDate
          );
          documentMethods.setValue("alertDays", education?.document?.alertDays);
        }
      }
    }
  }, [provider, educationId]);

  const [updateProviderEducation] = useAddProviderEducationMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [saveDocument] = useSaveDocumentMutation();

  return (
    <IncredableContent
      style={{ position: "relative", paddingTop: "10px" }}
      spinning={isLoading || isFetching}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: `${provider?.firstName || ""} ${provider?.lastName || ""}`,
            href: `/manageprovider/${providerId}`,
          },
          {
            text: educationId ? "Edit Education" : "Add Education",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="education"
            size="large"
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    {educationId ? "Edit Education" : "Add Education"}
                  </span>
                ),
                key: "education",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                      name="editprovidereducation"
                      layout="vertical"
                      onFinish={methods.handleSubmit(
                        async (data: ProviderEducationRequestType) => {
                          if (!!providerId) {
                            spinnerContext?.showSpinner();
                            await updateProviderEducation({
                              ...data,
                              providerId: providerId,
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `Education saved successfully`,
                              placement: "topRight",
                            });
                          }
                          navigate(-1);
                        }
                      )}
                      autoComplete="off"
                    >
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}
                      >
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Type" }}
                            controllerProps={{
                              name: `type`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select type",
                              options: [
                                "Undergraduate",
                                "Graduate",
                                "Medical school",
                                "Internship",
                                "Residency",
                                "Fellowship",
                              ].map((option) => ({
                                value: option,
                              })),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Start Date",
                            }}
                            controllerProps={{
                              name: `startDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "End Date",
                            }}
                            controllerProps={{
                              name: `endDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Degree" }}
                            controllerProps={{
                              name: `degreeId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select degree",
                              options: degree?.map((degree) => {
                                return {
                                  label: degree?.name,
                                  value: degree?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Residency Director",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "residencyDirector",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Fellowship Director",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "fellowshipDirector",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider orientation="left">Contact Info</Divider>
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}
                      >
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "email",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "phone",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Fax",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "fax",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}
                      >
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Line 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane1",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Line 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane2",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "city",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              filterOption: (input, option) =>
                                (option?.value ?? '').toString().toLowerCase().includes(input?.toLowerCase())
                              ,
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Country" }}
                            controllerProps={{
                              name: `country`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select country",
                              filterOption: (input, option) =>
                                (option?.value ?? '').toString().toLowerCase().includes(input?.toLowerCase())
                              ,
                              options: Countries?.map((country) => {
                                return {
                                  value: country?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "zip",
                            }}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Document</span>,
                key: "document",
                children: (
                  <FormProvider {...documentMethods}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Form: {
                            verticalLabelMargin: -4,
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "30px",
                        }}
                        onFinish={documentMethods.handleSubmit(
                          async (data: DocumentRequest) => {
                            console.log("document methods");
                          }
                        )}
                        layout="vertical"
                        size="middle"
                        autoComplete="off"
                      >
                        <ProviderDocumentDetails
                          uploadedFile={uploadedFile}
                          setUploadedFile={setUploadedFile}
                        />
                      </Form>
                    </ConfigProvider>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Alert</span>,
                key: "alert",
                children: (
                  <FormProvider {...documentMethods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "30px",
                      }}
                      layout="vertical"
                      size="middle"
                      autoComplete="off"
                    >
                      <Card>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={12}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Expiration Date",
                                tooltip:
                                  "Re-Enrollment Date is set as the default expiration date. You may modify it below if needed.",
                              }}
                              controllerProps={{
                                name: "expirationDate",
                              }}
                            />
                          </Col>
                          <Col span={12}>
                            <RHFSelect
                              formItemProps={{
                                label: "Expiration Alert Reminder",
                              }}
                              controllerProps={{ name: "alertDays" }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select Expiration Reminder",
                                options: alertReminderOptions?.map((alert) => {
                                  return {
                                    label: alert?.label,
                                    value: alert?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Form>
                  </FormProvider>
                ),
              },
            ]}
          />
          <StickyFooter
            hasPermission={true}
            onSubmit={async () => {
              const hasDocumentValues =
                documentMethods.getValues("alertDays") ||
                documentMethods.getValues("expirationDate") ||
                documentMethods.getValues("name") ||
                documentMethods.getValues("file");

              const handleProviderEducationSubmit = async (
                documentId?: string
              ): Promise<void> => {
                methods.handleSubmit(
                  async (data: ProviderEducationRequestType) => {
                    if (!!providerId) {
                      spinnerContext?.showSpinner();
                      await updateProviderEducation({
                        ...data,
                        providerId: providerId,
                        ...(documentId && { documentId }),
                      });
                      spinnerContext?.hidespinner();
                      toastContext?.openSuccessNotification({
                        message: "Education saved successfully",
                        placement: "topRight",
                      });
                      navigate(-1);
                    }
                  }
                )();
              };

              const handleFileUpload = async (
                key: string,
                file?: File
              ): Promise<void> => {
                if (!!file && key) {
                  try {
                    await fileAxios.put(getFileUrl(key), file);
                    toastContext?.openSuccessNotification({
                      message: `Document saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                }
              };

              if (!hasDocumentValues) {
                handleProviderEducationSubmit();
              } else {
                const documentId = documentMethods.getValues("documentId");

                const handleDocumentUpdate = async (
                  data: DocumentRequest & { documentId: string }
                ): Promise<void> => {
                  try {
                    const result = await updateDocument({
                      documentId: data.documentId,
                      isVersioned: false,
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderEducationSubmit(result?.id);
                    await handleFileUpload(
                      result?.attachment?.key,
                      uploadedFile
                    );
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: `Document updated successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    spinnerContext?.hidespinner();
                    toastContext?.openErrorNotification({
                      message: `Document update failed`,
                      placement: "topRight",
                    });
                  }
                };

                const handleDocumentSave = async (
                  data: DocumentRequest
                ): Promise<void> => {
                  try {
                    const documentResponse = await saveDocument({
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderEducationSubmit(documentResponse?.id);
                    await handleFileUpload(
                      documentResponse?.attachment?.key,
                      uploadedFile
                    );
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                };

                documentMethods.handleSubmit(
                  documentId ? handleDocumentUpdate : handleDocumentSave
                )();
              }
            }}
            onCancel={() => {
              navigate(-1);
            }}
          />
        </div>
      </ConfigProvider>
      <Modal
        title="Education verification Status"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        Education number <b>AA1019872</b> verified successfully !!
      </Modal>
    </IncredableContent>
  );
}
