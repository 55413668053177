import { useContext, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { ColumnLayout, Link } from "@cloudscape-design/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useIsLoading from "../../hooks/useIsLoading";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./signup.css";
import { Button, Col, Row, Image, Typography, theme, Form, Spin } from "antd";
import { loginContent, loginText, loginTitle } from "../login/login.styles";
import { ToastContext } from "../../context/toast/incredable-toast";

type FormInputType = {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  agree: boolean;
  role: string;
  email: string;
};

const { Title, Text } = Typography;
const { useToken } = theme;

function SignUpForm() {
  const { t } = useTranslation();
  const { token } = useToken();
  const { register } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>();
  const toastContext = useContext(ToastContext);

  const [isAllValid, setIsAllValid] = useState(false);

  const methods = useForm<FormInputType>();
  useIsLoading(isLoading);

  useEffect(() => {
    if (!!searchParams.get("token")) {
      const email = searchParams.get("email");
      const role = searchParams.get("role");
      setIsLoading(true);
      if (!!role) {
        methods.setValue("role", role);
      }
      if (!!email) {
        methods.setValue("email", email);
      }
      setIsLoading(false);
    }
  }, [!!searchParams.get("token")]);

  const onSubmit = async (data: FormInputType) => {
    const invite_hash = searchParams.get("token");
    setIsLoading(true);
    if (invite_hash) {
      try {
        register({
          queryparams: searchParams.toString(),
          body: {
            password: data.password,
            frist_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            roleId: searchParams.get("roleId") ?? "",
            isOwner: searchParams.get("isOwner") === "true",
            invite_hash: invite_hash,
          },
        })
          .then((result) => {
            navigate("/");
          })
          .catch((error) => {
            setError(error?.response?.data?.message ?? "Error: ");
            toastContext?.openWarningNotification({
              message: error?.response?.data?.message,
              placement: "top",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        setError("Error: ");
        setIsLoading(false);
      }
    }
  };
  const validationRules = [
    { label: "One lowercase character", regex: /[a-z]/ },
    { label: "One uppercase character", regex: /[A-Z]/ },
    { label: "One number", regex: /\d/ },
    { label: "One special character", regex: /[@$!%*?&]/ },
    { label: "8 characters minimum", regex: /.{8,}/ },
  ];
  const isValid = methods?.watch("password");

  const validateRule = (rule: { label: string; regex: RegExp }) =>
    rule.regex.test(isValid || "");

  useEffect(() => {
    const allRulesValid = validationRules.every(validateRule);
    setIsAllValid(allRulesValid);
    methods?.setValue("confirmPassword", "");
  }, [isValid]);

  return (
    <>
      <Spin fullscreen spinning={isLoading}></Spin>
      <Row>
        <Col xs={0} md={12} lg={12}>
          <Image
            height={"100vh"}
            width={"100%"}
            preview={false}
            src="/assets/Login_Bg_image.png"
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div style={loginContent}>
            <Title style={{ ...loginTitle, color: token.colorPrimary }}>
              Signup
            </Title>
            <Text style={loginText}>
              Welcome to Incredable™, Your gateway to seamless document
              management awaits.
            </Text>
            <FormProvider {...methods}>
              <Form
                layout="vertical"
                name="login"
                style={{ marginTop: "24px" }}
                initialValues={{ remember: true }}
                onFinish={methods.handleSubmit(onSubmit)}
              >
                <ColumnLayout columns={2}>
                  <RHFTextField
                    formItemProps={{ label: "First name" }}
                    controllerProps={{ name: "firstName" }}
                    inputProps={{
                      placeholder: "Enter your first name",
                    }}
                    rules={{ required: "This field is required" }}
                  />

                  <RHFTextField
                    formItemProps={{ label: "Last name" }}
                    controllerProps={{ name: "lastName" }}
                    inputProps={{
                      placeholder: "Enter your last name",
                    }}
                    rules={{ required: "This field is required" }}
                  />
                </ColumnLayout>

                {/*    <RHFTextField
                  formItemProps={{ label: "Role" }}
                  controllerProps={{ name: "role" }}
                  inputProps={{
                    readOnly: true,
                  }}
                /> */}

                <RHFTextField
                  formItemProps={{ label: "Email address" }}
                  controllerProps={{ name: "email" }}
                  rules={{ required: "This field is required" }}
                  inputProps={{
                    readOnly: true,
                  }}
                />

                <div style={{ width: "100%", margin: "20px auto" }}>
                  <RHFTextField
                    formItemProps={{ label: "Password" }}
                    controllerProps={{ name: "password" }}
                    rules={{ required: "This field is required" }}
                    inputProps={{
                      type: "password",
                      placeholder: "Enter your password",
                    }}
                  />
                  <ul className={isValid ? "validation-ul" : "none-ul"}>
                    {validationRules.map((rule, index) => (
                      <li
                        className="validation-li"
                        key={index}
                        style={{
                          color: validateRule(rule)
                            ? "rgb(82, 18, 98)"
                            : "gray",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            display: "inline-block",
                            width: "18px",
                            height: "18px",
                            borderRadius: "50%",
                            color: validateRule(rule)
                              ? "rgb(82, 18, 98)"
                              : "#bbb",
                            textAlign: "center",
                            marginBottom: "5px",
                          }}
                        >
                          {"✔"}
                        </span>
                        {rule.label}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div style={{ marginTop: "32px" }}>
                    <Checkbox
                      onChange={({ detail }) => setShowPassword(detail.checked)}
                      checked={showPassword}
                    >
                      Show password
                    </Checkbox>
                  </div> */}

                <RHFTextField
                  formItemProps={{ label: "Confirm Password" }}
                  controllerProps={{ name: "confirmPassword" }}
                  inputProps={{
                    type: "password",
                    placeholder: "Enter your password",
                  }}
                  rules={{
                    required: "This field is required",
                    validate: (value: any, formValues: FieldValues) => {
                      return (
                        value === (formValues.password ?? "") ||
                        "Password and Confirm Password not match"
                      );
                    },
                  }}
                />
                {/* <div style={{ marginTop: "-10px", paddingTop: "-10px" }}>
                  <RHFCheckBox
                    formItemProps={{
                      style: { marginTop: "-10px", paddingTop: "-10px" },
                      label: "",
                    }}
                    checkboxProps={{
                      style: { marginTop: "-10px", paddingTop: "-10px" },
                      label: (
                        <span>
                          I agree to Incredable{" "}
                          <a href="#"> Terms of Service</a> and{" "}
                          <a href="#">Privacy Policy</a>.
                        </span>
                      ),
                    }}
                    controllerProps={{ name: "agree" }}
                  />
                </div> */}
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{
                    borderRadius: "6px",
                    padding: "14px 18px",
                    backgroundColor:
                      !isAllValid || methods?.watch("confirmPassword") == ""
                        ? "gray"
                        : "#521262",
                    boxShadow: "0px 1px 2px 0px #1018280a",
                    height: "52px",
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#ffffff",
                  }}
                  disabled={
                    !isAllValid || methods?.watch("confirmPassword") == ""
                  }
                >
                  Create account
                </Button>
                <div style={{ paddingTop: "8px" }}>
                  Already have an account? <Link href="/auth/login">Login</Link>
                </div>
              </Form>
            </FormProvider>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default SignUpForm;
