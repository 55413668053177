import { useContext, useEffect, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
    CalendarOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { AsYouType } from "libphonenumber-js";
import { Trainee } from "../redux/api/gme/types";
import { useDeleteTraineeMutation } from "../redux/api/gme/gme";
import { TablePreferences, TRAINEE_CONTENT_DISPLAY_OPTIONS, TRAINEE_TABLE_DEFAULT_PREFERENCES } from "./table-config";
import useTraineeFilter from "../table-filters/trainee-filter";
import IncredableDateFormat from "../common/incredable-date-format";

export default function GMEList() {
    const [selectedRows, setSelectedRows] = useState<Trainee[]>();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const {
        TableFilters,
        filteredTrainee = [],
        UseQueryHookResult: {
            refetch,
            setPageNumber,
            setPageSize,
            pageNumber,
            pageSize,
            isFetching,
            sortingDetails,
            setSortingDetails,
        },
    } = useTraineeFilter();

    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-Trainee-Table-Preferences",
        TRAINEE_TABLE_DEFAULT_PREFERENCES
    );

    const navigate = useNavigate();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);

    const [deleteTrainee] = useDeleteTraineeMutation();

    useEffect(() => {
        if (tablePreferences?.pageSize) setPageSize(tablePreferences.pageSize);
    }, [tablePreferences, setPageSize]);

    const { items, collectionProps, paginationProps } = useCollection(
        filteredTrainee,
        {
            pagination: { pageSize: tablePreferences.pageSize },
            sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
        }
    );

    return (
        <div style={{ padding: "32px 32px 0 32px" }}>
            <Table
                loading={isFetching}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (selectedItem) {
                        if (selectedRows?.at(0)?.id === selectedItem?.id) {
                            setSelectedRows([]);
                        } else {
                            setSelectedRows([selectedItem]);
                        }
                    }
                }}
                selectedItems={selectedRows || []}
                sortingColumn={{ sortingField: sortingDetails?.sortingField }}
                sortingDescending={sortingDetails?.isDescending}
                onSortingChange={({ detail }) => {
                    setSortingDetails({
                        sortingField: detail?.sortingColumn?.sortingField,
                        isDescending: detail?.isDescending,
                    });
                }}
                columnDefinitions={[
                    {
                        id: "firstName",
                        header: "First Name",
                        cell: (item) => (
                            <NavLink to={`/gme/${item?.id}/edit`}>
                                {item?.traineeFirstName}
                            </NavLink>
                        ),
                        sortingField: "firstName",
                        sortingComparator: (a, b) =>
                            (a?.traineeFirstName ?? "").localeCompare(b?.traineeFirstName ?? ""),
                    },
                    {
                        id: "lastName",
                        header: "Last Name",
                        cell: (item) => item?.traineeLastName,
                        isRowHeader: true,
                        sortingField: "lastName",
                        sortingComparator: (a, b) =>
                            (a?.traineeLastName ?? "").localeCompare(b?.traineeLastName ?? ""),
                    },
                    {
                        id: "traineeType",
                        header: "Trainee Type",
                        cell: (item) => <span>{item?.type ?? "-"}</span>,
                        sortingField: "type",
                        sortingComparator: (a, b) =>
                            (a?.type ?? "").localeCompare(
                                b?.type ?? ""
                            ),
                    },
                    {
                        id: "program",
                        header: "Program",
                        cell: (item) => <div>{item?.program}</div>,
                    },
                    {
                        id: "trainingYear",
                        header: "Training Year",
                        cell: (item) => <span>{item?.trainingYear}</span>,
                    },
                    {
                        id: "currentRotation",
                        header: "Current Rotation",
                        cell: (item) => <span>{item?.specialty?.name ?? "-"}</span>,
                    },
                    {
                        id: "evaluationStatus",
                        header: "Evaluation Status",
                        cell: (item) => <span>{item?.evaluation ?? "-"}</span>,
                    },
                    {
                        id: "evaluationDate",
                        header: "Evaluation Date",
                        cell: (item) => item.evaluationDate ?
                            <span>
                                <CalendarOutlined />{IncredableDateFormat(item?.evaluationDate)}
                            </span>
                            : "-",
                    },
                    {
                        id: "notes",
                        header: "Notes",
                        cell: (item) => <span>{item?.notes ?? "-"}</span>,
                    },
                ]}
                items={items}
                pagination={
                    <Pagination
                        {...paginationProps}
                    />
                }
                preferences={
                    <TablePreferences
                        preferences={tablePreferences}
                        setPreferences={(preferences) => {
                            if (preferences.pageSize !== tablePreferences.pageSize) {
                                preferences.pageSize && setPageSize(preferences.pageSize);
                            }
                            setTablePreferences(preferences);
                        }}
                        contentDisplayOptions={TRAINEE_CONTENT_DISPLAY_OPTIONS}
                    />
                }
                loadingText="Loading resources"
                selectionType="single"
                trackBy="id"
                filter={TableFilters}
                header={
                    <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                        <Header
                            actions={
                                <Space size="middle">
                                    <Button
                                        onClick={() => {
                                            !!refetch && refetch();
                                        }}
                                    >
                                        <ReloadOutlined />
                                    </Button>
                                    <Dropdown
                                        menu={{
                                            disabled: selectedRows?.length !== 1,
                                            items: [
                                                {
                                                    label: "Edit",
                                                    key: "edit",
                                                    icon: <EditOutlined />,
                                                    onClick: () => { navigate(`${selectedRows?.at(0)?.id}/edit`); },
                                                },
                                                {
                                                    label: "Delete",
                                                    key: "delete",
                                                    onClick: () => setIsDeleteModalOpen(true),
                                                    icon: <DeleteOutlined />,
                                                    danger: true,
                                                },
                                            ],
                                        }}
                                    >
                                        <Button>
                                            Actions
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: "#2B6BE6",
                                            },
                                        }}
                                    >
                                        <Button
                                            onClick={() => navigate(`add`)}
                                            type="primary"
                                            icon={<PlusOutlined />}
                                        >
                                            Add Trainee
                                        </Button>
                                    </ConfigProvider>
                                </Space>
                            }
                        >
                            <Typography.Title level={3} style={{ marginTop: "0" }}>
                                Trainee Dashboard{" "}
                                <Typography.Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                >{`(${filteredTrainee?.length})`}</Typography.Text>
                            </Typography.Title>
                        </Header>
                    </div>
                }
                {...collectionProps}
                columnDisplay={tablePreferences?.contentDisplay}
                wrapLines={tablePreferences?.wrapLines}
                stripedRows={tablePreferences?.stripedRows}
                contentDensity={tablePreferences?.contentDensity}
                stickyColumns={tablePreferences?.stickyColumns}
            />
            <DeleteAlertModal
                visible={isDeleteModalOpen}
                action={async () => {
                    if (selectedRows?.at(0)?.id) {
                        setIsDeleteModalOpen(false);
                        spinnerContext?.showSpinner();
                        await deleteTrainee({
                            traineeId: String(selectedRows?.at(0)?.id),
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                            message: `Trainee deleted successfully`,
                            placement: "topRight",
                        });
                    }
                }}
                closeModal={() => setIsDeleteModalOpen(false)}
                header="Delete Trainee"
                content={
                    <Typography.Text>
                        Trainee Name:{" "}
                        <Typography.Text>{selectedRows?.at(0)?.traineeFirstName} {selectedRows?.at(0)?.traineeLastName}</Typography.Text>
                    </Typography.Text>
                }
                description="Are you sure you want to delete this Trainee?"
            />
        </div>
    );
}
