import { api } from "..";
import { Dashboard, DocumentSnapshot, EsignatureStatus, Overview, ServicesBreakdownByFacility, TaskStats, WorkflowStats } from "./types";

const dashboardAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDashboard: build.query<Dashboard, void>({
      query: () => `dashboard`,
      providesTags: ["Dashboard"],
    }),
    getDashboardPayerEnrollmentStats: build.query<any, void>({
      query: () => `dashboard/payerenrollment/stats`,
      providesTags: ["Dashboard"],
    }),
    getDashboardOverview: build.query<Overview, void>({
      query: () => `dashboard/overview`,
      providesTags: ["Dashboard"],
    }),
    getDashboardServicesBreakdownByFacility: build.query<ServicesBreakdownByFacility[], void>({
      query: () => `dashboard/servicesbreakdownbyfacility`,
      providesTags: ["Dashboard"],
    }),
    getDashboardESignatureRequest: build.query<EsignatureStatus, void>({
      query: () => `dashboard/signaturerequest/stats`,
      providesTags: ["Dashboard"],
    }),
    getDashboardDocumentSnapshot: build.query<DocumentSnapshot, void>({
      query: () => `dashboard/documentsnapshot`,
      providesTags: ["Dashboard"],
    }),
    getDashboardWorkflowStats: build.query<WorkflowStats[], void>({
      query: () => `dashboard/workflow/stats`,
      providesTags: ["Dashboard"],
    }),
    getDashboardTaskStats: build.query<TaskStats[], void>({
      query: () => `dashboard/task/stats`,
      providesTags: ["Dashboard"],
    }),
  }),
});
export const {
  useGetAllDashboardQuery,
  useGetDashboardPayerEnrollmentStatsQuery,
  useGetDashboardOverviewQuery,
  useGetDashboardServicesBreakdownByFacilityQuery,
  useGetDashboardESignatureRequestQuery,
  useGetDashboardDocumentSnapshotQuery,
  useGetDashboardWorkflowStatsQuery,
  useGetDashboardTaskStatsQuery
} = dashboardAPI;
